import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Route } from "react-router"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import * as appActions from "../../../redux/actions"
import { store } from "../../../redux/store"
import { isNotSkylead } from "../../../utils/whitelabel-utils"
import LoadingPage from "../../pages/LoadingPage"

class AuthUserRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      initialLoaded: false,
    }
  }

  async componentDidMount() {
    await this.initializeData(async () => {
      const { history, computedMatch } = this.props
      const activeUserID = +computedMatch.params.userID
      if (history.location.pathname === "/") {
        if ([2, 3].includes(store.getState().user?.profile?.userTypeId)) {
          history.replace("/admin")
        } else {
          history.replace(`/user/${activeUserID || store.getState().user?.profile?.id}/`)
        }
      }

      await this.setState({ initialLoaded: true })
    })
  }

  componentDidUpdate() {
    if (this.state.initialLoaded) {
      this.initializeData()
    }
  }

  initializeData = async callback => {
    const { actions, computedMatch, activeAccountReduxID, activeUserReduxID } = this.props
    let { userID: activeUserID } = computedMatch.params
    const { accountID } = computedMatch.params

    const { loading } = this.state
    if (
      !loading &&
      (callback ||
        (activeUserID &&
          accountID &&
          // eslint-disable-next-line
          (activeUserID != activeUserReduxID || activeAccountReduxID != accountID)))
    ) {
      await this.setState({ loading: true })

      await actions.getUserData()
      if (!activeUserID) {
        activeUserID = store.getState().user.profile.id
      }
      if (!isNotSkylead()) {
        if (window.userGuiding) {
          window.userGuiding.identify(
            typeof store.getState().user?.profile?.id === "number"
              ? store.getState().user?.profile?.id.toString()
              : null,
            {
              email: store.getState().user?.profile?.email,
              account_id: accountID,
              created_at: moment().format(),
            },
          )
        }
      }

      await actions.setActiveUserID(activeUserID)

      await actions.setActiveAccountID(accountID)
      await actions.getUserAccounts()

      const account = this.props.userAccounts.find(
        accountData => accountData.id === Number(accountID),
      )
      if (account) {
        localStorage.getItem("selectedTheme", account.theme)

        if (account.theme === "1") {
          document.getElementById("app-content-body").classList.add("theme-1")
          document.getElementById("app-content-body").classList.remove("theme-2")
        } else {
          document.getElementById("app-content-body").classList.add("theme-2")
          document.getElementById("app-content-body").classList.remove("theme-1")
        }
      }

      if (callback) {
        await callback()
      }

      await this.setState({ loading: false })
    }
  }

  render() {
    const { loading } = this.state
    const { component, exact, path } = this.props
    return loading ? <LoadingPage /> : <Route exact={exact} path={path} component={component} />
  }
}

AuthUserRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Object)]).isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
}

AuthUserRoute.defaultProps = {
  exact: false,
}

const mapStateToProps = state => ({
  user: state.user.profile,
  userAccounts: state.user.userAccounts,
  activeAccountReduxID: state.account.activeAccountID,
  activeUserReduxID: state.user.activeUserID,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthUserRoute))
