import { LEADS_FILTERS } from "../constants/leads-filter"

export function mapLeadForSelectCampaign(lead, nextStep, nextStepId, currentStep) {
  return {
    ...lead,
    step: nextStepId,
    currentStepId: nextStepId,
    currentStep,
    nextStep,
  }
}

export function preparedFiltersQuery(query) {
  let preparedQuery = ""
  const existedParams = Object.keys(query)
  for (let index = 0; index < existedParams.length; index++) {
    preparedQuery += `&${existedParams[index]}=${query[existedParams[index]]}`
  }
  return preparedQuery
}

export function setFiltersToShow(statuses) {
  let filtersString = "?eventTab=leads"
  const filtersToShow = []
  const statusFilter = []
  for (let i = 0; i < statuses.length; i++) {
    const element = statuses[i]
    switch (element.name) {
      case "VERIFIED_EMAILS":
        filtersToShow.push("Leads with verified emails")
        filtersString += `&filterByVerifiedEmails=true`
        break

      case "NOT_VERIFIED_EMAILS":
        filtersToShow.push("Leads with not verified emails")
        filtersString += `&filterByNotVerifiedEmails=true`
        break

      case "NOT_VERIFIED_EMAIL":
      case "DISCOVERED":
      case "CONNECTED_PENDING":
      case "CONNECTED_NOT_REPLIED":
      case "CONNECTED_REPLIED":
        statusFilter.push(LEADS_FILTERS[element.name].id)
        filtersToShow.push(LEADS_FILTERS[element.name].text)
        break

      case "COMPLEX_STEPS":
        if (element.stepIds.length >= 1) {
          filtersToShow.push(`Selected steps (${element.stepIds.length})`)
        }
        if (element.stepIds.length >= 1) {
          if (element.stepIds.length >= 2) {
            const stepIds = element.stepIds.map(id => +id)
            filtersString += `&filterByCurrentStep=[${stepIds.join(",")}]`
          } else {
            filtersString += `&filterByCurrentStep=[${element.stepIds}]`
          }
        } else {
          filtersString += ``
        }

        break

      default:
        break
    }
  }

  if (statusFilter.length) {
    filtersString += `&filterByStatus=${JSON.stringify(statusFilter)}`
  }

  return { filtersString, filtersToShow }
}
