export default {
  maxCampaignsPerAccount: 20,
  whitelabelAccountsLimit: 10,
  notificationsLimit: 5,
  featuresActive: [
    "inbox",
    "analytics",
    "webhooks",

    "campaign-sales",
    "campaign-csv",
    "campaign-post-engagement",
    "campaign-post-lead-list",

    "blacklist",
    "campaign-recruiter",
  ],
  maxLengthImportCSVFileName: 45,
}
