import moment from "moment-timezone"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import campaignService from "../../services/campaign-service"
import { getCreateCampaignRequestData, parseCampaignSteps } from "../../utils/campaign-utils"
import dataUtils from "../../utils/data-utils"
import { mapLeadForSelectCampaign } from "../../utils/lead-utils"
import { parseSimpleReplyToSameThread } from "../../utils/step-utils"
import { apiError, startApiCall } from "../api/apiActions"
import { clearForm, updateFormField } from "../forms/formsActions"
import { history } from "../store"

function getAllCampaignSuccess(allCampaigns) {
  return { type: ACTIONS.GET_ALL_CAMPAIGNS, allCampaigns }
}

function getAllCampaigns(query = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(startApiCall(API.GET_ALL_CAMPAIGNS))
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allCampaigns = await campaignService.getAllCampaigns(userID, accountID, query)
      dispatch(getAllCampaignSuccess(allCampaigns.data.result))
    } catch (error) {
      dispatch(apiError(API.GET_ALL_CAMPAIGNS, error))
    }
  }
}

function importFile(campaignID, file, dashboard, removeCsvDuplicates, removeDbDuplicates) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.importFile(
        userID,
        accountID,
        campaignID,
        dashboard,
        file,
        removeCsvDuplicates,
        removeDbDuplicates,
      )
      return true
    } catch (error) {
      return false
    }
  }
}

function createLeadSource(campaignId) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms
      const data = [
        {
          campaignId,
          leadSourceUrl: formData.campaignUrl,
          dashboard: Number(formData.realDashboard),
          leadSourceType: formData.campaignType,
        },
      ]
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.createLeadSource(userID, accountID, data)

      return true
    } catch (error) {}
  }
}

function createDraftCampaignSuccess(draftCampaignId) {
  return { type: ACTIONS.SELECT_DRAFT_CAMPAIGN, draftCampaignId }
}

function selectDraftCampaign(draftCampaignId) {
  return async dispatch => {
    dispatch(createDraftCampaignSuccess(draftCampaignId))
  }
}

function createDraftCampaign() {
  return async (dispatch, getState) => {
    try {
      const data = getCreateCampaignRequestData({ stateId: 2 })

      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const campaign = await campaignService.createCampaign(userID, accountID, data)

      const { formData } = getState().forms
      let success = true
      if (formData.dashboard !== "import") {
        success = await dispatch(createLeadSource(campaign.data.result.id))
      }

      await dispatch(selectDraftCampaign(campaign.data.result.id))
      return success
    } catch (error) {
      return false
    }
  }
}

function updateDraftCampaignSuccess(draftCampaignUpdateCounter) {
  return { type: ACTIONS.UPDATE_DRAFT_CAMPAIGN, draftCampaignUpdateCounter }
}

function updateDraftCampaign(additionalData = { stateId: 2 }, createCampaignFlag = false) {
  return async (dispatch, getState) => {
    try {
      const { createCampaignLoading, campaignStepsType } = getState().forms.formData
      if (!createCampaignLoading || createCampaignFlag) {
        const data = getCreateCampaignRequestData(additionalData)
        let newData = data
        if (campaignStepsType === "simple") {
          newData = parseSimpleReplyToSameThread(data)
        }

        const userID = getState().user.activeUserID
        const accountID = getState().account.activeAccountID
        const { draftCampaignId, draftCampaignUpdateCounter } = getState().campaign

        await campaignService.updateDraftCampaign(userID, accountID, draftCampaignId, newData)

        const { formData } = getState().forms
        let success = true
        if (formData.dashboard !== "import") {
          success = await dispatch(createLeadSource(draftCampaignId))
        }

        await dispatch(updateDraftCampaignSuccess(draftCampaignUpdateCounter + 1))
        return success
      }
    } catch (error) {
      return false
    }
  }
}

function createCampaignSuccess() {
  return { type: ACTIONS.CREATE_CAMPAIGN }
}

function createCampaign() {
  return async (dispatch, getState) => {
    try {
      await dispatch(updateFormField("createCampaignLoading", true))
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await dispatch(updateDraftCampaign({ stateId: 1 }, true))

      const { formData } = getState().forms
      const { draftCampaignId } = getState().campaign
      if (formData.dashboard === "import") {
        await dispatch(
          importFile(
            draftCampaignId,
            formData.file,
            Number(formData.realDashboard),
            formData.removeCsvDuplicates,
            formData.removeDbDuplicates,
          ),
        )
      }

      history.replace(`/user/${userID}/account/${accountID}`)
      await dispatch(clearForm())
      dispatch(createCampaignSuccess())
    } catch (error) {}
  }
}

function getLeadsByCampaignSuccess(campaignLeads) {
  return { type: ACTIONS.GET_LEADS_BY_CAMPAIGN, campaignLeads }
}

function changeLeadsByCampaign(campaignLeads) {
  return dispatch => {
    dispatch(getLeadsByCampaignSuccess(campaignLeads))
  }
}

function getLeadsByCampaign(campaignID, sort = "?limit=20") {
  return async (dispatch, getState) => {
    try {
      if (campaignID) {
        const userID = getState().user.activeUserID
        const accountID = getState().account.activeAccountID
        let campaignLeads
        if (campaignID !== "all") {
          campaignLeads = await campaignService.getLeadsByCampaign(
            userID,
            accountID,
            campaignID,
            sort,
          )
        } else {
          campaignLeads = await campaignService.getLeadsByAllCampaigns(userID, accountID, sort)
        }
        await dispatch(getLeadsByCampaignSuccess(campaignLeads.data.result))
      } else {
        await dispatch(getLeadsByCampaignSuccess({ count: 0, items: [], campaignStats: {} }))
      }
    } catch (error) {}
  }
}

function changeLeadVariables(leadId, data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.changeLeadVariables(userID, accountID, leadId, data)
      toast.success("Lead changes successfully saved")
    } catch (error) {}
  }
}

function deleteCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const { allCampaigns } = getState().campaign
      await campaignService.deleteCampaign(userID, accountID, campaignID)
      dispatch(
        getAllCampaignSuccess({
          campaigns: allCampaigns.campaigns.filter(campaign => +campaign.id !== campaignID),
          count: allCampaigns.count - 1,
        }),
      )
    } catch (error) {}
  }
}

function changeLeadActivitySuccess(allLeads) {
  return { type: ACTIONS.CHANGE_LEAD_ACTIVITY, allLeads }
}

function blockLead(leadID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.blockLead(userID, accountID, leadID)
      const blockedLeadIndex = allLeads.findIndex(lead => +lead.id === +leadID)
      allLeads[blockedLeadIndex].active = false
      dispatch(changeLeadActivitySuccess(allLeads))
    } catch (error) {}
  }
}

function blockLeads(leadIDS) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.blockLeads(userID, accountID, leadIDS)
      const leadsWithNewStatus = allLeads.map(lead => {
        if (leadIDS.includes(lead.id)) {
          lead.active = false
        }
        return lead
      })
      dispatch(changeLeadActivitySuccess(leadsWithNewStatus))
    } catch (error) {}
  }
}

function unblockLead(leadID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.unblockLead(userID, accountID, leadID)
      const unnlockedLeadIndex = allLeads.findIndex(lead => +lead.id === +leadID)
      allLeads[unnlockedLeadIndex].active = true
      dispatch(changeLeadActivitySuccess(allLeads))
    } catch (error) {}
  }
}

function unblockLeads(leadIDS) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.unblockLeads(userID, accountID, leadIDS)
      const leadsWithNewStatus = allLeads.map(lead => {
        if (leadIDS.includes(lead.id)) {
          lead.active = true
        }
        return lead
      })
      dispatch(changeLeadActivitySuccess(leadsWithNewStatus))
    } catch (error) {}
  }
}

function deleteLeadsSuccess(allLeads) {
  return { type: ACTIONS.DELETE_LEADS, allLeads }
}

function deleteLeads(leadIDS) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.deleteLeads(userID, accountID, leadIDS)
      const newLeadsWithoutDeletedLeads = allLeads.filter(lead => !leadIDS.includes(lead.id))
      dispatch(deleteLeadsSuccess(newLeadsWithoutDeletedLeads))
    } catch (error) {}
  }
}

function activateCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.activateCampaign(userID, accountID, campaignID)
      return true
    } catch (error) {
      return false
    }
  }
}

function deactivateCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.deactivateCampaign(userID, accountID, campaignID)
      return true
    } catch (error) {
      return false
    }
  }
}

function changeCampaignState(campaignID, campaignState) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.changeCampaignState(userID, accountID, campaignID, campaignState)
      dispatch(getAllCampaigns())
      return true
    } catch (error) {
      return false
    }
  }
}

function setAdditionalVariablesSuccess(additionalVariables) {
  return { type: ACTIONS.SET_ADDITIONAL_VARIABLES, additionalVariables }
}

function setAdditionalVariables(additionalVariables) {
  return async dispatch => {
    try {
      const allUniqueTagsFormated = dataUtils.formatVariableTags(additionalVariables, {})
      dispatch(setAdditionalVariablesSuccess(allUniqueTagsFormated))
      return true
    } catch (error) {
      return false
    }
  }
}

function setCsvInfo(csvInfo) {
  return { type: ACTIONS.GET_CSV_INFO, csvInfo }
}

function importValidation(file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const additionalVariables = await campaignService.importValidation(file, userID, accountID)
      dispatch(setCsvInfo(additionalVariables.data.result))
      dispatch(setAdditionalVariables(additionalVariables.data.result.items))
      return true
    } catch (error) {
      return false
    }
  }
}

function getCampaignDetailsSuccess(campaignDetails) {
  return { type: ACTIONS.GET_CAMPAIGN_DETAILS, campaignDetails }
}

function getCampaignDetails(campaignID) {
  return async (dispatch, getState) => {
    try {
      if (campaignID === "all") {
        const mockCampain = { campaignSteps: [] }
        dispatch(getCampaignDetailsSuccess(mockCampain))
        return mockCampain
      }

      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const campaignDetails = await campaignService.getCampaignDetails(
        userID,
        accountID,
        campaignID,
      )
      dispatch(getCampaignDetailsSuccess(campaignDetails.data.result))
      dispatch(setAdditionalVariables(campaignDetails.data.result.additionalData || []))
      return campaignDetails.data.result
    } catch (error) {
      return false
    }
  }
}

function getAllCampaignStepsSuccess(campaignSteps) {
  return { type: ACTIONS.GET_CAMPAIGN_STEPS, campaignSteps }
}

function getAllCampaignSteps(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const campaignSteps = await campaignService.getAllCampaignSteps(userID, accountID, campaignID)
      dispatch(getAllCampaignStepsSuccess(campaignSteps.data.result.items))
    } catch (error) {}
  }
}

function editCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const { formData } = getState().forms
      let campaignSteps = formData.campaignStepsEdit.map(currentStep => {
        if (currentStep.action === "email") {
          const doc = new DOMParser().parseFromString(currentStep.data.message, "text/html")
          const rawEmail = doc.getElementsByClassName("ql-editor")
          if (!rawEmail.length) {
            return {
              ...currentStep,
              data: {
                ...currentStep.data,
                signatureId: currentStep.data.signatureId,
                message: dataUtils.createHTMLEmailTemplate(
                  currentStep.data.message,
                  currentStep.data.subject,
                ),
              },
            }
          }
        }
        return currentStep
      })
      const changedData = {}

      campaignSteps = parseCampaignSteps(campaignSteps)

      if (campaignSteps && campaignSteps.length > 0) {
        changedData.editCampaignSteps = campaignSteps
      }

      if (formData.campaignName) {
        changedData.name = formData.campaignName
      }

      if (formData.campaignStepsTree) {
        let stepsWithoutEdges = formData.campaignStepsTree.filter(
          step => !step.id.includes("reactflow__edge"),
        )
        stepsWithoutEdges = parseCampaignSteps(stepsWithoutEdges, true)

        changedData.stepsTree = stepsWithoutEdges.concat(
          formData.campaignStepsTree.filter(step => step.id.includes("reactflow__edge")),
        )
        changedData.editCampaignSteps = campaignSteps.map(newData => {
          return {
            ...newData,
            id: getState().campaign.campaignDetails.campaignSteps.find(
              step => step.step === newData.step,
            )?.id,
            // When StepsTree are present, all ids are i.e. "dndnode_xx", we need to get it back as it came from details endpoint
          }
        })
      }
      changedData.editCampaignSteps = changedData.editCampaignSteps.map(step => ({
        ...step,
        replyToStep: step.replyToStep ? step.replyToStep : null,
        // change all undefineds to nulls, this is necessary for backend to know to reset its replyToStep value in database
      }))

      await campaignService.editCampaign(userID, accountID, campaignID, changedData)
      toast.success("Campaign changes successfully saved")
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error with updating campaign", error)
    }
  }
}

function getExportFile(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.getExportFile(userID, accountID, campaignID)
    } catch (error) {}
  }
}

function getAllCampaignNamesSuccess(allCampaignNames) {
  return { type: ACTIONS.GET_CAMPAIGN_NAMES, allCampaignNames }
}

function getAllCampaignNames(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allCampaignNames = await campaignService.getAllCampaignNames(
        userID,
        accountID,
        campaignID,
      )
      dispatch(getAllCampaignNamesSuccess(allCampaignNames.data.result.items))
      return true
    } catch (error) {
      return false
    }
  }
}

function addBackLeadToCampaign(leadID, nextStepId, campaignId, currentCampaignId) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms
      const newStartUTCTime = moment.tz(
        `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
        "DD MM YYY HH:mm",
        moment.tz.guess(),
      )

      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const doTaskAt = newStartUTCTime.valueOf()
      dispatch(clearForm())

      await campaignService.addBackLeadToCampaign(userID, accountID, leadID, {
        campaignId,
        step: nextStepId,
        doTaskAt,
      })

      const leadsFromSelectCampaign = getState().campaign.campaignLeads.items

      const { campaignDetails } = getState().campaign

      const { action: nextStep, step: currentStep } = campaignDetails.campaignSteps.find(
        step => +step.id === nextStepId,
      )

      const newLeads = leadsFromSelectCampaign
        .map(lead => {
          if (lead.id === leadID) {
            return mapLeadForSelectCampaign(lead, nextStep, nextStepId, currentStep)
          }
          return lead
        })
        .filter(lead => {
          if (+lead.id === +leadID) {
            return +campaignId === +currentCampaignId
          }
          return true
        })

      dispatch(changeLeadActivitySuccess(newLeads))

      toast.success("Lead successfully returned into the campaign")
      return true
    } catch (error) {
      return false
    }
  }
}

function reuseLeadList(leadSourcesId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await campaignService.reuseLeadList(userID, accountID, leadSourcesId)
      toast.success("Search successfully reused")
      return true
    } catch (error) {
      return false
    }
  }
}

function setSelectedNodeSuccess(selectedNode) {
  return { type: ACTIONS.SET_SELECTED_NODE, selectedNode }
}

function setSelectedNodeHandler(selectedNode) {
  return dispatch => {
    dispatch(setSelectedNodeSuccess(selectedNode))
  }
}

function setSelectedTabSuccess(selectedTab) {
  return { type: ACTIONS.SET_SELECTED_TAB, selectedTab }
}

function setSelectedTabHandler(selectedTab) {
  return dispatch => {
    dispatch(setSelectedTabSuccess(selectedTab))
  }
}

function getSavedSequencesSuccess(savedSequences) {
  return { type: ACTIONS.GET_SAVED_SEQUENCES, savedSequences }
}

function getSavedSequences(query = "?search") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      const savedSequences = await campaignService.getSavedSequences(userID, accountID, query)
      dispatch(getSavedSequencesSuccess(savedSequences.data.result.items))
    } catch (error) {}
  }
}

export {
  getAllCampaigns,
  selectDraftCampaign,
  createDraftCampaign,
  updateDraftCampaign,
  createCampaign,
  createLeadSource,
  getLeadsByCampaign,
  changeLeadVariables,
  deleteCampaign,
  blockLead,
  blockLeads,
  unblockLead,
  unblockLeads,
  deleteLeads,
  activateCampaign,
  deactivateCampaign,
  importValidation,
  setAdditionalVariables,
  getExportFile,
  importFile,
  getCampaignDetails,
  getAllCampaignSteps,
  editCampaign,
  getAllCampaignNames,
  addBackLeadToCampaign,
  changeLeadsByCampaign,
  changeCampaignState,
  reuseLeadList,
  setSelectedNodeHandler,
  setSelectedTabHandler,
  getSavedSequences,
}
