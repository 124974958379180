/* eslint-disable import/no-cycle */
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"
import { showInfoModal } from "../app/appActions"
import { store } from "../store"
import { getUserAccounts } from "./accountActions"

function getSettingsDataSuccess(settingsData) {
  return { type: ACTIONS.GET_SETTING_DATA, settingsData }
}

function getSettingsData() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      const settingsData = await accountService.getSettingsData(userID, accountID)
      dispatch(getSettingsDataSuccess(settingsData.data.result))
      return settingsData.data.result
    } catch (error) {}
  }
}

function setSettingsData(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.setSettingsData(userID, accountID, data)
      toast.success("Successfully saved")
      return true
    } catch (error) {
      return false
    }
  }
}

function setMaxPendingConnectionsSuccess(maxPendingAmount) {
  return { type: ACTIONS.SET_ACCOUNT_HEALTH, maxPendingAmount }
}

function setMaxPendingConnections(maxPendingAmount) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.setMaxPendingConnections(userID, accountID, { maxPendingAmount })
      dispatch(setMaxPendingConnectionsSuccess(maxPendingAmount))
      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function setTheme(theme) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      localStorage.setItem("selectedTheme", theme)
      await accountService.setTheme(userID, accountID, theme)
    } catch (error) {}
  }
}

function getPendingConnectionsSuccess(pendingConnections) {
  return { type: ACTIONS.GET_PENDING_CONNECTIONS, pendingConnections }
}

function getPendingConnections() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const pendingConnections = await accountService.getPendingConnections(userID, accountID)
      dispatch(getPendingConnectionsSuccess(pendingConnections.data.result.shouldRemove))
      return pendingConnections.data.result.shouldRemove
    } catch (error) {}
  }
}

function removePendingConnections(shouldRemove) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.removePendingConnections(userID, accountID, shouldRemove)
      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function fetchLinkedinState() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.fetchLinkedinState(userID, accountID)
      toast.info("Your request is sent")
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        dispatch(showInfoModal("warning", "Warning", error.response.data.error.message))
      }
      // if (error.response.status === 409) {
      //   dispatch(showInfoModal("warning","Warning", "You cannot verify your pin right now. Please try later"));
      // }
    }
  }
}

function updateBlacklist(blacklist, emailBlackList, showMessage = true) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.updateBlacklist(userID, accountID, blacklist)
      await accountService.updateEmailBlacklist(userID, accountID, emailBlackList)
      if (showMessage) {
        toast.success("Successfully saved")
      }
    } catch (error) {}
  }
}

function getAuthenticatedEmailsSuccess(emails) {
  return {
    type: ACTIONS.GET_AUTHENTICATED_EMAILS,
    emails,
  }
}

function getAuthenticatedEmails(
  userID = store.getState().user.activeUserID,
  accountID = store.getState().account.activeAccountID,
) {
  return async dispatch => {
    try {
      const emails = await accountService.getAuthenticatedEmails(userID, accountID)
      dispatch(getAuthenticatedEmailsSuccess(emails.data.result))
    } catch (error) {}
  }
}

function disconnectEmail(
  emailType,
  userID = store.getState().user.activeUserID,
  accountID = store.getState().account.activeAccountID,
  shouldRefreshAccounts,
) {
  return async dispatch => {
    try {
      await accountService.disconnectEmail(userID, accountID, emailType)
      dispatch(getAuthenticatedEmails(userID, accountID))
      if (shouldRefreshAccounts) {
        dispatch(getUserAccounts())
      }
    } catch (error) {}
  }
}

export {
  getSettingsData,
  setSettingsData,
  setMaxPendingConnections,
  setTheme,
  getPendingConnections,
  removePendingConnections,
  fetchLinkedinState,
  updateBlacklist,
  getAuthenticatedEmails,
  disconnectEmail,
}
