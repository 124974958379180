export function checkForReturnToCampaign(messages, doTaskAt) {
  const returnedToCampaignIndex = messages.findIndex(c => c.returnedToCampaign)
  if (returnedToCampaignIndex > -1) {
    messages.splice(returnedToCampaignIndex, 1)
  }
  messages.push({
    createdAt: doTaskAt,
    seenAt: Date.now(),
    returnedToCampaign: true,
  })
}

export function mapNewBelongedLeadWhenGetBackedToCampaign(
  lead,
  campaignId,
  nextStepId,
  doTaskAt,
  campaignName,
) {
  return {
    ...lead,
    campaignId,
    currentStepId: nextStepId,
    nextStepId,
    campaignBack: doTaskAt,
    campaign: {
      ...lead.campaign,
      name: campaignName,
    },
  }
}

export function getNewBelongedLeadsWhenLeadGetBackedToCampaign(
  leads,
  currentCampaignId,
  campaignId,
  nextStepId,
  doTaskAt,
  campaignName,
) {
  return leads.map(lead => {
    if (lead.campaignId === +currentCampaignId) {
      return mapNewBelongedLeadWhenGetBackedToCampaign(
        lead,
        campaignId,
        nextStepId,
        doTaskAt,
        campaignName,
      )
    }
    return lead
  })
}

export function mapNewLeadForChatWhenGetBackToCampaign(
  lead,
  campaignId,
  backToCampaignTimestamp,
  nextStepId,
) {
  return {
    ...lead,
    campaignId,
    lead: {
      ...lead.lead,
      backToCampaignTimestamp,
      nextStepId,
    },
  }
}

export function mapNewLeadsForChatWhenGetBackToCampaign(
  leads,
  leadID,
  campaignId,
  backToCampaignTimestamp,
  nextStepId,
) {
  return leads.map(lead => {
    if (lead.lead.id === leadID) {
      return mapNewLeadForChatWhenGetBackToCampaign(
        lead,
        campaignId,
        backToCampaignTimestamp,
        nextStepId,
      )
    }
    return lead
  })
}

export function checkIfLeadReturnedToTheStepInSameCampaign(
  selectedCampaign,
  newLeadsForChat,
  leadID,
  currentCampaignId,
) {
  let returnedToStepInSameCampaign = false
  let filteredLeads = newLeadsForChat
  if (!["all", "unread", "other"].includes(selectedCampaign)) {
    filteredLeads = newLeadsForChat.filter(lead => {
      if (lead.lead.id === leadID) {
        if (+lead.campaignId === +currentCampaignId) {
          returnedToStepInSameCampaign = true
          return true
        }
        return false
      }
      return true
    })
  }
  return {
    returnedToStepInSameCampaign,
    filteredLeads,
  }
}
