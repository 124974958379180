import "./SvgIcon.css"

import React from "react"

const SvgIcon = ({
  className = "",
  style = {},
  icon,
  tooltip = "",
  noCaret = false,
  spanClassName = "",
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  let filePathPrefix = ""

  if (process.env.NODE_ENV === "production") {
    filePathPrefix = process.env.REACT_APP_BUILD_NUMBER
  }

  const url = `/images/icons/svgSprite/svgstore.sprite.svg?build_version=${filePathPrefix}`

  let classNameTooltip = tooltip && tooltip.length > 0 ? "hover-tooltip " : " "

  if (noCaret) {
    classNameTooltip += " no-tooltip-caret"
  }
  return (
    <span
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      className={classNameTooltip + spanClassName}
      onClick={onClick}
    >
      <svg className={className} style={style}>
        <use xlinkHref={`${url}#${icon}`} />
      </svg>
      {tooltip ? <span className="tooltip">{tooltip}</span> : null}
    </span>
  )
}

export default SvgIcon
