import axios from "axios"

import config from "../utils/config"

function getPaymentPlans() {
  return axios.get(`${config.REACT_APP_BACKEND_PAYMENT_URL}/plans`)
}

function getAvailableSeats(userID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/available_seats`,
  )
}

function getAccountSpecificPrices(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/accounts/${accountID}/products/prices`,
  )
}

function getPaymentCustomer(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/customer`)
}

function getSubscriptionInfo(userID, subscriptionID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/${subscriptionID}/info`,
  )
}

function getLastUsedCard(userID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/payment_source`,
  )
}
function changeCard(userID, data) {
  return axios.put(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/payment_source`,
    data,
  )
}

function buySeats(userID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/upgrade`,
    data,
  )
}

function getPricePreview(userID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/upgrade_preview`,
    data,
  )
}

function createCustomer(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/customers`, data)
}

function submitPromoCode(userID, subscriptionID, coupon) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/${subscriptionID}/coupon`,
    {
      coupon,
    },
  )
}

function checkPromoCode(coupon) {
  return axios.post(`${config.REACT_APP_BACKEND_PAYMENT_URL}/coupon`, {
    coupon,
  })
}

function createSubscription(userID) {
  return axios.post(`${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions`)
}

function cancelSubscription(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/subscriptions/accounts/${accountID}`,
    data,
  )
}

function cancelSubscriptionReason(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/form_cancellation`, data)
}

function changeAccountSubscription(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/accounts/${accountID}/plan`,
    data,
  )
}

function getLastInvoiceAmount(userID, subscriptionID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/${subscriptionID}/latest_invoice`,
  )
}

function paySubscription(userID, subscriptionID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/${subscriptionID}/pay`,
    data,
  )
}

function endTrial(userID, subscriptionID) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/${subscriptionID}/end_trial`,
  )
}

function getAllSubscriptions(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions`)
}

function transferSeats(userID, seats, destinationUserId) {
  return axios.post(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/subscriptions/transfer_seats`,
    {
      seats,
      destinationUserId,
    },
  )
}

function updateCompanyInvoiceInfo(userID, customerID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/customers/${customerID}`,
    data,
  )
}

function generateRegistrationLink(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/register_invitation`, data)
}

function getAllInvoices(userID, query) {
  return axios.get(`${config.REACT_APP_BACKEND_PAYMENT_URL}/users/${userID}/invoices${query}`)
}

export default {
  getPaymentPlans,
  getAvailableSeats,
  getAccountSpecificPrices,
  getPaymentCustomer,
  getSubscriptionInfo,
  getLastUsedCard,
  changeCard,
  getPricePreview,
  createCustomer,
  buySeats,
  checkPromoCode,
  submitPromoCode,
  cancelSubscription,
  cancelSubscriptionReason,
  changeAccountSubscription,
  getLastInvoiceAmount,
  paySubscription,
  endTrial,
  createSubscription,
  getAllSubscriptions,
  transferSeats,
  updateCompanyInvoiceInfo,
  generateRegistrationLink,
  getAllInvoices,
}
