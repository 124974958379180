import axios from "axios"

import config from "../utils/config"

function getConversationsForCampaign(userID, accountID, campaignID, search) {
  if (campaignID === "all") {
    return axios.get(
      `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations${search}`,
    )
  }

  if (campaignID === "unread") {
    return axios.get(
      `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/unread${search}`,
    )
  }

  if (campaignID === "other") {
    return axios.get(
      `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/other${search}`,
    )
  }

  return axios.get(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/messages${search}`,
  )
}

function getMessagesForIdentifiers(userID, accountID, identifiers) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/identifiers?identifiers=${identifiers}`,
  )
}

function getMessagesForLeads(userID, accountID, leads) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/leads?leadIds=${leads}`,
  )
}

function getMessagesForThreads(userID, accountID, threads) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/threads?threads=${threads}`,
  )
}

function getBelongedLeadsForThread(userID, accountID, threads) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${threads}/belonged_leads`,
  )
}

function sendMessages(userID, accountID, thread, obj, file = []) {
  const data = new FormData()
  for (const key in obj) {
    if (obj[key]) {
      data.append(key, obj[key])
    }
  }
  if (file.length > 0) {
    for (let i = 0; i < file.length; i++) {
      const element = file[i]
      data.append(`file_upload_${i}`, element)
    }
  }

  if (obj.messageType === "EMAIL") {
    return axios.post(
      `
      ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/email`,
      data,
    )
  }

  return axios.post(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/message`,
    data,
  )
}

function seenMessage(userID, accountID, thread) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/seen`,
  )
}

function fetchLinkedinMessages(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/fetch_conversations`,
  )
}

function fetchEmails(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/fetch_emails`,
  )
}

function saveLeadNote(userID, accountID, leadID, note) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/note`,
    { note },
  )
}

function getAllTags(userID, accountID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags`)
}

function getTagsForLeads(userID, accountID, leadIds) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/tags?leadIds=[${leadIds}]`,
  )
}

function createTag(userID, accountID, tagName, color) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags`, {
    tag: tagName,
    color,
  })
}

function deleteTag(userID, accountID, tagId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags/${tagId}`,
  )
}

function addLabelToLead(userID, accountID, leadId, tagId) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadId}/tags/${tagId}`,
  )
}

function removeLabelFromLead(userID, accountID, leadId, tagId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadId}/tags/${tagId}`,
  )
}

function getLastFetchedConversation(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/last_fetch_conversations`,
  )
}

function markMessageAsUnread(userID, accountID, thread) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/mark_as_unread`,
  )
}

function deleteChatMessage(userID, accountID, thread, messageID) {
  return axios.delete(`
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/${messageID}
  `)
}

export default {
  getConversationsForCampaign,
  getMessagesForIdentifiers,
  getMessagesForLeads,
  getMessagesForThreads,
  getBelongedLeadsForThread,
  sendMessages,
  seenMessage,
  fetchLinkedinMessages,
  fetchEmails,
  saveLeadNote,
  getAllTags,
  getTagsForLeads,
  createTag,
  deleteTag,
  addLabelToLead,
  removeLabelFromLead,
  getLastFetchedConversation,
  markMessageAsUnread,
  deleteChatMessage,
}
