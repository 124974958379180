import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    profile: { availableSeats: [] },
    userProfile: {},
    userAccounts: [],
    userCount: 0,
    allUsers: [],
    activeUserID: null,
    activeUser: {},
    savedReplies: [],
    globalSignatures: [],
    featureRequestsObject: {
      count: 0,
      featureRequests: [],
    },
    featureRequest: { comments: [] },
    seat: {},
    linkedinUserData: {},
    openApiKey: "",
  }
}

export default function userReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.USER_REGISTER:
    case ACTIONS.USER_LOGIN:
      return {
        ...state,
        profile: { ...state.profile, ...action.userData },
      }
    case ACTIONS.GET_AVAILABLE_SEATS_FOR_WHITELABEL:
      return {
        ...state,
        profile: { ...state.profile, availableSeats: action.availableSeats },
      }
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        userProfile: action.userData,
      }
    case ACTIONS.USER_LOGOUT:
      return {
        ...state,
        profile: {},
      }
    case ACTIONS.GET_USER_ACCOUNTS:
      return {
        ...state,
        userAccounts: action.userAccounts,
      }
    case ACTIONS.CLEAR_ALL_USERS:
      return {
        ...state,
        allUsers: [],
        userCount: 0,
      }
    case ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.allUsers,
        userCount: action.userCount,
      }

    case ACTIONS.GET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.activeUser,
      }

    case ACTIONS.SET_ACTIVE_USER_ID: {
      return {
        ...state,
        activeUserID: action.userID,
      }
    }

    case ACTIONS.GET_ALL_SAVED_REPLIES: {
      return {
        ...state,
        savedReplies: action.savedReplies,
      }
    }

    case ACTIONS.GET_ALL_FEATURE_REQUESTS: {
      return {
        ...state,
        featureRequestsObject: {
          count: action.featureRequests.count,
          featureRequests: [
            ...(action.init ? [] : state.featureRequestsObject.featureRequests),
            ...action.featureRequests.featureRequests,
          ],
        },
      }
    }

    case ACTIONS.GET_FEATURE_REQUESTS_BY_ID: {
      return {
        ...state,
        featureRequest: action.featureRequest,
      }
    }

    case ACTIONS.GET_GLOBAL_SIGNATURES: {
      return {
        ...state,
        globalSignatures: action.signatures,
      }
    }

    case ACTIONS.CREATE_GLOBAL_SIGNATURE: {
      return {
        ...state,
        globalSignatures: [...state.globalSignatures, action.signature],
      }
    }

    case ACTIONS.UPDATE_GLOBAL_SIGNATURE: {
      return {
        ...state,
        globalSignatures: state.globalSignatures.map(globalSignature => {
          if (globalSignature.id === action.signature.id) {
            return {
              ...globalSignature,
              signature: action.signature.signature,
            }
          }
          return globalSignature
        }),
      }
    }

    case ACTIONS.DELETE_GLOBAL_SIGNATURE: {
      return {
        ...state,
        globalSignatures: state.globalSignatures.filter(
          globalSignature => globalSignature.id !== action.signatureId,
        ),
      }
    }

    case ACTIONS.GET_SEAT: {
      return {
        ...state,
        seat: action.seat,
      }
    }

    case ACTIONS.GET_LINKEDIN_USER: {
      return {
        ...state,
        linkedinUserData: action.linkedinUserData,
      }
    }

    case ACTIONS.CLEAR_LINKEDIN_USER: {
      return {
        ...state,
        linkedinUserData: initialState().linkedinUserData,
      }
    }

    case ACTIONS.GET_OPEN_API_KEY: {
      return {
        ...state,
        openApiKey: action.openApiKey,
      }
    }

    case ACTIONS.GET_GLOBAL_STATUS: {
      return {
        ...state,
        userAccounts: state.userAccounts.map(account => {
          if (+account.id === +action.accountID) {
            return {
              ...account,
              accountGlobalStatusId: action.globalStatus.globalStatusId,
            }
          }

          return { ...account }
        }),
      }
    }

    default:
      return { ...state }
  }
}
