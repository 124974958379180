import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    myTemplates: [],
    defaultTemplates: [],
    images: [],
  }
}

export default function imagePersonalizationReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.GET_MY_TEMPLATES: {
      return {
        ...state,
        myTemplates: action.myTemplates,
      }
    }

    case ACTIONS.GET_DEFAULT_TEMPLATES: {
      return {
        ...state,
        defaultTemplates: action.defaultTemplates,
      }
    }

    case ACTIONS.GET_PERSONALIZED_IMAGE_DATA: {
      return {
        ...state,
        images: [...state.images, { id: action.imageId, data: action.imageData }],
      }
    }

    default:
      return { ...state }
  }
}
