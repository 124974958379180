/* eslint-disable no-console */
import Validator from "validatorjs"

import {
  basicSearchFilters,
  forbiddenNavigatorParams,
  relationshipsBasic,
  relationshipsNav,
  smartSequencesConditions,
} from "../constants/validation-constants"
import { store } from "../redux/store"
import dataUtils from "./data-utils"
// eslint-disable-next-line import/no-cycle
import { actionsHasCondition, getAllStepIndexes, getMinIndexFromAllArrays } from "./step-utils"

let allParams = {}

const validateInput = (data, rules, customMessages, attributeNames) => {
  const validator = new Validator(data, rules, customMessages)
  if (attributeNames) {
    validator.setAttributeNames(attributeNames)
  }

  if (validator.fails()) {
    const { errors } = validator.errors
    const errs = Object.keys(errors).reduce((pr, cv) => {
      const [err] = errors[cv]
      pr[cv] = err
      return pr
    }, {})
    return errs
  }
  return undefined
}

export const validateChangePassword = params => {
  const rules = {
    newPassword: "required",
    oldPassword: "required",
  }
  const customMessages = {
    "required.newPassword": ":attribute is required",
    "required.oldPassword": ":attribute is required",
  }
  const attributeNames = {
    newPassword: "New password",
    oldPassword: "Old password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const cleanMessage = message => {
  const cleanString = message.replace(/((){{[^{}]+}})(?!})|{+[^{}]*}+/g, "$1")
  const firstMissingTags = message.match(/[^{]{([^{}]+)\}\}/g)

  const stripCorrectTags = message.replace(/({{[^{}]+}})/g, "")
  const incorrectTags = stripCorrectTags.match(/((?:[^{]|^){{[^{}]+}})(?!})|{+[^{}]*}+/g)

  const totalDigits = []

  if (incorrectTags) {
    for (let i = 0; i < incorrectTags.length; i++) {
      totalDigits.push(incorrectTags[i].length)
    }
  }
  let tagsLength
  if (firstMissingTags) {
    tagsLength = true
  } else {
    tagsLength = totalDigits.reduce((a, b) => a + b, 0)
  }

  return { lengthOfRemoveTags: tagsLength, string: cleanString }
}

export const validateChangeInvoiceInfo = params => {
  const rules = {
    companyAddress: "required|max:30",
    companyCity: "required|max:30",
    companyState: "required|max:30",
    companyPostalCode: "required|max:30",
    countryOfIncorporation: "required|max:3",
    companyName: "required|max:30",
    taxId: "max:30",
  }
  const customMessages = {
    "required.companyAddress": ":attribute is required",
    "max.companyAddress": ":attribute must be up to 30 characters long",
    "required.companyCity": ":attribute is required",
    "max.companyCity": ":attribute must be up to 30 characters long",
    "required.companyState": ":attribute is required",
    "max.companyState": ":attribute must be up to 30 characters long",
    "required.companyPostalCode": ":attribute is required",
    "max.companyPostalCode": ":attribute must be up to 30 characters long",
    "required.countryOfIncorporation": ":attribute is required",
    "max.countryOfIncorporation": ":attribute must be a valid ISO code",
    "required.companyName": ":attribute is required",
    "max.companyName": ":attribute must be up to 30 characters long",
    "max.taxId": ":attribute must be up to 30 characters long",
  }
  const attributeNames = {
    companyAddress: "Street address",
    companyCity: "City",
    companyState: "State",
    companyPostalCode: "Postal code",
    countryOfIncorporation: "Country of incorporation",
    companyName: "Company name",
    taxId: "Tax ID",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateChangeAccountName = params => {
  const rules = {
    newAccountName: "required",
  }
  const customMessages = {
    "required.newAccountName": ":attribute is required",
  }
  const attributeNames = {
    newAccountName: "Account name",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateWrongAuth = params => {
  const rules = {
    emailAddressUpdate: "required|email",
    passwordUpdate: "required",
  }
  const customMessages = {}
  const attributeNames = {
    emailAddressUpdate: "LinkedIn email address",
    passwordUpdate: "LinkedIn password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateVerifyPin = params => {
  const rules = {
    pin: "required",
  }
  const customMessages = {
    "required.pin": ":attribute is required",
  }
  const attributeNames = {
    pin: "PIN",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateVerifyTwoFactorAuth = params => {
  const rules = {
    twoFactorAuth: "required",
  }
  const customMessages = {
    "required.twoFactorAuth": ":attribute is required",
  }
  const attributeNames = {
    twoFactorAuth: "Two factor auth PIN",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateNewLeads = params => {
  const rules = {
    file: "required",
  }
  const customMessages = {
    "required.file": ":attribute is required",
  }
  const attributeNames = {
    file: "File",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

Validator.register(
  "campaign_url_basic",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      return value.includes("linkedin.com/search/results/people")
    }
    return true
  },
  "Campaign url as type LinkedIn search results is invalid. Please check if you selected the right type and that you enter a valid campaign url!",
)

Validator.register(
  "campaign_url_basic_search_filter",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      let hasBasicSearchFilters = false
      basicSearchFilters.forEach(filter => {
        if (value.includes(filter)) {
          hasBasicSearchFilters = true
        }
      })
      if (!hasBasicSearchFilters) {
        return false
      }
    }

    return true
  },
  "Campaign url is invalid. Please enter a valid campaign url!",
)

Validator.register(
  "campaign_url_basic_relationship",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      let hasRelationshipsBasic = false
      relationshipsBasic.forEach(filter => {
        if (value.includes(filter)) {
          hasRelationshipsBasic = true
        }
      })
      if (!hasRelationshipsBasic) {
        return false
      }
    }

    return true
  },
  "Please select the type of connection!",
)

Validator.register(
  "campaign_recruiter_relationship",
  () => {
    const { dashboard, isAnyOfConnectionDegreeChecked } = allParams
    if (dashboard === "recruiter") {
      return isAnyOfConnectionDegreeChecked
    }

    return true
  },
  "Please select the type of connection!",
)

Validator.register(
  "campaign_url_sales",
  value => {
    const { dashboard } = allParams
    if (dashboard === "2") {
      return value.includes("linkedin.com/sales/search/people")
    }
    return true
  },
  "Campaign url as type Sales Navigator search results is invalid. Please check if you selected the right type and that you enter a valid campaign url!",
)

Validator.register(
  "campaign_url_sales_relationship",
  value => {
    const { dashboard } = allParams
    if (dashboard === "2") {
      let hasRelationshipsNav = false
      relationshipsNav.forEach(filter => {
        if (value.includes(filter)) {
          hasRelationshipsNav = true
        }
      })
      if (!hasRelationshipsNav) {
        return false
      }
    }
    return true
  },
  "Please select the type of connection!",
)

Validator.register(
  "campaign_url_sales_forbidden",
  value => {
    const { dashboard } = allParams
    if (dashboard === "2") {
      let hasForbiddenNavigatorParams = false
      forbiddenNavigatorParams.forEach(filter => {
        if (value.includes(filter)) {
          hasForbiddenNavigatorParams = filter.slice(0, -1)
        }
      })
      if (hasForbiddenNavigatorParams) {
        return false
      }
    }
    return true
  },
  "Campaign url is invalid. Searching by  recentSearchId or savedSearchId is currently forbidden!",
)

Validator.register(
  "campaign_url_post_engagement",
  value => {
    const { dashboard } = allParams
    if (dashboard === "post-engagement") {
      return value.includes("linkedin.com/posts")
    }
    return true
  },
  "Campaign url as type post engagement results is invalid. Please check if you selected the right type and that you enter a valid campaign url!",
)

Validator.register(
  "campaign_url_recruiter",
  value => {
    const { dashboard } = allParams
    if (dashboard === "recruiter") {
      return value.includes("linkedin.com/talent/search")
    }
    return true
  },
  "Campaign url as type recruiter results is invalid. Please check if you selected the right type and that you enter a valid campaign url!",
)

Validator.register(
  "campaign_url_leads_list",
  value => {
    const { dashboard } = allParams
    if (dashboard === "leads-list") {
      return value.includes("linkedin.com/sales/lists/people")
    }
    return true
  },
  "Campaign url as type leads list results is invalid. Please check if you selected the right type and that you enter a valid campaign url!",
)

export const validateManageCampaign = params => {
  allParams = params
  const rules = {
    name: "required",
    campaignUrl:
      "required_unless:dashboard,import|campaign_url_basic|campaign_url_basic_relationship|campaign_url_sales|campaign_url_sales_relationship|campaign_url_sales_forbidden|campaign_url_post_engagement|campaign_url_leads_list|campaign_url_recruiter|campaign_recruiter_relationship",
    file: "required_if:dashboard,import",
  }
  const customMessages = {
    "required.name": ":attribute is required",
    "required_unless.campaignUrl": ":attribute is required",
    "required_if.file": "Please upload your csv",
  }
  const attributeNames = {
    name: "Campaign name",
    campaignUrl: "Campaign URL",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateWebhook = params => {
  const rules = {
    callBackURL: "required",
  }
  const customMessages = {
    "required.callBackURL": ":attribute is required",
  }
  const attributeNames = {
    callBackURL: "Callback URL",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validatePayment = params => {
  const rules = {
    cardName: "required",
  }
  const customMessages = {
    "required.cardName": ":attribute is required",
  }
  const attributeNames = {
    cardName: "Name of card",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddAccountStep2 = params => {
  const rules = {
    selectedProxy: "required",
    proxyHostWithPort: [{ required_if: ["showDefaultProxies", false] }],
    proxyUsername: [{ required_if: ["showDefaultProxies", false] }],
    proxyPassword: [{ required_if: ["showDefaultProxies", false] }],
  }
  const customMessages = {
    "required.selectedProxy": ":attribute is required",
    "required_if.proxyHostWithPort": "The proxy host with port field is required.",
    "required_if.proxyUsername": "The proxy username field is required.",
    "required_if.proxyPassword": "The proxy password field is required.",
  }
  const attributeNames = {
    selectedProxy: "Access country",
    proxyHostWithPort: "proxy host with port",
    proxyUsername: "proxy username",
    proxyPassword: "proxy password",
    showDefaultProxies: "show default proxies",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddAccountStep3 = params => {
  const rules = {
    emailAddress: "required|email",
    password: "required|min:6",
  }
  const customMessages = {
    "required.password": ":attribute is required",
  }
  const attributeNames = {
    emailAddress: "Email address",
    password: "Password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddLinkedinAccount = params => {
  const rules = {
    emailAddress: "required|email",
    password: "required",
    selectedProxy: "required",
    proxyHostWithPort: [{ required_if: ["showDefaultProxies", false] }],
    proxyUsername: [{ required_if: ["showDefaultProxies", false] }],
    proxyPassword: [{ required_if: ["showDefaultProxies", false] }],
  }
  const customMessages = {
    "required.password": ":attribute is required",
    "required.selectedProxy": ":attribute is required",
  }
  const attributeNames = {
    emailAddress: "Email address",
    password: "Password",
    selectedProxy: "Access country",
    proxyHostWithPort: "proxy host with port",
    proxyUsername: "proxy username",
    proxyPassword: "proxy password",
    showDefaultProxies: "show default proxies",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

Validator.register(
  "message_tags_valid",

  value => {
    const { allSupportedTags } = allParams

    const tagsInMessage = value.match(new RegExp("(?:{{)(.*?)(?:}})", "ig"))
    for (const index in tagsInMessage) {
      if (tagsInMessage[index]) {
        tagsInMessage[index] = tagsInMessage[index].slice(2, -2)

        if (
          !allSupportedTags.find(suppTag => suppTag.tag === tagsInMessage[index].toString()) &&
          !["unsubscribe", "personalizedImage"].includes(tagsInMessage[index].toString())
        ) {
          return false
        }
      }
    }
    return true
  },
  `You entered a tag that doesn’t exist. Please check for typing errors and correct the text.`,
)

Validator.register(
  "message_tags_replacement",
  (value, params) => {
    const { setFormErrors, tagInfos, allSupportedTags } = allParams
    const { index: validationIndex } = params
    const allSupportedTagsWithAdditional = [...allSupportedTags, { tag: "unsubscribe" }]
    let hasError = false
    allSupportedTagsWithAdditional.forEach(({ tag }) => {
      if (value.includes(`{{${tag}}}`)) {
        if (dataUtils.showTagReplacement(tag)) {
          const currentTagData = tagInfos[validationIndex]?.find(
            currentTag => currentTag.tag === tag,
          )

          if (!currentTagData || (currentTagData && currentTagData.replaceWith.length === 0)) {
            setFormErrors({ [`${tag}${validationIndex}`]: "Please enter the tag replacement" })
            hasError = true
          }
        }
      }
    })

    return !hasError
  },
  "Please enter all tag replacements",
)

export const validateCardStep = params => {
  allParams = params
  let validationErrors = {}
  for (let i = 0; i < allParams.messages.length; i++) {
    const messageObjectKey = `message-${i}`
    const subjectObjectKey = `subject-${i}`
    const signatureObjectKey = `signature-${i}`
    const daysObjectKey = `days-${i}`
    const hoursObjectKey = `hours-${i}`
    const rules = {
      [messageObjectKey]: [
        { required_if: ["action", "message"] },
        { required_if: ["action", "inMail"] },
        { message_tags_valid: true },
        { message_tags_replacement: { index: i } },
      ],
      [subjectObjectKey]: [
        !params.dontValidateSubject ? { required_if: ["action", "email"] } : {},
        !params.dontValidateSubject ? { required_if: ["action", "inMail"] } : {},
        { message_tags_valid: true },
        { message_tags_replacement: { index: i } },
      ],
      [signatureObjectKey]: [{ required_if: ["action", "inMail"] }, { between: [0, 150] }],
      emailType: [{ required_if: ["action", "email"] }],
      [daysObjectKey]: "required",
      [hoursObjectKey]: "required",
    }
    const customMessages = {
      [`required_if.${messageObjectKey}`]: "Please enter the message",
      [`required_if.${subjectObjectKey}`]: "Please enter the subject",
      [`required_if.${signatureObjectKey}`]: "Please enter the InMail signature",
    }
    const attributeNames = {
      [messageObjectKey]: "Message",
      [signatureObjectKey]: "Signature",
      [subjectObjectKey]: "Subject",
      emailType: "Email type",
      [daysObjectKey]: "Days",
      [hoursObjectKey]: "Hours",
    }
    const newDataForValidation = {
      ...allParams,
      [messageObjectKey]: allParams.messages[i],
      [subjectObjectKey]: allParams.subjects[i],
      [signatureObjectKey]: allParams.signatures[i],
      [daysObjectKey]: allParams.days[i],
      [hoursObjectKey]: allParams.hours[i],
    }

    const validation =
      validateInput(newDataForValidation, rules, customMessages, attributeNames) || {}
    validationErrors = { ...validationErrors, ...validation }

    if (allParams.action === "email") {
      if (allParams.messages[i] === "" || allParams.messages[i] === "<p><br></p>") {
        validationErrors = {
          ...(validationErrors || {}),
          [messageObjectKey]: "Please enter valid email message",
        }
      }
    }
  }

  if (Object.keys(validationErrors).length > 0) {
    return validationErrors
  }

  return false
}

export const validateLoginForm = params => {
  const rules = {
    email: "required|email",
    password: "required",
  }
  const customMessages = {}
  const attributeNames = {}
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateChangeSkyleadPassword = params => {
  const rules = {
    newPassword: "required|min:8",
    confirmPassword: "required|min:8|same:newPassword",
  }
  const customMessages = {}
  const attributeNames = {
    newPassword: "New password",
    confirmPassword: "Confirmation password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateResetSkyleadPassword = params => {
  const rules = {
    resetEmail: "required|email",
  }
  const customMessages = {}
  const attributeNames = {
    resetEmail: "Email address",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateRegisterForm = params => {
  const rules = {
    name: "required",
    email: "required|email",
    password: "required|min:8",
    passwordRepeat: "required|min:8|same:password",
  }
  const customMessages = {}
  const attributeNames = {
    name: "Full name",
    email: "Email address",
    password: "Password",
    passwordRepeat: "Repeat password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateGenerateRegisterLink = params => {
  const rules = {
    generateRegistrationLinkCount: "required",
  }
  const customMessages = {
    "required.generateRegistrationLinkCount": "You need to specify the amount of redeems",
  }
  const attributeNames = {
    generateRegistrationLinkCount: "redeems",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateSavedReply = params => {
  const rules = {
    savedReplyTitle: "required",
    savedReplyText: "required|max:4096",
  }
  const customMessages = {
    "max.savedReplyText": "Saved Reply is too long. A maximum of 4096 characters is allowed",
  }
  const attributeNames = {
    savedReplyTitle: "title",
    savedReplyText: "reply",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateFeatureRequest = params => {
  const rules = {
    feature_title: "required",
    feature_details: "required",
  }
  const customMessages = {}
  const attributeNames = {
    feature_title: "title",
    feature_details: "details",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validatePromoCode = params => {
  const rules = {
    promoCode: "required",
  }
  const customMessages = {
    "required.promoCode": "Promo code can't be empty",
  }
  const attributeNames = {
    promoCode: "promoCode",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSmtp = params => {
  const rules = {
    "smtp-username": "required",
    "smtp-password": "required",
    "smtp-ip": "required",
    "smtp-port": "required",
  }

  const customMessages = {}

  const attributeNames = {
    "smtp-username": "SMTP username",
    "smtp-password": "SMTP password",
    "smtp-ip": "SMTP ip",
    "smtp-port": "SMTP port",
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateImap = params => {
  const rules = {
    "imap-username": "required",
    "imap-password": "required",
    "imap-ip": "required",
    "imap-port": "required",
  }

  const customMessages = {}

  const attributeNames = {
    "imap-username": "IMAP username",
    "imap-password": "IMAP password",
    "imap-ip": "IMAP ip",
    "imap-port": "IMAP port",
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCustomSmtpServer = params => {
  const rules = {
    "smtp-sender-email": "required",
    "smtp-sender-name": "required",
    "smtp-username": "required",
    "smtp-password": "required",
    "smtp-ip": "required",
    "smtp-port": "required|numeric",
    "imap-username": "required",
    "imap-password": "required",
    "imap-ip": "required",
    "imap-port": "required|numeric",
  }

  const customMessages = {}

  const attributeNames = {
    "smtp-sender-email": "Sender email",
    "smtp-sender-name": "Sender name",
    "smtp-username": "SMTP username",
    "smtp-password": "SMTP password",
    "smtp-ip": "SMTP ip",
    "smtp-port": "SMTP port",
    "imap-username": "IMAP username",
    "imap-password": "IMAP password",
    "imap-ip": "IMAP ip",
    "imap-port": "IMAP port",
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const stepValidation = allTrees => {
  const { isFirstConnection, dashboard } = store.getState().forms.formData

  for (let i = 0; i < allTrees.length; i++) {
    const allElements = allTrees[i]

    const {
      allViewSteps,
      allConnectSteps,
      allFollowSteps,
      allInMailSteps,
      allMessageSteps,
      allEmailSteps,
      allEmailVerificationSteps,
      allIfEmailOpenedSteps,
      allIfEmailClickedSteps,
      allIfOpenInMailSteps,
      allIfConnectedSteps,
      allIfHasVerifiedEmail,
      allIfHasEmail,
      allIfCustom,
    } = getAllStepIndexes(allElements)

    let hasTwoConditionsInARow = false
    let j = 0
    while (j < allElements.length - 1 && !hasTwoConditionsInARow) {
      if (
        smartSequencesConditions.includes(allElements[j]?.type) &&
        smartSequencesConditions.includes(allElements[j + 1]?.type)
      ) {
        hasTwoConditionsInARow = true
      }
      j++
    }

    if (hasTwoConditionsInARow) {
      return "You cannot connect two conditions in a row"
    }

    // actions validation

    // view - always

    // connect - not first connection (inMail not exists) and only connect one time
    if (allConnectSteps.length > 1) {
      return "You can only have one connect step"
    }

    // follow - not first connection (before connect) and only follow one time
    if (allFollowSteps.length > 1) {
      return "You can follow someone only once"
    }
    const followStep = allFollowSteps.length ? allFollowSteps[0] : -1

    if (followStep !== -1 && allElements[followStep].conditions.isConnected) {
      return "Follow can't be after connect"
    }

    // inMail - not first connection (connect not exists)
    if (
      allInMailSteps.length &&
      actionsHasCondition(allElements, allInMailSteps, "isConnected").filter(cond => cond).length
    ) {
      return "InMail and connect step can't work together"
    }

    // message - on first connection/CSV (after connect)
    if (
      !isFirstConnection &&
      dashboard !== "import" &&
      allMessageSteps.length &&
      actionsHasCondition(allElements, allMessageSteps, "isConnected").filter(cond => !cond).length
    ) {
      return "You can't have message before connect"
    }

    // email - always

    // business email - after email verification step
    for (let index = 0; index < allEmailSteps.length; index++) {
      const emailStep = allEmailSteps[index]

      const isBusinessEmail = allElements[emailStep].data.emailType === "BUSINESS_EMAIL"

      if (isBusinessEmail) {
        if (allEmailVerificationSteps.length) {
          if (emailStep < allEmailVerificationSteps[0]) {
            return "Business email should be after email verification step"
          }
        } else {
          return "Email verification step is required before business email step!"
        }
      }
    }

    const minIndexViewFollowInMailConnect = getMinIndexFromAllArrays([
      allViewSteps,
      allFollowSteps,
      allInMailSteps,
      allConnectSteps,
    ])

    // email verification - on CSV always, after view, follow, inMail or connect
    if (
      // dashboard !== "import" &&
      allEmailVerificationSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allEmailVerificationSteps) < minIndexViewFollowInMailConnect))
    ) {
      return "Email verification must be after view, follow, inMail or connect step!"
    }

    // conditions validation

    // first step cannot be condition
    if (
      [
        allIfEmailOpenedSteps[0],
        allIfEmailClickedSteps[0],
        allIfOpenInMailSteps[0],
        allIfConnectedSteps[0],
        allIfHasVerifiedEmail[0],
        allIfHasEmail[0],
        allIfCustom[0],
      ].some(el => el === 0)
    ) {
      return "Your first step cannot be a condition!"
    }

    // ifConnected -after view, follow, inMail or connect
    if (
      allIfConnectedSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allIfConnectedSteps) < minIndexViewFollowInMailConnect))
    ) {
      return "Is connected condition must be after view, follow, inMail or connect step!"
    }

    // ifHasEmail - always
    // ifHasVerifiedEmail - always

    // ifOpenInMail - always, if csv then after view, follow, inMail or connect
    if (
      dashboard === "import" &&
      allIfOpenInMailSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allIfOpenInMailSteps) < minIndexViewFollowInMailConnect))
    ) {
      return "Is free InMail condition must be after view, follow, inMail or connect step when using CSV!"
    }

    // ifEmailOpened - after email step
    if (
      allIfEmailOpenedSteps.length &&
      allEmailSteps.length &&
      Math.min(allIfEmailOpenedSteps) < Math.min(allEmailSteps)
    ) {
      return "You can't have if email is opened step before email"
    }

    // ifEmailClicked - after email step
    if (
      allIfEmailClickedSteps.length &&
      allEmailSteps.length &&
      Math.min(allIfEmailClickedSteps) < Math.min(allEmailSteps)
    ) {
      return "You can't have if email clicked step before email"
    }
  }
}

export const checkIfEmail = string => {
  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return validEmail.test(String(string).toLowerCase())
}

export const validateEmailPreview = values => {
  const errors = {}
  let isValid = true
  values.forEach(tag => {
    if (!tag.value) {
      errors[tag.id] = "Please enter the tag replacement"
      isValid = false
    }

    if (tag.id === "sendToEmail") {
      if (!checkIfEmail(tag.value)) {
        errors[tag.id] = "Please enter valid email"
        isValid = false
      }
    }
  })
  return isValid ? false : errors
}

export const validateScheduleCampaign = params => {
  const rules = {
    // startUTCTime: "required",
    selectedTimezone: "required",
    startDate: "required",
  }
  const customMessages = {
    // "required.startUTCTime": ":attribute is required",
    "required.selectedTimezone": ":attribute is required",
    "required.startDate": ":attribute is required",
  }
  const attributeNames = {
    startUTCTime: "Selected start time",
    selectedTimezone: "Selected timezone",
    startDate: "Selected start date",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSeat = params => {
  const rules = {
    seatName: "required",
  }
  const customMessages = {
    "required.seatName": ":attribute is required",
  }
  const attributeNames = {
    seatName: "Seat name",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateGlobalEmailBcc = value => {
  let error = {}
  let isValid = true
  if (!checkIfEmail(value)) {
    error = { globalBcc: "Please enter valid email" }
    isValid = false
  }
  return isValid ? false : error
}

export const validateEmaiBccList = raw => {
  const emails = raw.toString().split(",")

  let valid = true
  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  for (let i = 0; i < emails.length; i++) {
    if (emails[i] === "" || !validEmail.test(emails[i].replace(/\s/g, ""))) {
      valid = false
    }
  }
  return valid
}

export const validateLinkedinConnection = params => {
  allParams = params
  const rules = {
    digitCode: "required",
    pin: "required",
  }
  const customMessages = {
    "required.digitCode": ":attribute is required",
    "required.pin": ":attribute is required",
  }
  const attributeNames = {
    digitCode: "Digit code",
    pin: "Pin",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}
