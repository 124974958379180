export const curveNames = {
  PROFILE_VIEW: "PROFILE_VIEW",
  PROFILE_FOLLOW: "PROFILE_FOLLOW",
  INVITATION_SENT: "INVITATION_SENT",
  MESSAGE_SENT: "MESSAGE_SENT",
  INMAIL_SENT: "INMAIL_SENT",
  EMAIL_SENT: "EMAIL_SENT",
  INVITATION_ACCEPTED: "INVITATION_ACCEPTED",
  MESSAGE_REPLY: "MESSAGE_REPLY",
  INVITATION_ACCEPTED_RATE: "INVITATION_ACCEPTED_RATE",
  MESSAGE_REPLY_RATE: "MESSAGE_REPLY_RATE",
  EMAIL_OPENED: "EMAIL_OPENED",
  EMAIL_CLICKED: "EMAIL_CLICKED",
  EMAIL_OPEN_RATE: "EMAIL_OPEN_RATE",
  EMAIL_CLICK_RATE: "EMAIL_CLICK_RATE",
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
  BOUNCE_RATE: "BOUNCE_RATE",
}

export const statisticsCurves = {
  PROFILE_VIEW: { ID: 1, color: "84, 216, 255", label: "Views", key: "views" },
  PROFILE_FOLLOW: { ID: 2, color: "220, 28, 117", label: "Follows", key: "follows" },
  INVITATION_SENT: {
    ID: 3,
    color: "34, 201, 173",
    label: "Connections sent",
    key: "connectionsSent",
  },
  MESSAGE_SENT: { ID: 4, color: "57, 130, 239", label: "Messages sent", key: "messagesSent" },
  INMAIL_SENT: { ID: 5, color: "242, 96, 41", label: "InMails sent", key: "inMailsSent" },
  EMAIL_SENT: { ID: 10, color: "100, 79, 68", label: "Emails sent", key: "emailsSent" },

  INVITATION_ACCEPTED: {
    ID: 6,
    color: "147, 61, 169",
    label: "Connections accepted",
    key: "connectionsAccepted",
  },
  // This is a reply for message and InMail
  MESSAGE_REPLY: {
    ID: 7,
    color: "255, 194, 30",
    label: "Replies received",
    key: "repliesReceived",
  },
  // rates
  INVITATION_ACCEPTED_RATE: {
    ID: 8,
    color: "255, 10, 10",
    label: "Acceptance rate",
    percentage: true,
    key: "acceptanceRate",
  },
  MESSAGE_REPLY_RATE: {
    ID: 9,
    color: "12, 49, 119",
    label: "Response rate",
    percentage: true,
    key: "responseRate",
  },

  EMAIL_OPENED: { ID: 11, color: "220, 28, 117", label: "Email opened", key: "emailOpened" },
  EMAIL_CLICKED: { ID: 12, color: "34, 201, 173", label: "Email clicked", key: "emailClicked" },

  EMAIL_REPLY_RATE: {
    ID: 13,
    color: "12, 49, 119",
    label: "Response rate",
    percentage: true,
    hide: true,
  },

  EMAIL_OPEN_RATE: {
    ID: 14,
    color: "57, 130, 239",
    label: "Email open rate",
    percentage: true,
    key: "emailOpenRate",
  },
  EMAIL_CLICK_RATE: {
    ID: 15,
    color: "242, 96, 41",
    label: "Email click rate",
    percentage: true,
    key: "emailClickRate",
  },
  EMAIL_VERIFIED: { ID: 16, color: "100, 79, 68", label: "Email verified", key: "emailVerified" },
  BOUNCE_RATE: {
    ID: 17,
    color: "151, 21, 90",
    label: "Bounce rate",
    percentage: true,
    key: "bounceRate",
  },
}

export const getStatisticsObject = statisticsID => {
  for (const curve in statisticsCurves) {
    if (statisticsCurves[curve] && statisticsCurves[curve].ID === +statisticsID) {
      return statisticsCurves[curve]
    }
  }
}
