import ACTIONS from "../../constants/ACTIONS"
import resourceService from "../../services/resource-service"
import dataUtils from "../../utils/data-utils"

function getProxyCountriesSuccess(proxyCountries) {
  return { type: ACTIONS.GET_PROXY_COUNTRIES, proxyCountries }
}

function getProxyCountries() {
  return async dispatch => {
    try {
      const proxyCountries = await resourceService.getProxyCountries()
      dispatch(getProxyCountriesSuccess(Object.values(proxyCountries.data.result)))
    } catch (error) {}
  }
}

function showInfoModal(
  type,
  header,
  body,
  onSuccess,
  actionButtonText,
  onClose,
  grayButtonText,
  formWrap,
  noHideOnSubmit,
  hasPrevious,
  noCloseButton,
  actionButtonVariant,
  className,
  noAutoHide,
  maxZIndex,
) {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      dispatch({
        type: ACTIONS.SHOW_INFO_MODAL,
        infoModalData: {
          show: true,
          type,
          header,
          body,
          onSuccess,
          actionButtonText,
          onClose,
          grayButtonText,
          formWrap,
          noHideOnSubmit,
          hasPrevious,
          previousData: { ...infoModalData },
          noCloseButton,
          actionButtonVariant,
          className,
          noAutoHide,
          maxZIndex,
        },
      })
    } catch (error) {}
  }
}

function setModalData(modalDataObject) {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      dispatch({
        type: ACTIONS.SET_INFO_MODAL_DATA,
        infoModalData: {
          ...infoModalData,
          ...modalDataObject,
        },
      })
    } catch (error) {}
  }
}

function hideInfoModal() {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      let newInfoModalData = {}
      if (infoModalData.hasPrevious) {
        newInfoModalData = {
          ...infoModalData.previousData,
        }
      } else {
        newInfoModalData = {
          ...infoModalData,
          previousData: {},
          show: false,
        }
      }
      dispatch({
        type: ACTIONS.HIDE_INFO_MODAL,
        infoModalData: newInfoModalData,
      })
    } catch (error) {}
  }
}

function getSupportedCampaignTagsSuccess(allSupportedTags) {
  return { type: ACTIONS.GET_ALL_SUPPORTED_TAGS, allSupportedTags }
}

function getSupportedCampaignTags() {
  return async dispatch => {
    try {
      const allSupportedTags = await resourceService.getSupportedCampaignTags()
      const allUniqueTagsFormated = dataUtils.formatVariableTags(
        Object.keys(allSupportedTags.data.result),
        allSupportedTags.data.result,
      )
      dispatch(getSupportedCampaignTagsSuccess(allUniqueTagsFormated))
    } catch (error) {}
  }
}

function getAccountStatusesSuccess(accountStatuses) {
  return { type: ACTIONS.GET_ACCOUNT_STATUSES, accountStatuses }
}

function getAccountStatuses() {
  return async dispatch => {
    try {
      const accountStatuses = await resourceService.getAccountStatuses()

      dispatch(
        getAccountStatusesSuccess([
          {
            statusId: -1,
            statusName: "CONNECT",
            userMessage: "Connect your linkedin account.",
          },
          ...Object.values(accountStatuses.data.result),
        ]),
      )
    } catch (error) {}
  }
}

export {
  getProxyCountries,
  showInfoModal,
  setModalData,
  hideInfoModal,
  getSupportedCampaignTags,
  getAccountStatuses,
}
