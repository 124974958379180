/* eslint-disable */
export default {
  ...window._env_,
  REACT_APP_BACKEND_URL: window._env_.REACT_APP_BACKEND_URL.replace("/api/v1", "/api/backend/v1"),
  REACT_APP_BACKEND_PAYMENT_URL:
    typeof window._env_.REACT_APP_BACKEND_PAYMENT_URL !== "undefined" &&
    window._env_.REACT_APP_BACKEND_PAYMENT_URL !== "undefined"
      ? window._env_.REACT_APP_BACKEND_PAYMENT_URL
      : window._env_.REACT_APP_BACKEND_URL.replace("/api/v1", "/api/payment/v1"),

  REACT_APP_HIDE_BILLING_ACCOUNT_IDS:
    window._env_.REACT_APP_HIDE_BILLING_ACCOUNT_IDS &&
    typeof window._env_.REACT_APP_HIDE_BILLING_ACCOUNT_IDS !== "undefined" &&
    window._env_.REACT_APP_HIDE_BILLING_ACCOUNT_IDS !== "undefined"
      ? window._env_.REACT_APP_HIDE_BILLING_ACCOUNT_IDS
      : "[]",

  REACT_APP_AUTH_SIDE_IMAGE:
    window._env_.REACT_APP_AUTH_SIDE_IMAGE &&
    typeof window._env_.REACT_APP_AUTH_SIDE_IMAGE !== "undefined" &&
    window._env_.REACT_APP_AUTH_SIDE_IMAGE !== "undefined"
      ? window._env_.REACT_APP_AUTH_SIDE_IMAGE
      : "https://linkedin-aws.s3.us-east-2.amazonaws.com/009edffc-91da-4867-a7b0-706f738599f2",

  REACT_APP_AUTH_SIDE_IMAGE_POSITION:
    window._env_.REACT_APP_AUTH_SIDE_IMAGE_POSITION &&
    typeof window._env_.REACT_APP_AUTH_SIDE_IMAGE_POSITION !== "undefined" &&
    window._env_.REACT_APP_AUTH_SIDE_IMAGE_POSITION !== "undefined"
      ? window._env_.REACT_APP_AUTH_SIDE_IMAGE_POSITION : "bottom",

  REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN:
    window._env_.REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN &&
    typeof window._env_.REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN !== "undefined" &&
    window._env_.REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN !== "undefined"
      ? window._env_.REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN : false,

  REACT_APP_AUTH_SECOND_LINK_COLOR:
    window._env_.REACT_APP_AUTH_SECOND_LINK_COLOR &&
    typeof window._env_.REACT_APP_AUTH_SECOND_LINK_COLOR !== "undefined" &&
    window._env_.REACT_APP_AUTH_SECOND_LINK_COLOR !== "undefined"
      ? window._env_.REACT_APP_AUTH_SECOND_LINK_COLOR
      : "#edb106",

}
