import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import Loader from "../Loader"
import SvgIconRound from "../SvgIconRound"

const Button = props => {
  const {
    type,
    children,
    onClick,
    active = true,
    margin,
    padding,
    variant,
    loading,
    disabled,
    small,
    big,
    border,
    className,
    center,
    icon,
    borderRadius,
    color,
    fontSize,
    iconColor,
    iconClassName,
    hasLoading,
    tabIndex,
    buttonProps,
    arialabel,
  } = props

  const reduxLoading = useSelector(({ forms }) => forms.formData.loadingButton)

  const loadingValue = loading || (hasLoading && reduxLoading)

  const getButtonAriaLabel = useMemo(() => {
    if (typeof children === "string") {
      return children
    }
    return undefined
  }, [children])

  const onButtonClick = e => {
    if (!loadingValue) {
      onClick(e)
    }
  }

  const buttonClassnames = classNames("btn", {
    [className]: className,
    "primary-bg": variant === "primary",
    "buttons-tab": variant === "secondary" && !active,
    "highlight-bg": variant === "secondary" && active,
    "btn-danger": variant === "danger",
    "dark-btn": variant === "dark",
    "transparent-btn": variant === "transparent",
    "image-personalization-btn": variant === "personalization",
    "small-btn": small,
    "big-btn": big,
    "border-btn": border,
    "m-auto d-block": center,
  })

  const buttonStyle = { margin, borderRadius }
  if (padding) {
    buttonStyle.padding = padding
  }

  const buttonTextStyle = {}
  if (color) {
    buttonTextStyle.color = color
  }
  if (fontSize) {
    buttonTextStyle.fontSize = fontSize
  }

  return (
    <button
      aria-label={arialabel || getButtonAriaLabel}
      onClick={onButtonClick}
      className={buttonClassnames}
      disabled={disabled || loadingValue}
      style={buttonStyle}
      type={type}
      tabIndex={tabIndex || "0"}
      {...buttonProps}
    >
      {icon && (
        <SvgIconRound
          style={{ marginRight: 12, border: `2px solid ${iconColor}` }}
          icon={icon}
          className={iconClassName}
          bgColor="transparent"
        />
      )}
      <span className="button-text" style={buttonTextStyle}>
        {loadingValue ? <Loader /> : children}
      </span>
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "dark",
    "danger",
    "transparent",
    "personalization",
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
  border: PropTypes.bool,
  icon: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  fontSize: PropTypes.number,
  type: PropTypes.string,
  iconColor: PropTypes.string,
  iconClassName: PropTypes.string,
  hasLoading: PropTypes.bool,
  buttonProps: PropTypes.instanceOf(Object),
  ariaLabel: PropTypes.string,
}

Button.defaultProps = {
  className: "",
  onClick: () => {},
  center: false,
  active: true,
  margin: "0 0 0 0",
  padding: undefined,
  variant: "primary",
  loading: false,
  disabled: false,
  small: false,
  big: false,
  border: false,
  icon: "",
  borderRadius: 50,
  color: undefined,
  fontSize: undefined,
  type: "submit",
  iconColor: "#fff",
  iconClassName: "create-campaign-icon",
  hasLoading: false,
  buttonProps: {},
  ariaLabel: "",
}

export default Button
