import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"

function seenNotificationsSuccess(notificationIds) {
  return { type: ACTIONS.SEEN_ACCOUNT_NOTIFICATIONS, notificationIds }
}

function seenNotifications(notificationIds) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.seenNotifications(userID, accountID, { notificationIds })
      dispatch(seenNotificationsSuccess(notificationIds))
    } catch (error) {}
  }
}

function getAccountNotificationsSuccess(accountNotifications) {
  return { type: ACTIONS.GET_ACCOUNT_NOTIFICATIONS, accountNotifications }
}

function getAccountNotifications(initialLoad = false, offset = 0, limit = 5) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const accountNotifications = await accountService.getAccountNotifications(
        userID,
        accountID,
        `?offset=${offset}&limit=${limit}`,
      )
      let allNotifications = { count: 0, notifications: [], unseen: 0 }
      let unseenCount = accountNotifications.data.result.unseen

      if (initialLoad) {
        allNotifications = accountNotifications.data.result
      } else {
        const allNotificationsIds = accountNotifications.data.result.notifications
          .filter(notf => !notf.seen)
          .map(notf => notf.id)
        if (allNotificationsIds.length > 0) {
          await dispatch(await seenNotifications(allNotificationsIds))
          unseenCount = getState().account.accountNotifications.unseen
        }

        allNotifications = {
          count: accountNotifications.data.result.count,
          notifications: getState().account.accountNotifications.notifications.concat(
            accountNotifications.data.result.notifications,
          ),
          unseen: unseenCount,
        }
      }
      dispatch(getAccountNotificationsSuccess(allNotifications))
    } catch (error) {}
  }
}

export { getAccountNotifications, seenNotifications }
