import axios from "axios"
import { toast } from "react-toastify"

import * as actions from "../redux/actions"
import { store } from "../redux/store"

function initInterceptors() {
  axios.interceptors.request.use(
    async req => {
      const newRequest = { ...req }
      return newRequest
    },
    error => {
      Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    res => {
      return res
    },
    async error => {
      const defaultMsg = "Oops, it looks like something went wrong."
      let err = {
        type: "Unknown Server Error",
        message: defaultMsg,
        raw_error: error,
      }

      if (
        error.response &&
        error.response.data &&
        (error.response.data.error || error.response.data.result)
      ) {
        err = error.response.data.error || error.response.data.result
        if (error.response.data.error) {
          if (error.response.data.errors) {
            if (typeof error.response.data.errors === "string") {
              err.message = error.response.data.errors
            } else {
              const errorMessages = []
              Object.values(error.response.data.errors).map(error1 =>
                errorMessages.push(error1.msg),
              )
              err.message = errorMessages
            }
          }
        }
      }

      if (!err.message) err.message = defaultMsg
      const { url } = error.response.config

      if (
        err.code === 401 &&
        !url.includes("/test_smtp") &&
        !url.includes("/test_imap") &&
        !url.includes("/oauth_email")
      ) {
        // store.dispatch(actions.expireSession())
        const { profile } = store.getState().user
        if (profile && profile.id) {
          await store.dispatch(await actions.logoutUser())
        }
      }

      if (err.code === 403) {
        // const { profile } = store.getState().user
        // if (profile && profile.id) {
        //   await store.dispatch(await actions.logoutUser())
        // }
        await store.dispatch(await toast.error(err.message))
      }

      if (err.code === 404 && err.message === "Customer not found") {
        return
      }

      if (err.code !== 401 && err.code !== 403 && err.code) {
        store.dispatch(
          actions.showInfoModal(
            "error",
            "Error",
            err.message,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          ),
        )
      }

      // if (err.code === 500) {
      //   store.dispatch(actions.showInfoModal("error", err.type, err.message))
      // }

      // TODO: return err instead of error and handle it on all actions if needed!
      return Promise.reject(error)
    },
  )
}

export default {
  initInterceptors,
}
