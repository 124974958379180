import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    allCampaigns: { campaigns: [], count: 0 },
    campaignLeads: { count: 0, items: [], campaignStats: {} },
    campaignDetails: { campaignSteps: [] },
    additionalVariables: [],
    csvInfo: {},
    allCampaignNames: [],
    draftCampaignId: undefined,
    draftCampaignUpdateCounter: 0,
    leadsByAllCampaigns: [],
    selectedNode: {},
    selectedTab: "1",
    savedSequences: [],
  }
}

export default function campaignReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.GET_ALL_CAMPAIGNS: {
      return { ...state, allCampaigns: action.allCampaigns }
    }

    case ACTIONS.GET_LEADS_BY_CAMPAIGN: {
      return {
        ...state,
        campaignLeads: action.campaignLeads,
      }
    }

    case ACTIONS.GET_CAMPAIGN_DETAILS: {
      return {
        ...state,
        campaignDetails: action.campaignDetails,
      }
    }

    case ACTIONS.GET_CAMPAIGN_STEPS: {
      return {
        ...state,
        campaignDetails: { ...state.campaignDetails, campaignSteps: action.campaignSteps },
      }
    }

    case ACTIONS.SET_ADDITIONAL_VARIABLES: {
      return {
        ...state,
        additionalVariables: action.additionalVariables,
      }
    }

    case ACTIONS.GET_CAMPAIGN_NAMES: {
      return {
        ...state,
        allCampaignNames: action.allCampaignNames,
      }
    }

    case ACTIONS.CHANGE_LEAD_ACTIVITY: {
      return {
        ...state,
        campaignLeads: {
          ...state.campaignLeads,
          items: action.allLeads,
        },
      }
    }

    case ACTIONS.DELETE_LEADS: {
      return {
        ...state,
        campaignLeads: {
          ...state.campaignLeads,
          items: action.allLeads,
        },
      }
    }

    case ACTIONS.GET_CSV_INFO: {
      return {
        ...state,
        csvInfo: action.csvInfo,
      }
    }

    case ACTIONS.SELECT_DRAFT_CAMPAIGN: {
      return {
        ...state,
        draftCampaignId: action.draftCampaignId,
      }
    }

    case ACTIONS.UPDATE_DRAFT_CAMPAIGN: {
      return {
        ...state,
        draftCampaignUpdateCounter: action.draftCampaignUpdateCounter,
      }
    }
    case ACTIONS.SET_SELECTED_NODE: {
      return {
        ...state,
        selectedNode: action.selectedNode,
      }
    }

    case ACTIONS.GET_LEADS_BY_ALL_CAMPAIGNS: {
      return { ...state, leadsByAllCampaigns: action.leadsByAllCampaigns }
    }

    case ACTIONS.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab,
      }
    }

    case ACTIONS.GET_SAVED_SEQUENCES: {
      return {
        ...state,
        savedSequences: action.savedSequences,
      }
    }

    default:
      return { ...state }
  }
}
