import _ from "lodash"

import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  formData: {},
  formErrors: {},
}

export default function formsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.field]: action.value,
        },
      }

    case ACTIONS.UPDATE_FORM_FIELDS:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.fields,
        },
      }

    case ACTIONS.INIT_FORM:
      return {
        ...state,
        formData: action.formData,
      }

    case ACTIONS.CLEAR_FORM:
      return {
        ...state,
        formData: {},
      }

    case ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.formErrors,
        },
      }
    case ACTIONS.SET_COMPLEX_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          complexStepsError: [
            ...(state.formErrors.complexStepsError || []),
            ...action.complexStepsError,
          ],
        },
      }

    case ACTIONS.CLEAR_FORM_ERROR:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          [action.field]: undefined,
        },
      }
    case ACTIONS.CLEAR_COMPLEX_STEPS_ERROR:
      // eslint-disable-next-line
      const complexStepsError = _.cloneDeep(state.formErrors.complexStepsError || [])

      if (complexStepsError.length > 0) {
        const activeError = complexStepsError.findIndex(s => s.index === action.index)
        complexStepsError.splice(activeError, 1)
        return {
          ...state,
          formErrors: {
            ...state.formErrors,
            complexStepsError,
          },
        }
      }
      return {
        ...state,
      }

    case ACTIONS.CLEAR_FORM_ERRORS:
      return {
        ...state,
        formErrors: {},
      }

    default:
      return { ...state }
  }
}
