import emojiRegex from "regex-emoji"

import { store } from "../redux/store"

export function getLeadLabels(leadId) {
  const { leadsChatLabels, allChatLabels } = store.getState().chat
  const allLabels = leadsChatLabels.filter(label => label.leadId === leadId)
  const allParsedLabels = []
  allLabels.forEach(label => {
    const selectedLabel = allChatLabels.find(ll => ll.id === label.tagId)
    if (selectedLabel) {
      allParsedLabels.push(selectedLabel)
    }
  })
  return allParsedLabels
}

export const removeEmojiTitlesFromLeadName = fullname => {
  let name = ""
  const regexEmoji = emojiRegex()
  name = fullname.replace(regexEmoji, "")
  name = name.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    "",
  )
  name = name.replace(
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
    "",
  )

  let nameSplitted = name.split(" ")

  nameSplitted = nameSplitted.filter(elem => {
    if (elem.charCodeAt(0) === 65039) {
      // remove demonic character (eof char)
      elem = ""
    }
    return elem !== ""
  })

  return nameSplitted.join(" ").replace(/\s+/g, " ")
}
