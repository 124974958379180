import ACTIONS from "../../constants/ACTIONS"

export function initialState() {
  return {
    conversations: [],
    conversationsCount: 0,
    selectedConversation: {
      linkedinUser: { fullName: "" },
      labels: [],
      lead: {},
      belongedLeads: [],
    },
    allChatLabels: [],
    leadsChatLabels: [],
    lastFetchedTimestamp: null,
    identifierMessages: {},
    threadMessages: {},
    leadMessages: {},
    campaignID: "all",
    selectedChannel: 1,
  }
}

export default function chatReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.RELOAD_CHAT_STATE: {
      return {
        ...initialState(),
      }
    }
    case ACTIONS.CLEAR_FILTERED_ITEMS: {
      return {
        ...state,
        conversations: [],
        conversationsCount: 0,
        identifierMessages: {},
        threadMessages: {},
        leadMessages: {},
      }
    }
    case ACTIONS.SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.conversations,
      }
    }

    case ACTIONS.GET_CAMPAIGN_MESSAGES: {
      const newFilteredItems = state.conversations.concat(action.conversations)
      return {
        ...state,
        conversations: newFilteredItems,
        conversationsCount: action.conversationsCount,
        campaignID: action.campaignID,
      }
    }

    case ACTIONS.REPLACE_CAMPAIGN_MESSAGES: {
      return {
        ...state,
        conversations: action.conversations,
        conversationsCount: action.conversationsCount,
      }
    }

    case ACTIONS.GET_MESSAGES_FOR_IDENTIFIERS: {
      return {
        ...state,
        identifierMessages: { ...state.identifierMessages, ...action.identifierMessages },
      }
    }

    case ACTIONS.GET_MESSAGES_FOR_LEADS: {
      const leadMessages = { ...state.leadMessages, ...action.leadMessages }
      return {
        ...state,
        leadMessages,
      }
    }

    case ACTIONS.GET_MESSAGES_FOR_THREADS: {
      const threadMessages = { ...state.threadMessages, ...action.threadMessages }
      return {
        ...state,
        threadMessages,
      }
    }

    case ACTIONS.GET_BELONGED_LEADS_FOR_THREAD: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          belongedLeads: action.belongedLeads,
        },
      }
    }

    case ACTIONS.GET_THREAD_MESSAGES: {
      return {
        ...state,
        threadMessages: action.threadMessages,
      }
    }

    case ACTIONS.GET_IDENTIFIER_MESSAGES: {
      return {
        ...state,
        identifierMessages: action.identifierMessages,
      }
    }

    case ACTIONS.GET_LEAD_MESSAGES: {
      return {
        ...state,
        leadMessages: action.leadMessages,
      }
    }

    case ACTIONS.REMOVE_IDENTIFIER_MESSAGE: {
      return {
        ...state,
        identifierMessages: action.identifierMessages,
      }
    }

    case ACTIONS.REMOVE_THREAD_MESSAGE: {
      return {
        ...state,
        threadMessages: action.threadMessages,
      }
    }

    case ACTIONS.REMOVE_LEAD_MESSAGE: {
      return {
        ...state,
        leadMessages: action.leadMessages,
      }
    }

    case ACTIONS.GET_ALL_CHAT_LABELS: {
      return {
        ...state,
        allChatLabels: action.allChatLabels,
      }
    }

    case ACTIONS.GET_LEADS_CHAT_LABELS: {
      return {
        ...state,
        leadsChatLabels: action.leadsChatLabels,
      }
    }

    case ACTIONS.GET_LAST_FETCHED_CONVERSATION_TIMESTAMP: {
      return {
        ...state,
        lastFetchedTimestamp: action.lastFetchedTimestamp,
      }
    }

    case ACTIONS.SELECT_CHAT: {
      return {
        ...state,
        selectedConversation: action.selectedConversation,
      }
    }

    case ACTIONS.DESELECT_CHAT: {
      return {
        ...state,
        selectedConversation: initialState().selectedConversation,
      }
    }

    case ACTIONS.SELECT_CHANNEL: {
      return {
        ...state,
        selectedChannel: action.channel,
      }
    }

    default:
      return { ...state }
  }
}
