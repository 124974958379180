import _ from "lodash"
import moment from "moment-timezone"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import campaignService from "../../services/campaign-service"
import chatService from "../../services/chat-service"
import * as ChatUtils from "../../utils/chat-utils"
import dataUtils from "../../utils/data-utils"
import { getLeadLabels } from "../../utils/label-utils"
import { clearForm, updateFormField } from "../forms/formsActions"

function getTagsForLeadsSuccess(leadsChatLabels) {
  return { type: ACTIONS.GET_LEADS_CHAT_LABELS, leadsChatLabels }
}

function getTagsForLeads(leadIds, oldTags, allConversationsCount) {
  return async (dispatch, getState) => {
    try {
      if (typeof allConversationsCount !== "undefined" && allConversationsCount === 0) {
        return
      }
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allLeadIds = leadIds.filter(leadId => !!leadId)
      const leadsChatLabels = await chatService.getTagsForLeads(userID, accountID, allLeadIds)
      await dispatch(
        getTagsForLeadsSuccess(
          dataUtils.filterUniqueObject([...leadsChatLabels.data.result.items, ...oldTags]),
        ),
      )
    } catch (error) {}
  }
}

function getMessagesForIdentifiersSuccess(identifierMessages) {
  return {
    type: ACTIONS.GET_MESSAGES_FOR_IDENTIFIERS,
    identifierMessages,
  }
}

function getMessagesForIdentifiers(identifiers) {
  return async (dispatch, getState) => {
    const userID = getState().user.activeUserID
    const accountID = getState().account.activeAccountID
    const identifierMessages = await chatService.getMessagesForIdentifiers(
      userID,
      accountID,
      JSON.stringify(identifiers),
    )
    await dispatch(getMessagesForIdentifiersSuccess(identifierMessages.data.result.items))
  }
}

function getMessagesForLeadsSuccess(leadMessages) {
  return {
    type: ACTIONS.GET_MESSAGES_FOR_LEADS,
    leadMessages,
  }
}

function getMessagesForLeads(leads) {
  return async (dispatch, getState) => {
    const userID = getState().user.activeUserID
    const accountID = getState().account.activeAccountID
    const leadMessages = await chatService.getMessagesForLeads(
      userID,
      accountID,
      JSON.stringify(leads),
    )
    await dispatch(getMessagesForLeadsSuccess(leadMessages.data.result.leads))
  }
}

function getMessagesForThreadsSuccess(threadMessages) {
  return {
    type: ACTIONS.GET_MESSAGES_FOR_THREADS,
    threadMessages,
  }
}

function getMessagesForThreads(threads) {
  return async (dispatch, getState) => {
    const userID = getState().user.activeUserID
    const accountID = getState().account.activeAccountID
    const threadMessages = await chatService.getMessagesForThreads(
      userID,
      accountID,
      JSON.stringify(threads),
    )
    await dispatch(getMessagesForThreadsSuccess(threadMessages.data.result.items))
  }
}

function getBelongedLeadsForThreadSuccess(belongedLeads) {
  return {
    type: ACTIONS.GET_BELONGED_LEADS_FOR_THREAD,
    belongedLeads,
  }
}

function getBelongedLeadsForThread(thread) {
  return async (dispatch, getState) => {
    const userID = getState().user.activeUserID
    const accountID = getState().account.activeAccountID
    const belongedLeads = await chatService.getBelongedLeadsForThread(userID, accountID, thread)
    await dispatch(getBelongedLeadsForThreadSuccess(belongedLeads.data.result.items))
  }
}

function getConversationsForCampaignSuccess(conversations, offset, campaignID) {
  return {
    type: ACTIONS.GET_CAMPAIGN_MESSAGES,
    conversations,
    conversationsCount: conversations.length > 0 ? offset + 30 : 0,
    campaignID,
  }
}
function setConversations(conversations) {
  return {
    type: ACTIONS.SET_CONVERSATIONS,
    conversations,
  }
}

function getMessages(campaignID, allIdentifiers, allThreadIds, allLeadIds) {
  return async (dispatch, getState) => {
    try {
      let allMessages = []
      if (campaignID === "all") {
        if (allIdentifiers && allIdentifiers.length > 0) {
          await dispatch(getMessagesForIdentifiers(allIdentifiers))
          const { identifierMessages } = getState().chat
          allMessages = identifierMessages
        }
      } else if (["unread", "other"].includes(campaignID)) {
        if (allThreadIds && allThreadIds.length > 0) {
          await dispatch(getMessagesForThreads(allThreadIds))
          const { threadMessages } = getState().chat
          allMessages = threadMessages
        }
      } else if (allLeadIds && allLeadIds.length > 0) {
        await dispatch(getMessagesForLeads(allLeadIds))
        const { leadMessages } = getState().chat
        allMessages = leadMessages
      }
      return allMessages
    } catch (err) {}
  }
}

function getConversationsForCampaign(
  campaignID,
  filter = { search: "", tagsId: [] },
  limit = 30,
  offset = 0,
  isCalledOnFirstRender,
) {
  return async (dispatch, getState) => {
    try {
      const shouldClear = offset === 0
      if (shouldClear) {
        dispatch({ type: ACTIONS.CLEAR_FILTERED_ITEMS })
      }
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const tagsParam = filter.tagsId.length > 0 ? `&tagIds=[${filter.tagsId}]` : ""
      const realSearch = filter.search
        ? `?name=${filter.search}&limit=${limit}&offset=${offset}${tagsParam}`
        : `?limit=${limit}&offset=${offset}${tagsParam}`
      const conversations = await chatService.getConversationsForCampaign(
        userID,
        accountID,
        campaignID,
        realSearch,
      )

      let allConversations = conversations.data.result.items
      if (isCalledOnFirstRender) {
        dispatch(getConversationsForCampaignSuccess(allConversations, offset || 30, campaignID))
        dispatch(updateFormField("loadingChatList", false))
      }

      let leadsChatLabels = []
      if (offset > 0) {
        leadsChatLabels = getState().chat.leadsChatLabels
      }

      const allIdentifiers = []
      const allThreadIds = []
      const allLeadIds = []
      for (let i = 0; i < allConversations.length; i++) {
        const element = allConversations[i]
        allIdentifiers.push(element.linkedinUser.identifier)
        allThreadIds.push(element.thread)
        allLeadIds.push(element.lead.id)
      }

      await Promise.all([
        dispatch(getTagsForLeads(allLeadIds, leadsChatLabels, allConversations.length || 0)),
        dispatch(getMessages(campaignID, allIdentifiers, allThreadIds, allLeadIds)),
      ]).then(([, allMessages]) => {
        allConversations = dataUtils.mapConversations(allConversations, allMessages, campaignID)
        if (isCalledOnFirstRender) {
          dispatch(setConversations(allConversations))
        } else {
          dispatch(getConversationsForCampaignSuccess(allConversations, offset || 30, campaignID))
          dispatch(updateFormField("loadingChatList", false))
        }
      })
    } catch (error) {}
  }
}

function selectChannelSuccess(channel) {
  return {
    type: ACTIONS.SELECT_CHANNEL,
    channel,
  }
}

function seenMessage(thread) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.seenMessage(userID, accountID, encodeURIComponent(thread))
      const { conversations, conversationsCount } = getState().chat

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => ({
          ...conversation,
          lastChannelThread: {
            ...conversation.lastChannelThread,
            seenAt:
              conversation.thread === thread ? Date.now() : conversation.lastChannelThread.seenAt,
          },
        })),
        conversationsCount,
      })
    } catch (error) {}
  }
}

function selectChannel(channel) {
  return async dispatch => {
    try {
      dispatch(seenMessage(channel.thread))
      dispatch(selectChannelSuccess(channel))
    } catch (error) {}
  }
}

function selectChatSuccess(selectedConversation) {
  return {
    type: ACTIONS.SELECT_CHAT,
    selectedConversation,
  }
}

function addReturnedTimeFromCampaign(messagesArray, returnedTimeToCampaign) {
  const foundReturnedTime = messagesArray.some(
    el => el.createdAt === returnedTimeToCampaign.createdAt,
  )
  if (!foundReturnedTime && returnedTimeToCampaign.createdAt !== 0)
    messagesArray.push(returnedTimeToCampaign)
  return messagesArray
}

function selectChat(selectedConversation) {
  return async (dispatch, getState) => {
    try {
      dispatch(selectChatSuccess(selectedConversation))

      if (selectedConversation.thread) {
        dispatch(getBelongedLeadsForThread(selectedConversation.thread))
        dispatch(seenMessage(selectedConversation.thread))
      }

      dispatch(
        selectChannel({
          channel: selectedConversation.lastChannelThread.dashboard,
          thread: selectedConversation.lastChannelThread.thread,
        }),
      )

      const { campaignID, identifierMessages, threadMessages, leadMessages } = getState().chat
      const { backToCampaignTimestamp } = getState().chat.selectedConversation.lead

      const returnedTimeToCampaign = {
        returnedToCampaign: !!backToCampaignTimestamp,
        createdAt: backToCampaignTimestamp,
        seenAt: Date.now(),
      }

      if (campaignID === "all") {
        const identifierMessagesArray =
          identifierMessages[selectedConversation.linkedinUser.identifier].channels[
            selectedConversation.lastChannelThread.dashboard
          ].threads[selectedConversation.lastChannelThread.thread]

        const messages = addReturnedTimeFromCampaign(
          identifierMessagesArray,
          returnedTimeToCampaign,
        )
        const sortedMessages = _.sortBy(messages, "createdAt")

        identifierMessages[selectedConversation.linkedinUser.identifier].channels[
          selectedConversation.lastChannelThread.dashboard
        ].threads[selectedConversation.lastChannelThread.thread] = sortedMessages

        dispatch({ type: ACTIONS.REMOVE_IDENTIFIER_MESSAGE, identifierMessages })
      } else if (["unread", "other"].includes(campaignID)) {
        const threadMessagesArray = threadMessages[selectedConversation.lastChannelThread.thread]
        const messages = addReturnedTimeFromCampaign(threadMessagesArray, returnedTimeToCampaign)
        const sortedMessages = _.sortBy(messages, "createdAt")

        threadMessages[selectedConversation.lastChannelThread.thread] = sortedMessages

        dispatch({ type: ACTIONS.REMOVE_THREAD_MESSAGE, threadMessages })
      } else {
        const leadId = getState().chat.selectedConversation.lead.id
        const leadMessagesArray =
          leadMessages[leadId].channels[selectedConversation.lastChannelThread.dashboard].threads[
            selectedConversation.lastChannelThread.thread
          ]

        const messages = addReturnedTimeFromCampaign(leadMessagesArray, returnedTimeToCampaign)
        const sortedMessages = _.sortBy(messages, "createdAt")

        leadMessages[leadId].channels[selectedConversation.lastChannelThread.dashboard].threads[
          selectedConversation.lastChannelThread.thread
        ] = sortedMessages

        dispatch({ type: ACTIONS.REMOVE_LEAD_MESSAGE, leadMessages })
      }
    } catch (error) {}
  }
}

function sendMessages(thread, data, file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const response = await chatService.sendMessages(
        userID,
        accountID,
        encodeURIComponent(thread),
        data,
        file,
      )

      const { conversations, campaignID } = getState().chat
      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => ({
          ...conversation,
          lastChannelThread: {
            ...conversation.lastChannelThread,
            message:
              conversation.thread === thread
                ? data.message
                : conversation.lastChannelThread.message,
          },
        })),
      })

      if (campaignID === "all") {
        dispatch(getMessagesForIdentifiersSuccess(dataUtils.addNewMessages(response.data)))
      } else if (["unread", "other"].includes(campaignID)) {
        dispatch(getMessagesForThreadsSuccess(dataUtils.addNewMessages(response.data)))
      } else {
        dispatch(getMessagesForLeadsSuccess(dataUtils.addNewMessages(response.data)))
      }

      return response.data
    } catch (error) {}
  }
}

function fetchLinkedinMessages() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await chatService.fetchLinkedinMessages(userID, accountID)
      toast.success("You have queued a LinkedIn message refresh, it will be available in shortly")
    } catch (error) {}
  }
}

function fetchEmails() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await chatService.fetchEmails(userID, accountID)
      toast.success("You have queued a Emails refresh, it will be available in shortly")
    } catch (error) {}
  }
}

function saveLeadNote(leadID, note) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.saveLeadNote(userID, accountID, leadID, note)
      const { conversations, conversationsCount } = getState().chat

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => ({
          ...conversation,
          lead: {
            ...conversation.lead,
            note: conversation.lead.id === leadID ? note : conversation.lead.note,
          },
        })),
        conversationsCount,
      })

      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function getAllTagsSuccess(allChatLabels) {
  return { type: ACTIONS.GET_ALL_CHAT_LABELS, allChatLabels }
}

function getAllTags() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const allChatLabels = await chatService.getAllTags(userID, accountID)
      dispatch(getAllTagsSuccess(allChatLabels.data.result.items))
    } catch (error) {}
  }
}

function createTag(tagName, color) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const tagData = await chatService.createTag(userID, accountID, tagName, color)
      dispatch(getAllTags())
      return tagData.data.result
    } catch (error) {}
  }
}

function deleteTag(tagId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.deleteTag(userID, accountID, tagId)
      toast.success("Label successfully deleted")
      await dispatch(getAllTags())
    } catch (error) {}
  }
}

function refreshLabelToLeads(leadId, selectedCampaignID) {
  return async (dispatch, getState) => {
    try {
      const { leadsChatLabels } = getState().chat
      let allMessages = []
      await dispatch(
        getTagsForLeads(
          [leadId],
          leadsChatLabels.filter(chatLabel => leadId !== chatLabel.leadId),
        ),
      )

      const {
        conversations,
        identifierMessages,
        threadMessages,
        leadMessages,
        conversationsCount,
        selectedConversation,
      } = getState().chat

      // TODO(sb): fix this damirs code, unread issue, it look on the backend resp, not the current unread status
      const allIdentifiers = []
      const allThreadIds = []
      const allLeadIds = []
      for (let i = 0; i < conversations.length; i++) {
        const element = conversations[i]
        allIdentifiers.push(element.linkedinUser.identifier)
        allThreadIds.push(element.thread)
        allLeadIds.push(element.lead.id)
      }

      if (selectedCampaignID === "all") {
        if (allIdentifiers && allIdentifiers.length > 0) {
          allMessages = identifierMessages
        }
      } else if (["unread", "other"].includes(selectedCampaignID)) {
        if (allThreadIds && allThreadIds.length > 0) {
          allMessages = threadMessages
        }
      } else if (allLeadIds && allLeadIds.length > 0) {
        allMessages = leadMessages
      } // here

      const allConversations = dataUtils.mapConversations(
        conversations,
        allMessages,
        selectedCampaignID,
      )

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: allConversations,
        conversationsCount,
      })

      const newSelectedConversations = { ...selectedConversation }
      newSelectedConversations.labels = []
      if (newSelectedConversations.lead.id) {
        newSelectedConversations.labels = getLeadLabels(newSelectedConversations.lead.id)
      }
      await dispatch(selectChat(newSelectedConversations))
    } catch (error) {}
  }
}

function addLabelToLead(leadId, labelId, selectedCampaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.addLabelToLead(userID, accountID, leadId, labelId)

      await dispatch(refreshLabelToLeads(leadId, selectedCampaignID))
    } catch (error) {}
  }
}

function removeLabelFromLead(leadId, labelId, selectedCampaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.removeLabelFromLead(userID, accountID, leadId, labelId)

      await dispatch(refreshLabelToLeads(leadId, selectedCampaignID))
    } catch (error) {}
  }
}

function reloadChatState() {
  return async dispatch => {
    try {
      dispatch({ type: ACTIONS.RELOAD_CHAT_STATE })
    } catch (error) {}
  }
}

function getLastFetchedConversationSuccess(lastFetchedTimestamp) {
  return { type: ACTIONS.GET_LAST_FETCHED_CONVERSATION_TIMESTAMP, lastFetchedTimestamp }
}

function getLastFetchedConversation() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const lastFetchedTimestamp = await chatService.getLastFetchedConversation(userID, accountID)
      dispatch(getLastFetchedConversationSuccess(lastFetchedTimestamp.data.result.lastFetchBefore))
    } catch (error) {}
  }
}

function deleteChatMessage(messageID, selectedConversation, campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.deleteChatMessage(
        userID,
        accountID,
        encodeURIComponent(selectedConversation.thread),
        messageID,
      )
      const { identifierMessages, threadMessages, leadMessages } = getState().chat
      const {
        linkedinUser: { identifier },
        dashboard,
        thread,
      } = selectedConversation
      if (campaignID === "all") {
        const identifierMessagesArray = identifierMessages[identifier].channels[dashboard].threads[
          thread
        ].filter(mess => +mess.id !== +messageID)
        identifierMessages[identifier].channels[dashboard].threads[thread] = identifierMessagesArray
        dispatch({ type: ACTIONS.REMOVE_IDENTIFIER_MESSAGE, identifierMessages })
      } else if (["unread", "other"].includes(campaignID)) {
        const threadMessagesArray = threadMessages[thread].filter(mess => +mess.id !== +messageID)
        threadMessages[selectedConversation.thread] = threadMessagesArray
        dispatch({ type: ACTIONS.REMOVE_THREAD_MESSAGE, threadMessages })
      } else {
        const leadMessagesArray = leadMessages[selectedConversation.lead.id].channels[
          dashboard
        ].threads[thread].filter(mess => +mess.id !== messageID)
        leadMessages[selectedConversation.lead.id].channels[dashboard].threads[
          thread
        ] = leadMessagesArray
        dispatch({ type: ACTIONS.REMOVE_LEAD_MESSAGE, leadMessages })
      }
      dispatch(getLastFetchedConversation())
      // TODO(sb): Change conversations
      toast.success("Message successfully deleted")
    } catch (error) {}
  }
}

function markMessageAsUnread(thread) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await chatService.markMessageAsUnread(userID, accountID, thread)

      const { conversationsCount, conversations } = getState().chat

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => {
          return {
            ...conversation,
            lastChannelThread: {
              ...conversation.lastChannelThread,
              seenAt: conversation.thread === thread ? null : conversation.lastChannelThread.seenAt,
            },
          }
        }),
        conversationsCount,
      })
      toast.success("Message successfully marked as unread")
    } catch (error) {}
  }
}

function getIdentifierMessagesSuccess(identifierMessages) {
  return {
    type: ACTIONS.GET_IDENTIFIER_MESSAGES,
    identifierMessages,
  }
}

function getThreadMessagesSuccess(threadMessages) {
  return {
    type: ACTIONS.GET_THREAD_MESSAGES,
    threadMessages,
  }
}

function getLeadMessagesSuccess(leadMessages) {
  return {
    type: ACTIONS.GET_LEAD_MESSAGES,
    leadMessages,
  }
}
function deselectChatSuccess() {
  return {
    type: ACTIONS.DESELECT_CHAT,
  }
}

function deselectChat() {
  return async dispatch => {
    try {
      dispatch(deselectChatSuccess())
    } catch (error) {}
  }
}

function addBackLeadToCampaignForChat(leadID, nextStepId, campaignId) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms
      const newStartUTCTime = moment.tz(
        `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
        "DD MM YYY HH:mm",
        moment.tz.guess(),
      )

      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const doTaskAt = newStartUTCTime.valueOf()
      const leadsFromChat = getState().chat.conversations
      const { belongedLeads } = getState().chat.selectedConversation
      const selectedCampaign = getState().chat.campaignID
      const { identifierMessages, threadMessages, leadMessages } = getState().chat

      const { allCampaignNames } = getState().campaign
      await dispatch(clearForm())

      await campaignService.addBackLeadToCampaign(userID, accountID, leadID, {
        campaignId,
        step: nextStepId,
        doTaskAt,
      })
      const newCampaignName = allCampaignNames.find(campaign => campaign.id === campaignId).name

      const currentCampaignId = leadsFromChat.find(lead => lead.lead.id === leadID).campaignId

      const newBelongedLeads = ChatUtils.getNewBelongedLeadsWhenLeadGetBackedToCampaign(
        belongedLeads,
        currentCampaignId,
        campaignId,
        nextStepId,
        doTaskAt,
        newCampaignName,
      )

      const newLeadsForChat = ChatUtils.mapNewLeadsForChatWhenGetBackToCampaign(
        leadsFromChat,
        leadID,
        campaignId,
        doTaskAt,
        nextStepId,
      )

      const {
        returnedToStepInSameCampaign,
        filteredLeads: newFilteredLeadsForChat,
      } = ChatUtils.checkIfLeadReturnedToTheStepInSameCampaign(
        selectedCampaign,
        newLeadsForChat,
        leadID,
        currentCampaignId,
      )

      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: newFilteredLeadsForChat,
      })

      if (!returnedToStepInSameCampaign && !["all", "unread", "other"].includes(selectedCampaign)) {
        await dispatch(deselectChat())
      } else {
        const selectedConversation = newFilteredLeadsForChat.find(
          newLeadForChat =>
            newLeadForChat.campaignId === campaignId && newLeadForChat.lead.id === leadID,
        )

        selectedConversation.belongedLeads = newBelongedLeads
        await dispatch({ type: ACTIONS.SELECT_CHAT, selectedConversation })

        const { selectedChannel } = getState().chat

        if (selectedCampaign === "all") {
          const newIdentifierMessages = { ...identifierMessages }
          const newIdentifierMessage =
            newIdentifierMessages[selectedConversation.linkedinUser.identifier].channels[
              selectedConversation.dashboard
            ].threads[selectedChannel.thread]
          ChatUtils.checkForReturnToCampaign(newIdentifierMessage, doTaskAt)
          await dispatch(getIdentifierMessagesSuccess(newIdentifierMessages))
        } else if (["unread", "other"].includes(selectedCampaign)) {
          const newThreadMessages = { ...threadMessages }
          const newThreadMessage = threadMessages[selectedChannel.thread]
          ChatUtils.checkForReturnToCampaign(newThreadMessage, doTaskAt)
          await dispatch(getThreadMessagesSuccess(newThreadMessages))
        } else {
          const newLeadMessages = { ...leadMessages }
          const newLeadMessage =
            newLeadMessages[selectedConversation.lead.id].channels[selectedConversation.dashboard]
              .threads[selectedChannel.thread]
          ChatUtils.checkForReturnToCampaign(newLeadMessage, doTaskAt)
          await dispatch(getLeadMessagesSuccess(newLeadMessages))
        }
      }

      toast.success("Lead successfully returned into the campaign")
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  getConversationsForCampaign,
  getBelongedLeadsForThread,
  selectChannel,
  selectChat,
  sendMessages,
  seenMessage,
  fetchLinkedinMessages,
  fetchEmails,
  saveLeadNote,
  getAllTags,
  getTagsForLeads,
  createTag,
  deleteTag,
  addLabelToLead,
  removeLabelFromLead,
  reloadChatState,
  getLastFetchedConversation,
  markMessageAsUnread,
  deleteChatMessage,
  getMessagesForThreads,
  getMessagesForLeads,
  addBackLeadToCampaignForChat,
  deselectChat,
}
