import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"

function getAllWebhooksSuccess(allWebhooks) {
  return { type: ACTIONS.GET_ALL_WEBHOOKS, allWebhooks }
}

function getAllWebhooks(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      const allWebhooks = await accountService.getAllWebhooks(userID, accountID, query)
      dispatch(
        getAllWebhooksSuccess({
          rows: allWebhooks.data.result.rows,
          count: allWebhooks.data.result.count,
        }),
      )
    } catch (error) {}
  }
}

function getAllGlobalWebhooks(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      const allGlobalWebhooks = await accountService.getAllGlobalWebhooks(userID, accountID, query)
      dispatch(
        getAllWebhooksSuccess({
          rows: allGlobalWebhooks.data.result.rows,
          count: allGlobalWebhooks.data.result.count,
        }),
      )
    } catch (error) {}
  }
}

function createWebhook(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.createWebhook(userID, accountID, data)
      return true
    } catch (error) {
      return false
    }
  }
}

function createGlobalWebhook(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.createGlobalWebhook(userID, accountID, data)
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteWebhook(webhookID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.deleteWebhook(userID, accountID, webhookID)
      dispatch(getAllWebhooks())
    } catch (error) {}
  }
}

function deleteGlobalWebhook(webhookIDS, url, arrayOfActions) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.deleteGlobalWebhook(userID, accountID, webhookIDS, url, arrayOfActions)
      dispatch(getAllGlobalWebhooks())
    } catch (error) {}
  }
}

function testWebhook(url, action) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID

      await accountService.testWebhook(userID, accountID, url, action)
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  getAllWebhooks,
  getAllGlobalWebhooks,
  createWebhook,
  createGlobalWebhook,
  deleteWebhook,
  deleteGlobalWebhook,
  testWebhook,
}
