import axios from "axios"

import config from "../utils/config"
import { compressImage } from "../utils/image-personalization-utils"

async function getCloudinaryPermissions(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images/upload_permission`,
  )
}

async function uploadCloudinary(userID, accountID, image) {
  const data = await getCloudinaryPermissions(userID, accountID)

  const url = "https://api.cloudinary.com/v1_1/skylead/image/upload"

  const formData = new FormData()
  formData.append("api_key", "564153543923946")
  formData.append("public_id", data.data.result.publicId)
  formData.append("signature", data.data.result.signature)
  formData.append("timestamp", data.data.result.timestamp)

  return compressImage(image, formData, url, "file")
}

function getMyTemplates(userID, accountID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images`)
}

function getDefaultTemplates(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images/templates`,
  )
}

function createPersonalizedImage(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images`,
    data,
  )
}

function getPersonalizedImageData(userID, accountID, personalizedImageId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images/${personalizedImageId}`,
  )
}

function uploadImageToBucket(userID, accountID, file) {
  const data = new FormData()
  const url = `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images/upload`
  return compressImage(file, data, url, "CUSTOMER_CUSTOM_IMAGE")
}

function deleteImageTemplate(userID, accountID, imageId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/account/${accountID}/images/${imageId}`,
  )
}

export default {
  getCloudinaryPermissions,
  uploadCloudinary,
  getMyTemplates,
  getDefaultTemplates,
  createPersonalizedImage,
  getPersonalizedImageData,
  uploadImageToBucket,
  deleteImageTemplate,
}
