import moment from "moment-timezone"
import queryString from "query-string"

import { ABTESTINGELEMENTS } from "../constants/campaign-constants"
import { store } from "../redux/store"

const getInitialStateData = (step = {}, hideAdditionalData) => {
  const { userAccounts } = store.getState().user
  const { activeAccountID } = store.getState().account

  const defaultHoursIfEmailOpened = 8
  const days = [""]
  const hours = step.action === "ifEmailOpened" ? [defaultHoursIfEmailOpened] : [""]

  if (step.doAfterPreviousStep !== undefined) {
    days[0] = Math.trunc((step.doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))
    hours[0] = (step.doAfterPreviousStep - days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)
  }
  let data = {
    // main data
    ...step.data,
    tagInfos: (step.data && step.data.tagInfo) || [[]],
    personalizedImageIds: (step.data && step.data.personalizedImageId) || [null],
    messages: (step.data && step.data.message) || [""],
    message: "", // needed for backend validation
    subjects: (step.data && step.data.subject) || [""],
    signatures: (step.data && step.data.signature) || [""],
    signatureIds: (step.data && step.data.signatureId) || [""],
    emailType: (step.data && step.data.emailType) || "",
    sentEmailIfNotConnected: (step.data && step.data.sentEmailIfNotConnected) || false,
    discoverBusinessEmail: (step.data && step.data.discoverBusinessEmail) || undefined,
    outputs: step.outputs || [],
  }

  if (!hideAdditionalData) {
    data = {
      ...data,
      // additional FE data
      action: step.action || "view",
      days,
      hours,
      selectedTabId: "1",
      editing: false,
      openImagePersonalizationModal: false,
      personalizedImageData: step.personalizedImageData || [{}],
      loadingMyTemplates: false,
      loadingDefaultTemplate: false,
      uploadLoading: false,
      isEmailSubjectWarningShawn: false,
      imagePersonalizationData: {
        items: [],
        selectedLayerIndex: null,
        currentMessage: "",
        tagInfo: [],
      },
      userImage:
        (
          (
            userAccounts.find(user => user.id === Number(activeAccountID)) || {
              linkedinUser: {},
            }
          ).linkedinUser || {}
        ).picture ||
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png",
      customEmailError: false,
    }
  }

  return data
}

const getTime = (doAfterPreviousStep, field) => {
  const time = { days: 0, hours: 0 }
  if (doAfterPreviousStep !== undefined) {
    time.days = [Math.trunc((doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))]
    time.hours = [(doAfterPreviousStep - time.days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)]
  }
  return time[field]
}

const parseIncomingSteps = steps => {
  const ABGroupElements = []
  let n = 0
  let ABGroup = 0
  while (n < steps.length) {
    if (typeof steps[n].id === "string" && steps[n].id?.includes("reactflow__edge")) {
      ABGroupElements.push(steps[n])
      n++
      // eslint-disable-next-line
      continue
    }
    if (steps[n].nextSteps && steps[n].nextSteps.length > 1) {
      // if there is AB element after condition or AB element, multiple same nextSteps needs to be removed.
      const nextStepsValues = [0]
      const newNextSteps = []
      for (let i = 0; i < steps[n].nextSteps.length; i++) {
        if (!nextStepsValues.includes(steps[n].nextSteps[i].step)) {
          nextStepsValues.push(steps[n].nextSteps[i].step)
          if (steps[n].nextSteps[i]?.step) {
            newNextSteps.push(steps[n].nextSteps[i])
          }
        }
      }
      steps[n].nextSteps = newNextSteps
    }

    if (steps[n].ABGroup && ABGroup === steps[n].ABGroup) {
      // eslint-disable-next-line
      const ABGroupElementIndex = ABGroupElements.findIndex(el => el.ABGroup === steps[n].ABGroup)
      ABGroupElements[ABGroupElementIndex] = {
        ...(ABGroupElements[ABGroupElementIndex] || []),
        data: {
          ...getInitialStateData(),
          ...(ABGroupElements[ABGroupElementIndex]?.data || []),
          messages: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.messages || []),
            steps[n]?.data?.message,
          ],
          signatures: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatures || []),
            steps[n]?.data?.signature,
          ],
          subjects: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.subjects || []),
            steps[n]?.data?.subject,
          ],
          signatureIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatureIds || []),
            steps[n]?.data?.signatureId,
          ],
          tagInfos: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.tagInfos || []),
            steps[n]?.data?.tagInfo,
          ],
          personalizedImageIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageIds || []),
            steps[n]?.data?.personalizedImageId,
          ],
          personalizedImageData: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageData || []),
            steps[n]?.data?.personalizedImageData,
          ],
          hours: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.hours || []),
            getTime(steps[n]?.doAfterPreviousStep, "hours"),
          ],
          days: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.days || []),
            getTime(steps[n]?.doAfterPreviousStep, "days"),
          ],
          message: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
        },
        replyToStep: null,
        ABTestingIds: [...(ABGroupElements[ABGroupElementIndex]?.ABTestingIds || []), steps[n].id],
      }
    } else {
      ABGroupElements.push({
        ...steps[n],
        data: {
          ...getInitialStateData(),
          ...steps[n].data,
          messages: [steps[n].data?.message],
          signatures: [steps[n].data?.signature],
          subjects: [steps[n].data?.subject],
          signatureIds: [steps[n].data?.signatureId],
          tagInfos: [steps[n].data?.tagInfo],
          personalizedImageIds: [steps[n].data?.personalizedImageId],
          personalizedImageData: [steps[n].data?.personalizedImageData],
          days: [getTime(steps[n]?.doAfterPreviousStep, "days")],
          hours: [getTime(steps[n]?.doAfterPreviousStep, "hours")],
          message: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
        },
        replyToStep: null,
        ABTestingIds: [steps[n].id],
      })
      if (steps[n].ABGroup) {
        ABGroup = steps[n].ABGroup
      }
    }
    n++
  }

  return ABGroupElements
}

const parseCampaignSteps = (campaignSteps = [], isStepsTree) => {
  const filteredCampaignSteps = campaignSteps.filter(step => Object.keys(step).length > 0)
  const newSteps = []
  let ABGroupCount = 0
  filteredCampaignSteps.forEach(campaignStep => {
    let overWriteID = true
    if (typeof campaignStep.id === "string" && campaignStep.id.includes("dndnode_")) {
      overWriteID = false
    }

    if (ABTESTINGELEMENTS.includes(campaignStep.action)) {
      if (campaignStep.data.messages.length > 1) {
        ABGroupCount += 1
      }
      campaignStep.data.messages.forEach((message, messageIndex) => {
        if (message.includes("{{personalizedImage}}")) {
          campaignStep.data.tagInfos[messageIndex].push({
            tag: "personalizedImage",
            replaceWith: "personalizedImage",
          })
        }
        newSteps.push({
          ...campaignStep,
          ABGroup: campaignStep.data.messages.length > 1 ? ABGroupCount : null,
          data: {
            ...campaignStep.data,
            message,
            signature: campaignStep.data.signatures[messageIndex],
            subject: campaignStep.data.subjects[messageIndex],
            signatureId: campaignStep.data.signatureIds[messageIndex],
            tagInfo: campaignStep.data.tagInfos[messageIndex],
            personalizedImageId: campaignStep.data.personalizedImageIds[messageIndex],
            messages: undefined,
            signatures: undefined,
            subjects: undefined,
            signatureIds: undefined,
            tagInfos: undefined,
            personalizedImageIds: undefined,
            days: isStepsTree ? campaignStep.data.days[messageIndex] : undefined,
            hours: isStepsTree ? campaignStep.data.hours[messageIndex] : undefined,
            personalizedImageData: undefined,
          },
          id:
            overWriteID && campaignStep.ABTestingIds && campaignStep.ABTestingIds[messageIndex] // AB Steps merge multiple steps to one, this is necessary to save their ids
              ? campaignStep.ABTestingIds[messageIndex]
              : campaignStep.id,
          doAfterPreviousStep: campaignStep.doAfterPreviousStep[messageIndex],
          days: undefined,
          hours: undefined,
          ABTestingIds: undefined,
          replyToStep: campaignStep.replyToStep ? campaignStep.replyToStep[messageIndex] : null,
        })
      })
    } else {
      newSteps.push({
        ...campaignStep,
        doAfterPreviousStep:
          campaignStep.doAfterPreviousStep && campaignStep.doAfterPreviousStep[0],
        data: {
          ...campaignStep.data,
          personalizedImageData: undefined,
          message: "",
          tagInfo: [],
          messages: undefined,
          signatures: undefined,
          subjects: undefined,
          signatureIds: undefined,
          tagInfos: undefined,
          personalizedImageIds: undefined,
          days: isStepsTree ? campaignStep.data.days[0] : undefined,
          hours: isStepsTree ? campaignStep.data.hours[0] : undefined,
        },
      })
    }
  })

  return newSteps
}

const getCreateCampaignRequestData = (additionalData = {}) => {
  const { additionalVariables } = store.getState().campaign
  const { formData } = store.getState().forms

  const startAt = formData.startUTCTime
    ? moment
        .tz(
          `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
          "DD MM YYY HH:mm",
          formData.selectedTimezone.name,
        )
        .utc()
        .valueOf()
    : 0

  return {
    name: formData.name,
    checkTimestamp: formData.sendPrevious ? moment.utc().valueOf() : 0,
    campaignSteps: parseCampaignSteps(formData.campaignSteps),
    isFirstConnection: formData.isFirstConnection,
    getPersonalInfo: formData.getPersonalInfo,
    enableClickRate: formData.trackEmailsClicks,
    enableOpenRate: formData.trackEmailsOpens,
    onlyPremium: formData.dashboard === "import" ? undefined : formData.onlyPremium,
    onlyOpenInmail: formData.onlyOpenInmail,
    onlyDiscoverLeads: formData.onlyDiscoverLeads,
    onlyUniqueLeads: formData.onlyUniqueLeads,
    noPendingConnections: formData.noPendingConnections,
    additionalFields: additionalVariables.map(tag => {
      return tag.tag
    }),
    startAt,
    ...additionalData,
  }
}

const parseDoAfterPreviousStep = (hours, days) => {
  if (typeof hours !== "object") {
    hours = [hours]
    days = [days]
  }
  return (hours || []).map(
    (time, index) =>
      (Number(days[index]) || 0) * 24 * 60 * 60 * 1000 +
      (Number(hours[index]) || 0) * 60 * 60 * 1000,
  )
}

const filterTagInfos = (tagInfos = [], messages, subjects) =>
  tagInfos.map((tagInfo, index) =>
    tagInfo?.filter(
      ({ tag }) =>
        messages[index]?.includes(`{{${tag}}}`) || subjects[index]?.includes(`{{${tag}}}`),
    ),
  )

const getABTestingField = (field, data) => {
  return {
    messages: { field: "messages", data, emptyValue: "" },
    subjects: { field: "subjects", data, emptyValue: "" },
    signatures: { field: "signatures", data, emptyValue: "" },
    tagInfos: { field: "tagInfos", data, emptyValue: [] },
    personalizedImageIds: {
      field: "personalizedImageIds",
      data,
      emptyValue: null,
    },
    personalizedImageData: {
      field: "personalizedImageData",
      data,
      emptyValue: {},
    },
    hours: { field: "hours", data, emptyValue: "" },
    days: { field: "days", data, emptyValue: "" },
    signatureIds: { field: "signatureIds", data, emptyValue: "" },
  }[field]
}
const getABTestingFields = fields =>
  fields.map(field => getABTestingField(Object.keys(field)[0], field[Object.keys(field)[0]]))

const CREATE_CAMPAIGN_PAGE = {
  INFO: "INFO",
  SETTINGS: "SETTINGS",
}

const firstConnectionsSalesNavNewUrl = "text:1st%20Degree%20Connections"
const secondConnectionSalesNavNewUrl = "text:2nd%20Degree%20Connections"
const thirdConnectionSalesNavNewUrl = "text:3rd%20Degree%2B%20Connections"
const groupConnectionSalesNavNewUrl = "text:Group%20members"

const getCampaignType = dashboard => {
  let campaignType = ""
  switch (dashboard) {
    case "1":
      campaignType = "BASIC"
      break
    case "2":
      campaignType = "SALES_NAVIGATOR"
      break
    case "import":
      campaignType = "CSV"
      break
    case "post-engagement":
      campaignType = "POST_ENGAGEMENT"
      break
    case "leads-list":
      campaignType = "LEADS_LIST"
      break
    case "recruiter":
      campaignType = "RECRUITER"
      break
    default:
      break
  }
  return campaignType
}

const getCreateCampaignInitialStateData = (pageType, props) => {
  const campaignUrl = props.formData.campaignUrl || ""

  const searchParams = campaignUrl.substring(campaignUrl.search("\\?"))
  const searchString = queryString.parse(searchParams)

  const dashboard = props.formData.dashboard || "1"
  const { firstConnection, secondConnection, thirdConnection, groupConnection } = props.formData
  const connections = {
    firstConnection: firstConnection || false,
    secondConnection: secondConnection || false,
    thirdConnection: thirdConnection || false,
    groupConnection: groupConnection || false,
  }

  if (dashboard === "1") {
    let selectedFacetNetworks = []
    if (searchString.facetNetwork) {
      selectedFacetNetworks = JSON.parse(searchString.facetNetwork)
    }
    if (searchString.network) {
      const network = JSON.parse(searchString.network)
      selectedFacetNetworks = typeof network === "string" ? [network] : network
      selectedFacetNetworks.forEach(facetNetwork => {
        switch (facetNetwork) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
          default:
            break
        }
      })
    }
  } else if (dashboard === "2") {
    let selectedRelationship = ""
    if (searchString.relationship) {
      selectedRelationship = searchString.relationship
      selectedRelationship.split(",").forEach(relationship => {
        switch (relationship) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
          case "A":
            connections.groupConnection = true
            break
          default:
            break
        }
      })
    }

    if (searchString.query) {
      if (searchString.query.includes(firstConnectionsSalesNavNewUrl)) {
        connections.firstConnection = true
      }

      if (searchString.query.includes(thirdConnectionSalesNavNewUrl)) {
        connections.thirdConnection = true
      }

      if (searchString.query.includes(secondConnectionSalesNavNewUrl)) {
        connections.secondConnection = true
      }

      if (searchString.query.includes(groupConnectionSalesNavNewUrl)) {
        connections.groupConnection = true
      }
    }
  }

  const onlyUniqueLeads =
    typeof props.formData.onlyUniqueLeads !== "undefined" ? props.formData.onlyUniqueLeads : true

  const noPendingConnections =
    typeof props.formData.noPendingConnections !== "undefined"
      ? props.formData.noPendingConnections
      : true

  const state = {
    [CREATE_CAMPAIGN_PAGE.INFO]: {
      dashboard,
      selectedTimezone: props.formData.selectedTimezone || undefined,
      campaignType: getCampaignType(props.formData.dashboard || "1"),
      ...connections,
      onlyPremium: props.formData.onlyPremium || false,
      onlyOpenInmail: props.formData.onlyOpenInmail || false,
      sendPrevious: props.formData.sendPrevious || false,
      getPersonalInfo: props.formData.getPersonalInfo || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks: props.formData.trackEmailsClicks || false,
      trackEmailsOpens: props.formData.trackEmailsOpens || true,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      importWithDuplicatesCsv: false,
      removeCsvDuplicates: false,
      removeDbDuplicates: false,
      startUTCTime: props.formData.startUTCTime || undefined,
      startDate: props.formData.startDate || undefined,
      newUrl: !!searchString.query,
    },
    [CREATE_CAMPAIGN_PAGE.SETTINGS]: {
      dashboard,
      selectedTimezone: props.formData.selectedTimezone || {},
      campaignType: getCampaignType(props.formData.dashboard || "1"),
      ...connections,
      onlyPremium: props.formData.onlyPremium || false,
      onlyOpenInmail: props.formData.onlyOpenInmail || false,
      sendPrevious: props.formData.sendPrevious || false,
      getPersonalInfo: props.formData.getPersonalInfo || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks:
        typeof props.formData.trackEmailsClicks !== "undefined"
          ? props.formData.trackEmailsClicks
          : false,
      trackEmailsOpens:
        typeof props.formData.trackEmailsOpens !== "undefined"
          ? props.formData.trackEmailsOpens
          : true,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      startUTCTime: props.formData.startUTCTime || "",
      startDate: props.formData.startDate || "",
    },
  }

  return state[pageType]
}

export {
  CREATE_CAMPAIGN_PAGE,
  firstConnectionsSalesNavNewUrl,
  secondConnectionSalesNavNewUrl,
  thirdConnectionSalesNavNewUrl,
  groupConnectionSalesNavNewUrl,
  getCreateCampaignRequestData,
  parseIncomingSteps,
  parseCampaignSteps,
  parseDoAfterPreviousStep,
  filterTagInfos,
  getABTestingField,
  getABTestingFields,
  getInitialStateData,
  getCampaignType,
  getCreateCampaignInitialStateData,
}
