import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import userService from "../../services/user-service"
import config from "../../utils/config"
import { setFormErrors } from "../forms/formsActions"
import { history } from "../store"

function loginUserSuccess(userData) {
  return { type: ACTIONS.USER_LOGIN, userData }
}

function userLogin(email, password, recaptchaResponse) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))
      const userData = await userService.loginUser(email, password, recaptchaResponse)
      dispatch(loginUserSuccess(userData.data.result))
      history.replace("/")
    } catch (error) {
      const errorMessages = []
      if (error.response.status === 401) {
        dispatch(setFormErrors({ msg: "Wrong credentials" }))
      }
      if (error.response && error.response.data && error.response.data.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      }
    }
  }
}

function registerUserSuccess(userData) {
  return { type: ACTIONS.USER_REGISTER, userData }
}

function registerUser(email, password, fullName, phone, recaptchaResponse) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))

      const invitationID =
        config.REACT_APP_SECURED_REGISTRATION === "true"
          ? localStorage.getItem("invitationID") || undefined
          : undefined
      const userData = await userService.registerUser(
        email,
        password,
        fullName,
        phone,
        invitationID,
        recaptchaResponse,
      )

      if (invitationID) {
        localStorage.removeItem("invitationID")
      }

      dispatch(registerUserSuccess(userData.data.result))
      history.replace("/")
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          setFormErrors({
            msg: "Please choose a different email, this is already in use!",
          }),
        )
      }
      const errorMessages = []
      if (error.response && error.response.data && error.response.data.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      }
    }
  }
}

function logoutUserSuccess() {
  return { type: ACTIONS.USER_LOGOUT }
}

function logoutUser() {
  return async (dispatch, getState) => {
    const userID = getState().user.profile.id
    userService.logoutUser(userID)
    dispatch(logoutUserSuccess())
    localStorage.clear()
    history.replace("/login")
  }
}

function changePassword(data) {
  return async (_, getState) => {
    try {
      const userID = getState().user.profile.id

      await userService.changePassword(userID, data)
      toast.success("Password successfully changed!")
    } catch (error) {}
  }
}

function resetPassword(email) {
  return async () => {
    try {
      await userService.resetPassword(email)
      toast.success("Email successfuly sent")
      return true
    } catch (error) {
      return false
    }
  }
}

function setPassword(password, data) {
  return async () => {
    try {
      await userService.setPassword({ password, data })
      toast.success("Password successfuly changed. You can now login")
      return true
    } catch (error) {
      return false
    }
  }
}

export { userLogin, registerUser, logoutUser, changePassword, resetPassword, setPassword }
