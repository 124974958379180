import React from "react"
import { connect } from "react-redux"
import { Route } from "react-router"
import { bindActionCreators } from "redux"

import * as appActions from "../../../redux/actions"
import LoadingPage from "../../pages/LoadingPage"

class GuestUserRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  async componentDidMount() {
    const { actions } = this.props
    await actions.getUserData(true)

    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    const { component: Component, ...rest } = this.props
    return loading ? <LoadingPage /> : <Route {...rest} component={Component} />
  }
}

const mapStateToProps = state => ({
  user: state.user.profile,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestUserRoute)
