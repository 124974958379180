export default {
  // api actions
  START_API_CALL: "START_API_CALL",
  API_ERROR: "API_ERROR",
  CLEAR_API_ERROR: "CLEAR_API_ERROR",

  GET_USER_DATA: "GET_USER_DATA",
  USER_LOGIN: "USER_LOGIN",
  GET_USER_ACCOUNTS: "GET_USER_ACCOUNTS",
  USER_REGISTER: "USER_REGISTER",
  USER_LOGOUT: "USER_LOGOUT",
  INIT_FORM: "INIT_FORM",
  CLEAR_FORM: "CLEAR_FORM",
  SET_FORM_ERRORS: "SET_FORM_ERRORS",
  SET_COMPLEX_FORM_ERRORS: "SET_COMPLEX_FORM_ERRORS",
  CLEAR_COMPLEX_STEPS_ERROR: "CLEAR_COMPLEX_STEPS_ERROR",
  CLEAR_FORM_ERROR: "CLEAR_FORM_ERROR",
  CLEAR_FORM_ERRORS: "CLEAR_FORM_ERRORS",
  UPDATE_FORM_FIELD: "UPDATE_FORM_FIELD",
  UPDATE_FORM_FIELDS: "UPDATE_FORM_FIELDS",
  GET_ALL_CAMPAIGNS: "GET_ALL_CAMPAIGNS",
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  GET_LEADS_BY_CAMPAIGN: "GET_LEADS_BY_CAMPAIGN",
  CHANGE_LEAD_ACTIVITY: "CHANGE_LEAD_ACTIVITY",
  DELETE_LEADS: "DELETE_LEADS",
  GET_SETTING_DATA: "GET_SETTING_DATA",
  GET_CAMPAIGN_MESSAGES: "GET_CAMPAIGN_MESSAGES",
  GET_MESSAGES_FOR_IDENTIFIERS: "GET_MESSAGES_FOR_IDENTIFIERS",
  GET_MESSAGES_FOR_THREADS: "GET_MESSAGES_FOR_THREADS",
  GET_BELONGED_LEADS_FOR_THREAD: "GET_BELONGED_LEADS_FOR_THREAD",
  GET_MESSAGES_FOR_LEADS: "GET_MESSAGES_FOR_LEADS",
  SELECT_CHANNEL: "SELECT_CHANNEL",
  SELECT_CHAT: "SELECT_CHAT",
  DESELECT_CHAT: "DESELECT_CHAT",
  GET_THREAD_MESSAGES: "GET_THREAD_MESSAGES",
  GET_IDENTIFIER_MESSAGES: "GET_IDENTIFIER_MESSAGES",
  GET_LEAD_MESSAGES: "GET_LEAD_MESSAGES",
  SET_ACTIVE_ACCOUNT_ID: "SET_ACTIVE_ACCOUNT_ID",
  CLEAR_FILTERED_ITEMS: "CLEAR_FILTERED_ITEMS",
  GET_ALL_WEBHOOKS: "GET_ALL_WEBHOOKS",
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  GET_CAMPAIGN_DETAILS: "GET_CAMPAIGN_DETAILS",
  GET_CAMPAIGN_STEPS: "GET_CAMPAIGN_STEPS",
  GET_PENDING_CONNECTIONS: "GET_PENDING_CONNECTIONS",
  GET_MY_TEMPLATES: "GET_MY_TEMPLATES",
  GET_DEFAULT_TEMPLATES: "GET_DEFAULT_TEMPLATES",
  SET_ADDITIONAL_VARIABLES: "SET_ADDITIONAL_VARIABLES",
  GET_ALL_USERS: "GET_ALL_USERS",
  SET_ACTIVE_USER_ID: "SET_ACTIVE_USER_ID",
  GET_ALL_CHAT_LABELS: "GET_ALL_CHAT_LABELS",
  GET_LEADS_CHAT_LABELS: "GET_LEADS_CHAT_LABELS",
  RELOAD_CHAT_STATE: "RELOAD_CHAT_STATE",
  GET_LAST_FETCHED_CONVERSATION_TIMESTAMP: "GET_LAST_FETCHED_CONVERSATION_TIMESTAMP",
  SEEN_CHAT: "SEEN_CHAT",
  GET_LAST_USED_CARD: "GET_LAST_USED_CARD",
  CLEAR_ALL_USERS: "CLEAR_ALL_USERS",
  GET_ACCOUNT_NOTIFICATIONS: "GET_ACCOUNT_NOTIFICATIONS",
  GET_ACTIVE_ACCOUNT: "GET_ACTIVE_ACCOUNT",
  SEEN_ACCOUNT_NOTIFICATIONS: "SEEN_ACCOUNT_NOTIFICATIONS",
  GET_CAMPAIGN_NAMES: "GET_CAMPAIGN_NAMES",
  GET_PAYMENT_CUSTOMER: "GET_PAYMENT_CUSTOMER",
  GET_ALL_SAVED_REPLIES: "GET_ALL_SAVED_REPLIES",
  GET_ALL_FEATURE_REQUESTS: "GET_ALL_FEATURE_REQUESTS",
  GET_FEATURE_REQUESTS_BY_ID: "GET_FEATURE_REQUESTS_BY_ID",
  REPLACE_CAMPAIGN_MESSAGES: "REPLACE_CAMPAIGN_MESSAGES",
  REMOVE_IDENTIFIER_MESSAGE: "REMOVE_IDENTIFIER_MESSAGE",
  REMOVE_THREAD_MESSAGE: "REMOVE_THREAD_MESSAGE",
  REMOVE_LEAD_MESSAGE: "REMOVE_LEAD_MESSAGE",
  GET_CSV_INFO: "GET_CSV_INFO",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  GET_LEADS_BY_ALL_CAMPAIGNS: "GET_LEADS_BY_ALL_CAMPAIGNS",
  GET_PERSONALIZED_IMAGE_DATA: "GET_PERSONALIZED_IMAGE_DATA",

  // app
  GET_ALL_SUPPORTED_TAGS: "GET_ALL_SUPPORTED_TAGS",
  GET_PROXY_COUNTRIES: "GET_PROXY_COUNTRIES",
  GET_ACCOUNT_STATUSES: "GET_ACCOUNT_STATUSES",
  SHOW_INFO_MODAL: "SHOW_INFO_MODAL",
  SHOW_PREVIOUS_INFO_MODAL: "SHOW_PREVIOUS_INFO_MODAL",
  SET_INFO_MODAL_DATA: "SET_INFO_MODAL_DATA",
  HIDE_INFO_MODAL: "HIDE_INFO_MODAL",

  // user
  GET_ACTIVE_USER: "GET_ACTIVE_USER",
  GET_GLOBAL_SIGNATURES: "GET_GLOBAL_SIGNATURE",
  CREATE_GLOBAL_SIGNATURE: "CREATE_GLOBAL_SIGNATURE",
  UPDATE_GLOBAL_SIGNATURE: "UPDATE_GLOBAL_SIGNATURE",
  DELETE_GLOBAL_SIGNATURE: "DELETE_GLOBAL_SIGNATURE",
  GET_SEAT: "GET_SEAT",
  GET_LINKEDIN_USER: "GET_LINKEDIN_USER",
  GET_GLOBAL_STATUS: "GET_GLOBAL_STATUS",
  GET_OPEN_API_KEY: "GET_OPEN_API_KEY",
  CLEAR_LINKEDIN_USER: "CLEAR_LINKEDIN_USER",

  // account
  GET_ACCOUNT_PROXY_INFO: "GET_ACCOUNT_PROXY_INFO",

  // payment
  GET_PAYMENT_PLANS: "GET_PAYMENT_PLANS",
  GET_AVAILABLE_SEATS: "GET_AVAILABLE_SEATS",
  GET_AVAILABLE_SEATS_FOR_WHITELABEL: "GET_AVAILABLE_SEATS_FOR_WHITELABEL",
  GET_ACCOUNT_SPECIFIC_PRICES: "GET_ACCOUNT_SPECIFIC_PRICES",
  GET_PRICE_PREVIEW: "GET_PRICE_PREVIEW",

  // settings
  GET_AUTHENTICATED_EMAILS: "GET_AUTHENTICATED_EMAILS",
  SET_ACCOUNT_HEALTH: "SET_ACCOUNT_HEALTH",

  // statistics
  GET_STATISTICS: "GET_STATISTICS",
  GET_CAMPAIGN_STEPS_STATISTICS: "GET_CAMPAIGN_STEPS_STATISTICS",
  GET_STATISTICS_TOTAL_COUNT: "GET_STATISTICS_TOTAL_COUNT",

  // campaigns
  SELECT_DRAFT_CAMPAIGN: "SELECT_DRAFT_CAMPAIGN",
  UPDATE_DRAFT_CAMPAIGN: "UPDATE_DRAFT_CAMPAIGN",
  SET_SELECTED_NODE: "SET_SELECTED_NODE",
  SET_SELECTED_TAB: "SET_SELECTED_TAB",
  GET_SAVED_SEQUENCES: "GET_SAVED_SEQUENCES",
}
