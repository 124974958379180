import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"

import accountReducer from "./account/accountReducer"
import appReducer from "./app/appReducer"
import campaignReducer from "./campaign/campaignReducer"
import chatReducer from "./chat/chatReducer"
import formsReducer from "./forms/formsReducer"
import imagePersonalizationReducer from "./imagePersonalization/imagePersonalizationReducer"
import paymentReducer from "./payment/paymentReducer"
import statisticsReducer from "./statistics/statisticsReducer"
import userReducer from "./user/userReducer"

export default history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    account: accountReducer,
    forms: formsReducer,
    payment: paymentReducer,
    campaign: campaignReducer,
    chat: chatReducer,
    imagePersonalization: imagePersonalizationReducer,
    statistics: statisticsReducer,
  })
