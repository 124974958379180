/* eslint-disable max-classes-per-file */
import queryString from "query-string"
import React from "react"
import ReactQuill from "react-quill"

import { showInfoModal } from "../redux/actions"
import * as actions from "../redux/actions"
import { store } from "../redux/store"
import { getLeadLabels } from "./label-utils"

function showTagReplacement(tag) {
  return !["firstName", "lastName", "unsubscribe"].includes(tag)
}

function arrayUnique(array) {
  const a = array.concat()
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}

function formatVariableTags(tagKeys, tagObject) {
  const allTagObject = []
  tagKeys.forEach(tagKey => {
    if (tagObject[tagKey]) {
      allTagObject.push(tagObject[tagKey])
    } else {
      allTagObject.push({
        tag: tagKey,
        description: "Custom variable will use the value provided under this name in a csv file",
      })
    }
  })
  return allTagObject
}

function getDecimalCount(value) {
  if (!value) return 0
  if (Math.floor(value) === value) return 0
  return value.toString().split(".")[1].length || 0
}

function filterProxy(proxy) {
  switch (proxy) {
    // case "gb":
    //   return "de"
    // case "nl":
    //   return "de"
    // case "au":
    //   return "us"

    default:
      return proxy
  }
}

// Function to rotate a point.
// pt = {x,y} center,
// o = {x, y} inital cordinate,
// a = angle of rotation in degrees.
// returns {x, y} giving the new point.
function rotatePoint(pointCords, initialCords) {
  const angle = initialCords.angle * (Math.PI / 180) // Convert to radians

  const rotatedX =
    Math.cos(angle) * (pointCords.x - initialCords.x) -
    Math.sin(angle) * (pointCords.y - initialCords.y) +
    initialCords.x

  const rotatedY =
    Math.sin(angle) * (pointCords.x - initialCords.x) +
    Math.cos(angle) * (pointCords.y - initialCords.y) +
    initialCords.y

  return { x: rotatedX, y: rotatedY }
}

function getPointByIndex(index, { x, y, width, height }) {
  const pointCords = {
    x: 0,
    y: 0,
  }

  switch (index) {
    case 0:
      pointCords.x = x
      pointCords.y = y
      break

    case 1:
      pointCords.x = x + width
      pointCords.y = y
      break

    case 2:
      pointCords.x = x + width
      pointCords.y = y + height
      break

    case 3:
      pointCords.x = x
      pointCords.y = y + height
      break
  }

  return pointCords
}

function getTransformCordinates(newCordinates, cordinateLimits, outOfBorder) {
  // add prevCodinates to function parameters, removed because eslinter
  let isOutOfBorder = false

  for (let i = 0; i < 4; i++) {
    const pointCords = getPointByIndex(i, newCordinates)
    const rotatedPointCords = rotatePoint(pointCords, newCordinates)

    if (
      rotatedPointCords.x < 0 ||
      rotatedPointCords.x > cordinateLimits.width ||
      rotatedPointCords.y < 0 ||
      rotatedPointCords.y > cordinateLimits.height
    ) {
      isOutOfBorder = true
      break
    }
  }

  // return canMove ? newCordinates : prevCodinates
  return outOfBorder ? isOutOfBorder : newCordinates
}

function getCloudinaryCordinates(newCordinates) {
  const newCords = { ...newCordinates }
  for (let i = 0; i < 4; i++) {
    const pointCords = getPointByIndex(i, newCordinates)
    const rotatedPointCords = rotatePoint(pointCords, newCordinates)
    if (rotatedPointCords.x < newCords.x) {
      newCords.x = rotatedPointCords.x
    }
    if (rotatedPointCords.y < newCords.y) {
      newCords.y = rotatedPointCords.y
    }
  }

  return newCords
}

const addNewMessages = newMessage => {
  const { campaignID, selectedConversation, selectedChannel } = store.getState().chat

  let selector
  let selectorData
  let allMessages

  try {
    if (campaignID === "all") {
      const { identifierMessages } = store.getState().chat
      allMessages = { ...identifierMessages }

      selector = selectedConversation.linkedinUser.identifier
      selectorData = allMessages[selector]
    } else if (["unread", "other"].includes(campaignID)) {
      const { threadMessages } = store.getState().chat
      selector = selectedChannel.thread
      selectorData = threadMessages[selector]

      return {
        ...threadMessages,
        [selector]: [...selectorData, newMessage],
      }
    } else {
      const { leadMessages } = store.getState().chat
      allMessages = { ...leadMessages }

      selector = selectedConversation.lead.id
      selectorData = allMessages[selector]
    }

    const channelData = selectorData.channels[selectedChannel.channel]
    const channelThread = channelData.threads

    const data = {
      ...allMessages,
      [selector]: {
        ...selectorData,
        channels: {
          ...selectorData.channels,
          [selectedChannel.channel]: {
            ...channelData,
            threads: {
              ...channelThread,
              [selectedChannel.thread]: [...channelThread[selectedChannel.thread], newMessage],
            },
          },
        },
      },
    }
    return data
  } catch (error) {}
}

const getMessages = location => {
  const params = queryString.parse(location.search)
  const { thread } = params
  const { campaignID, selectedConversation, selectedChannel } = store.getState().chat
  const { firstChatLoading } = store.getState().forms.formData

  if (selectedConversation || firstChatLoading) {
    let selectorData
    let byThreads = false
    if (firstChatLoading) {
      const { threadMessages } = store.getState().chat
      selectorData = threadMessages[thread]
      byThreads = true
    } else if (campaignID === "all") {
      const { identifierMessages } = store.getState().chat
      selectorData = identifierMessages[selectedConversation.linkedinUser.identifier]
    } else if (["unread", "other"].includes(campaignID)) {
      const { threadMessages } = store.getState().chat
      selectorData = threadMessages[selectedChannel.thread]
      byThreads = true
    } else {
      const { leadMessages } = store.getState().chat
      selectorData = leadMessages[selectedConversation.lead.id]
    }

    if (selectorData) {
      if (byThreads) {
        return {
          channels: [],
          messages: selectorData,
        }
      }
      if (selectorData.channels && selectorData.channels[selectedChannel.channel]) {
        const allChannels = Object.keys(selectorData.channels)
        const allThreadChannels = []

        for (let i = 0; i < allChannels.length; i++) {
          const currentChannel = allChannels[i]
          const channelThreads = Object.keys(selectorData.channels[currentChannel].threads)

          for (let j = 0; j < channelThreads.length; j++) {
            const currentThread = channelThreads[j]
            allThreadChannels.push({
              channel: +currentChannel,
              thread: currentThread,
              createdAt: selectorData.channels[currentChannel].threads[currentThread][0]?.createdAt,
              seenAt: selectorData.channels[currentChannel].threads[currentThread][0]?.seenAt,
            })
          }
        }

        const allChannelThreads = Object.keys(
          selectorData.channels[selectedChannel.channel].threads,
        )

        if (allChannelThreads && allChannelThreads.length > 0) {
          return {
            channels: allThreadChannels,
            messages:
              selectorData.channels[selectedChannel.channel].threads[selectedChannel.thread],
          }
        }
      }
    }
  }

  return {
    channels: [],
    messages: [],
  }
}

const mapConversations = (conversations, allMessages, campaignID) => {
  return conversations.map(conversation => {
    let labels = []
    if (conversation.lead.id) {
      labels = getLeadLabels(conversation.lead.id)
    }

    let byThreads = false
    let selector = conversation.linkedinUser.identifier
    if (campaignID === "all") {
      selector = conversation.linkedinUser.identifier
    } else if (["unread", "other"].includes(campaignID)) {
      selector = conversation.thread
      byThreads = true
    } else {
      selector = allMessages[conversation.lead.id]
        ? conversation.lead.id
        : conversation.linkedinUser.identifier
    }

    let lastChannelThread = ""
    const selectorData = allMessages[selector]
    let unreadMessageFound = false
    if (selectorData) {
      if (byThreads) {
        lastChannelThread = selectorData[selectorData.length - 1]
        /** Checking if we have any unread message */
        if (!unreadMessageFound) {
          const unreadMessage = selectorData.find(data => data.seenAt === null)
          if (unreadMessage) {
            unreadMessageFound = true
            lastChannelThread.seenAt = null
          }
        }
      } else {
        const allChannels = Object.keys(selectorData.channels)

        let maxCreatedAt = 0
        allChannels.forEach(channel => {
          const channelThreads = Object.keys(selectorData.channels[channel].threads)
          channelThreads.forEach(currentThread => {
            const channelThread = selectorData.channels[channel].threads[currentThread]

            if (channelThread) {
              const lastMessageAtInChannelThread = channelThread[channelThread.length - 1]
              /** Checking if we have any unread message */
              if (!unreadMessageFound) {
                const unreadMessage = channelThread.find(data => data.seenAt === null)
                if (unreadMessage) {
                  unreadMessageFound = true
                }
              }
              if (maxCreatedAt < lastMessageAtInChannelThread.createdAt) {
                maxCreatedAt = lastMessageAtInChannelThread.createdAt
                lastChannelThread = lastMessageAtInChannelThread
                if (unreadMessageFound) lastChannelThread.seenAt = null
                // TODO: Change seen in reducer
                if (conversation.lastChannelThread) {
                  lastChannelThread.seenAt = conversation.lastChannelThread.seenAt
                }
              }
            }
          })
        })
      }
    }
    return {
      ...conversation,
      labels,
      lastChannelThread,
    }
  })
}

const removeHtmlInlineStyle = (editor, selector, styleProperty) => {
  const selectedSelector = editor.querySelectorAll(`[style*=${selector}]`)
  for (let i = 0; i < selectedSelector.length; i++) {
    const node = selectedSelector[i]
    node.style[styleProperty] = null
  }
}

const transformTagWithInlineStyle = (doc, tagSelector, inlineStyles) => {
  const selectedSelector = doc.querySelectorAll(tagSelector)
  for (let i = 0; i < selectedSelector.length; i++) {
    const node = selectedSelector[i]

    Object.keys(inlineStyles).forEach(styleKey => {
      node.style[styleKey] = inlineStyles[styleKey]
    })
  }
}

const transformToInlineStyle = (
  doc,
  classSelector,
  styleProperty,
  defaultSelectorValue,
  styleValueParser = value => value,
) => {
  const selectedSelector = doc.querySelectorAll(`[class*=${classSelector}]`)
  for (let i = 0; i < selectedSelector.length; i++) {
    const node = selectedSelector[i]
    const selectedClass = node.classList
      .toString()
      .split(" ")
      .filter(c => c.startsWith(classSelector))[0]

    let selectorValue = defaultSelectorValue
    if (selectedClass) {
      selectorValue = selectedClass.replace(classSelector, "")
    }

    node.style[styleProperty] = styleValueParser(selectorValue)
    node.classList.remove(selectedClass)
    if (node.className === "") {
      node.removeAttribute("class")
    }
  }
}

const transformInlineStyleToClassNames = (
  doc,
  classSelector,
  styleProperty,
  defaultSelectorValue,
  styleValueParser = value => value,
) => {
  const selectedSelector = doc.querySelectorAll(`[style*=${styleProperty}]`)
  for (let i = 0; i < selectedSelector.length; i++) {
    const node = selectedSelector[i]
    const stringStartIndex = node.style.cssText.indexOf(styleProperty)

    const newStr = node.style.cssText.substring(stringStartIndex)
    const selectedStyle = newStr.split(": ")[1].split(";")[0]

    let selectorValue = defaultSelectorValue
    if (selectedStyle) {
      selectorValue = selectedStyle
    }

    node.classList.add(classSelector + styleValueParser(selectorValue))
    node.style.removeProperty(styleProperty)
  }
}

const getHtmlFontFamily = font => {
  return {
    arial: "Arial, Helvetica, sans-serif",
    couriernew: "Courier New, serif",
    garamond: "garamond, 'times new roman', serif",
    georgia: "Georgia, serif",
    helvetica: "Helvetica, sans-serif",
    impact: "Impact, Charcoal, sans-serif",
    lucida: "Lucida Console, Courier New, serif",
    timesnewroman: "Times New Roman, Times, serif, -webkit-standard",
    tahoma: "Tahoma, Geneva, Helvetica, sans-serif",
    trebuchet: "'trebuchet ms', Helvetica, sans-serif",
    verdana: "Verdana, Geneva, Helvetica, sans-serif",
    monospace: "monospace",
    wide: "'arial black', sans-serif",
    narrow: "'arial narrow', sans-serif",
    comicsansms: "'comic sans ms', sans-serif",
  }[font]
}

const getHtmlClassForFontFamily = font => {
  return {
    "Arial, Helvetica, sans-serif": "arial",
    "Courier New, serif": "couriernew",
    "garamond, 'times new roman', serif": "garamond",
    "Georgia, serif": "georgia",
    "Helvetica, sans-serif": "helvetica",
    "Impact, Charcoal, sans-serif": "impact",
    "Lucida Console, Courier New, serif": "lucida",
    "Times New Roman, Times, serif, -webkit-standard": "timesnewroman",
    "Tahoma, Geneva, Helvetica, sans-serif": "tahoma",
    "'trebuchet ms', Helvetica, sans-serif": "trebuchet",
    "Verdana, Geneva, Helvetica, sans-serif": "verdana",
    monospace: "monospace",
    "'arial black', sans-serif": "wide",
    "'arial narrow', sans-serif": "narrow",
    "'comic sans ms', sans-serif": "comicsansms",
  }[font]
}

const transformTagWithoutInlineStyle = (editor, allStyleProperties) => {
  allStyleProperties.forEach(styleProperty => {
    const selectedSelector = editor.querySelectorAll(`[style*=${styleProperty}]`)
    for (let i = 0; i < selectedSelector.length; i++) {
      const node = selectedSelector[i]
      node.style[styleProperty] = null
      if (!node.style.cssText) {
        node.removeAttribute("style")
      }
    }
  })
}

const prepareIndent = editor => {
  const doc = new DOMParser().parseFromString(editor, "text/html")

  // intend
  transformToInlineStyle(doc, "ql-indent-", "padding-left", 0, value => `${3 * value}em`)

  // text align
  transformToInlineStyle(doc, "ql-align-", "text-align", "left")

  // font family
  transformToInlineStyle(doc, "ql-font-", "font-family", "arial", getHtmlFontFamily)

  // font size
  transformToInlineStyle(doc, "ql-size-", "size", "1em")

  transformTagWithInlineStyle(doc, "p", {
    margin: "0",
    "box-sizing": "border-box",
  })

  transformTagWithInlineStyle(doc, "br", {
    "box-sizing": "border-box",
  })

  transformTagWithInlineStyle(doc, "blockquote", {
    "margin-top": "0",
    "margin-bottom": "0",
  })

  transformTagWithInlineStyle(doc, "ol", {
    "margin-top": "0",
    "margin-bottom": "0",
  })

  transformTagWithInlineStyle(doc, "ul", {
    "margin-top": "0",
    "margin-bottom": "0",
  })

  transformTagWithInlineStyle(doc, "h1", {
    "margin-top": "0",
    "margin-bottom": "0",
  })
  transformTagWithInlineStyle(doc, "h2", {
    "margin-top": "0",
    "margin-bottom": "0",
  })
  transformTagWithInlineStyle(doc, "h3", {
    "margin-top": "0",
    "margin-bottom": "0",
  })
  transformTagWithInlineStyle(doc, "h4", {
    "margin-top": "0",
    "margin-bottom": "0",
  })
  transformTagWithInlineStyle(doc, "h5", {
    "margin-top": "0",
    "margin-bottom": "0",
  })
  transformTagWithInlineStyle(doc, "h6", {
    "margin-top": "0",
    "margin-bottom": "0",
  })

  const preparedHtml = doc.getElementsByTagName("body").item(0).innerHTML

  return preparedHtml
}

const getEditCampaignHTML = (editor, oldHTML = false) => {
  if (oldHTML) {
    removeHtmlInlineStyle(editor, "font-family", "fontFamily")
  } else {
    // intend
    transformInlineStyleToClassNames(
      editor,
      "ql-indent-",
      "padding-left",
      0,
      value => +value.split("em")[0] / 3,
    )

    // text align
    transformInlineStyleToClassNames(editor, "ql-align-", "text-align", "left")

    // font family
    transformInlineStyleToClassNames(
      editor,
      "ql-font-",
      "font-family",
      "arial",
      getHtmlClassForFontFamily,
    )

    // font size
    transformInlineStyleToClassNames(editor, "ql-size-", "size", "1em")
  }

  return editor.innerHTML
}

const parseImageInEmailInput = htmlText => {
  const htmlDom = new DOMParser().parseFromString(htmlText, "text/html")
  const imageTemplate = htmlDom.querySelector(".image-template")
  const tagSpan = document.createElement("span")
  tagSpan.innerText = "{{personalizedImage}}"

  if (imageTemplate) imageTemplate.replaceWith(tagSpan)
  return htmlDom.documentElement.innerHTML
}

const createHTMLEmailTemplate = (innerHTML, noHTML = false) => {
  const parsedHtml = parseImageInEmailInput(innerHTML)

  const parserHTML = prepareIndent(parsedHtml)

  if (noHTML) {
    return parserHTML
  }
  return `<html>${prepareIndent(parserHTML)}</html>`
}

const getHtmlInnerText = (htmlText, space = true, personalizedImage = false) => {
  const htmlDom = new DOMParser()
    .parseFromString(htmlText, "text/html")
    .getElementsByTagName("body")
    .item(0)

  if (personalizedImage) {
    const personalizedImg = htmlDom.querySelectorAll(".template-image")
    for (let i = 0; i < personalizedImg.length; i++) {
      const tag = document.createElement("P")
      tag.textContent = "{{personalizedImage}}"
      personalizedImg[i].append(tag)
    }
  }

  if (space) {
    const children = htmlDom.querySelectorAll("*")
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += " "
      else children[i].innerText += " "
    }
  }
  return [htmlDom.textContent || htmlDom.innerText].toString().replace(/ +/g, " ")
}

const getParsedMessagePreview = (plainMessage, subject, maxChars = 150) => {
  let message = plainMessage || ""

  const isHTMLmessage = message.match(/<\/?[a-z][\s\S]*>/i)

  if (isHTMLmessage) {
    let preMessage = ""
    if (subject) {
      preMessage = `${subject} - `
    }

    message = `${preMessage}${getHtmlInnerText(message)}`
  }

  if (maxChars && message.length > maxChars) {
    message = `${message.substring(0, 150)}...`
  }

  return {
    message,
    isHTMLmessage,
  }
}

function insertUnsubscribe() {
  const cursorPosition = this.quill.getSelection().index

  this.quill.insertText(cursorPosition, "unsubscribe", "link", "{{unsubscribe}}")
}

function quillInitStepEmailValues(
  editorValue,
  changeSelectedNodeData,
  personalizedImageData,
  personalizedImageIds,
) {
  const { Quill } = ReactQuill
  const Parchment = Quill.imports.parchment
  const Delta = Quill.imports.delta

  class PersonalizedImage extends Parchment.Embed {
    static create(value) {
      const node = super.create()
      node.classList.add("noselect-text")
      node.contentEditable = "false"
      this.addImage(node, value)
      this.addRemovalButton(node)
      // editor.focus() => error with leaf is undefined, don't know what do we need this for, but potential solutions could be next line
      // editor.setSelection( 0, 0 );

      node.ondragend = () => {
        const src = node.childNodes[0].getAttribute("src")

        const quillDom = node.parentNode?.parentNode
        let allQuilImages = []
        if (quillDom) {
          allQuilImages = quillDom.querySelectorAll(`img[src='${src}']:not(.template-image)`)
        }

        if (allQuilImages.length === 1) {
          return
        }

        node.parentNode.removeChild(node)
        const imgsToReplace = document.querySelectorAll(`img[src='${src}']:not(.template-image)`)

        if (imgsToReplace[imgsToReplace.length - 1]) {
          this.replaceImg(imgsToReplace[imgsToReplace.length - 1], node)
        }
      }
      return node
    }

    static value(node) {
      return node
    }

    static replaceImg(node, parentNodeTemplate) {
      if (!node.parentNode.classList.contains("image-template")) {
        return node.parentNode.replaceChild(parentNodeTemplate, node)
      }

      return this.replaceImg(node.parentNode, parentNodeTemplate)
    }

    static getUrl(node) {
      if (!node.childNodes.length) {
        return ""
      }

      if (node.childNodes[0].getAttribute("src")) {
        return node.childNodes[0].getAttribute("src")
      }

      return this.getUrl(node.childNodes[0])
    }

    static addImage(node, value) {
      const imgUrl = value.childNodes ? this.getUrl(value) : value.url
      const img = document.createElement("img")
      img.setAttribute("src", imgUrl)
      img.onclick = () =>
        store.dispatch(
          showInfoModal(
            "basic",
            "Template",
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                src={imgUrl}
                height="auto"
                style={{ objectFit: "contain", width: "max-content" }}
              />
            </div>,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "image-template__template-modal",
          ),
        )
      node.appendChild(img)
    }

    static addRemovalButton(node) {
      const div = document.createElement("div")
      div.className = "image-template__delete"

      const { personalizedImageIdsRedux } = store.getState().forms.formData
      const { personalizedImageDataRedux } = store.getState().forms.formData

      let newPersonalizedImageIds = [...(personalizedImageIdsRedux || [])]
      let newPersonalizedImageData = [...(personalizedImageDataRedux || [])]

      if (newPersonalizedImageIds.length === 0) {
        newPersonalizedImageIds = [...(personalizedImageIds || [])]
      }

      if (newPersonalizedImageData.length === 0) {
        newPersonalizedImageData = [...(personalizedImageData || [])]
      }

      div.onclick = () => {
        node.remove()

        editorValue.updateContents(
          new Delta().insert({
            insert: " ",
          }),
        )
        const activeMessageIndexRedux = store.getState().forms.formData.activeMessageIndex

        newPersonalizedImageIds[activeMessageIndexRedux] = null
        newPersonalizedImageData[activeMessageIndexRedux] = {}

        changeSelectedNodeData({
          personalizedImageIds: newPersonalizedImageIds,
          personalizedImageData: newPersonalizedImageData,
        })
        store.dispatch(
          actions.updateFormFields({
            personalizedImageIds: newPersonalizedImageIds,
            personalizedImageData: newPersonalizedImageData,
          }),
        )
      }
      node.appendChild(div)
    }
  }
  PersonalizedImage.blotName = "personalizedImage"
  PersonalizedImage.tagName = "div"
  PersonalizedImage.className = "image-template"

  Quill.register(PersonalizedImage)
}

function quillInitValues() {
  const { Quill } = ReactQuill

  const Clipboard = Quill.import("modules/clipboard")

  class PreventPersonalizedImageInClipboard extends Clipboard {
    /* eslint class-methods-use-this: ["error", { "exceptMethods": ["onPaste"] }] */
    onPaste(e) {
      const html = e.clipboardData.getData("text/html")
      if (html.includes("image-template")) {
        e.preventDefault()
        store.dispatch(
          showInfoModal(
            "warning",
            "Warning",
            `Please use "Add image" button to insert personalized image.`,
          ),
        )
      }
      super.onPaste(e)
    }
  }

  Quill.register("modules/clipboard", PreventPersonalizedImageInClipboard, true)

  const Block = Quill.import("blots/block")
  Block.tagName = "DIV"
  Quill.register(Block, true)

  const Font = Quill.import("formats/font")
  const fonts = [
    "arial",
    "couriernew",
    "garamond",
    "georgia",
    "helvetica",
    "impact",
    "lucida",
    "timesnewroman",
    "tahoma",
    "trebuchet",
    "verdana",
    "monospace",
    "wide",
    "narrow",
    "comicsansms",
  ]

  Font.whitelist = fonts

  Quill.register(Font, true)

  const Size = Quill.import("attributors/style/size")

  const sizes = ["small", "normal", "large"]
  Size.whitelist = sizes

  Quill.register(Size, true)

  const Link = Quill.import("formats/link")

  class CustomLink extends Link {
    static sanitize(url) {
      const value = super.sanitize(url)
      if (
        value &&
        !value.includes("http://") &&
        !value.includes("https://") &&
        value !== "{{unsubscribe}}"
      ) {
        return `http://${value}`
      }
      return value
    }
  }

  Quill.register(CustomLink)

  const icons = Quill.import("ui/icons")
  icons.bold = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
  <g id="Group_32649" data-name="Group 32649" transform="translate(0 -0.134)">
    <path id="Path_15869" data-name="Path 15869" d="M40.143,30.45a5.18,5.18,0,0,0,.495-5.53A5.451,5.451,0,0,0,35.793,22H29V40.527h8.83a5.4,5.4,0,0,0,5.29-4.139A5.255,5.255,0,0,0,40.143,30.45Zm-8.426-5.8h4.076a2.648,2.648,0,1,1,0,5.294H31.717ZM37.83,37.881H31.717V32.587H37.83a2.648,2.648,0,1,1,0,5.294Z" transform="translate(-21.874 -16.323)" fill="#787e91"/>
    <rect id="Rectangle_4093" data-name="Rectangle 4093"  transform="translate(0 0.134)" fill="none"/>
  </g>
</svg>`

  icons.italic = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28">
  <g id="Group_32650" data-name="Group 32650" transform="translate(0.006 -0.134)">
    <g id="Group_12190" data-name="Group 12190" transform="translate(6.759 4.187)">
      <path id="Path_15866" data-name="Path 15866" d="M18.18,2.795H11.9A.9.9,0,0,1,11.9,1H18.18a.9.9,0,0,1,0,1.795Z" transform="translate(-5.051 -0.999)" fill="#787e91"/>
      <path id="Path_15867" data-name="Path 15867" d="M11.18,22.795H4.9A.9.9,0,0,1,4.9,21H11.18a.9.9,0,0,1,0,1.795Z" transform="translate(-4 -3.158)" fill="#787e91"/>
      <path id="Path_15868" data-name="Path 15868" d="M7.864,20.636a.84.84,0,0,1-.32-.062.878.878,0,0,1-.475-.477.92.92,0,0,1-.009-.684L13.974,1.562a.856.856,0,0,1,1.123-.5.9.9,0,0,1,.484,1.16L8.668,20.074a.865.865,0,0,1-.8.562Z" transform="translate(-4.307 -0.999)" fill="#787e91"/>
    </g>
    <rect id="Rectangle_4094" data-name="Rectangle 4094"  transform="translate(-0.006 0.134)" fill="none"/>
  </g>
</svg>`

  icons.underline = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28">
  <g id="Group_32652" data-name="Group 32652" transform="translate(0.012 0.14)">
    <g id="Group_12199" data-name="Group 12199" transform="translate(4.609 5.555)">
      <path id="Path_15865" data-name="Path 15865" d="M6,3v7.466c0,3.534,2.5,6.4,5.576,6.4s5.576-2.865,5.576-6.4V3" transform="translate(-2.75 -3)" fill="none" stroke="#787e91" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_674" data-name="Line 674" x2="17" transform="translate(0.379 19.305)" fill="none" stroke="#787e91" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
    <rect id="Rectangle_4095" data-name="Rectangle 4095" transform="translate(-0.012 -0.14)" fill="none"/>
  </g>
`

  icons.color = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
  <g id="Group_32651" data-name="Group 32651" transform="translate(-0.078 -0.134)">
    <rect id="Rectangle_4092" data-name="Rectangle 4092"  transform="translate(0.077 0.134)" fill="none"/>
    <g id="Group_12197" data-name="Group 12197" transform="translate(5.912 4.133)">
      <g id="Group_12192" data-name="Group 12192" transform="translate(0.922)">
        <g id="Group_12186" data-name="Group 12186">
          <path id="Path_15863" data-name="Path 15863" d="M30.641,39.4H28L34.221,23h2.641Z" transform="translate(-28 -23)" fill="#787e91"/>
        </g>
        <g id="Group_12187" data-name="Group 12187" transform="translate(6.243)">
          <path id="Path_15864" data-name="Path 15864" d="M44.6,39.4h2.641L40.641,23H38Z" transform="translate(-38 -23)" fill="#787e91"/>
        </g>
        <g id="Group_12188" data-name="Group 12188" transform="translate(3.138 10.664)">
          <rect id="Rectangle_4087" data-name="Rectangle 4087" width="8.582" height="2.353" fill="#787e91"/>
        </g>
      </g>
      <line id="Line_675" data-name="Line 675" x2="17" transform="translate(0.167 19.932)" fill="none" stroke="#787e91" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </g>
</svg>
`

  icons.background = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28">
  <g id="Group_32653" data-name="Group 32653" transform="translate(-0.072 -0.134)">
    <g id="Group_12185" data-name="Group 12185" transform="translate(4.073 2.132)">
      <path id="Path_15862" data-name="Path 15862" d="M38.661,964.216c-3.681,4.475-7.541,8.878-11.023,13.44l8.436,3.652c3.4-4.614,6.488-9.368,9.723-14.053.308-.433.153-.744-.416-.974l-5.2-2.3A1.417,1.417,0,0,0,38.661,964.216ZM27.054,978.844c.22,1,.664,2.008.923,3.165l2.834,1.344c.8-.175,2.892-.627,4.263-1.032Zm.91,3.9c-.812,1.073-1.7,2.24-1.7,2.24l2.509.39s.267-.361,1.248-1.656ZM25,986.548v1.247H45.953v-1.247Z" transform="translate(-25 -963.862)" fill="#787e91"/>
    </g>
    <rect id="Rectangle_4096" data-name="Rectangle 4096" transform="translate(0.073 0.133)" fill="none"/>
  </g>
</svg>
`

  icons.link = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
  <g id="Group_32659" data-name="Group 32659" transform="translate(-0.133 -0.134)">
    <g id="Group_30589" data-name="Group 30589" transform="translate(4.201 4.201)">
      <path id="Path_35546" data-name="Path 35546" d="M10.147,972.31a4.62,4.62,0,1,0,6.533,6.534l3.267-3.267a4.625,4.625,0,0,0,0-6.534.693.693,0,0,0-.98.98,3.212,3.212,0,0,1,0,4.573L15.7,977.863a3.234,3.234,0,1,1-4.573-4.573l2.94-2.94a.693.693,0,1,0-.98-.98l-2.94,2.94Zm6.534-6.533a4.625,4.625,0,0,0,0,6.533.693.693,0,1,0,.98-.98,3.212,3.212,0,0,1,0-4.573l3.267-3.267a3.234,3.234,0,1,1,4.573,4.573L22.561,971a.693.693,0,1,0,.98.98l2.94-2.94a4.62,4.62,0,1,0-6.533-6.533l-3.267,3.267Z" transform="translate(-8.796 -961.159)" fill="#787e91"/>
    </g>
    <rect id="Rectangle_4104" data-name="Rectangle 4104" transform="translate(0.133 0.133)" fill="none"/>
  </g>
</svg>
`
  icons.strike = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
  <g id="Group_32656" data-name="Group 32656" transform="translate(-0.055 -0.134)">
    <g id="Group_12206" data-name="Group 12206" transform="translate(3.702 4.203)">
      <path id="Path_15870" data-name="Path 15870" d="M22.116,17.657v1.5H17.29a5.764,5.764,0,0,1,.883,3.027,5.825,5.825,0,0,1-2.069,4.4,6.88,6.88,0,0,1-4.525,1.624,6.88,6.88,0,0,1-4.525-1.624,5.825,5.825,0,0,1-2.069-4.4h2.65c-.043,1.624,1.767,3,3.922,3s3.965-1.378,3.965-3-1.81-3.027-3.965-3.027H1v-1.5H7.184a.926.926,0,0,0-.151-.123,5.724,5.724,0,0,1,0-8.81A6.88,6.88,0,0,1,11.558,7.1a6.88,6.88,0,0,1,4.525,1.624,5.825,5.825,0,0,1,2.069,4.4H15.523c0-1.624-1.81-3-3.965-3s-3.965,1.378-3.965,3,1.81,3.027,3.965,3.027a7,7,0,0,1,4.374,1.5Z" transform="translate(-1 -7.1)" fill="#787e91"/>
    </g>
    <rect id="Rectangle_4098" data-name="Rectangle 4098"  transform="translate(0.055 0.134)" fill="none"/>
  </g>
</svg>
`

  icons.clean = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
  <g id="Group_32657" data-name="Group 32657" transform="translate(-0.145 -0.134)">
    <g id="Group_12216" data-name="Group 12216" transform="translate(4.711 4.201)">
      <g id="Group_12215" data-name="Group 12215">
        <g id="Group_12214" data-name="Group 12214">
          <path id="Path_15874" data-name="Path 15874" d="M26.355,21.075l1.058,1.007a.918.918,0,0,1,0,1.347,1.037,1.037,0,0,1-1.416,0l-1.058-1.007-1.058,1.007a1.035,1.035,0,0,1-1.416,0,.92.92,0,0,1,0-1.347l1.058-1.007L22.47,20.068a.918.918,0,0,1,0-1.347,1.037,1.037,0,0,1,1.416,0l1.058,1.007L26,18.723a1.035,1.035,0,0,1,1.416,0,.92.92,0,0,1,0,1.347ZM18.6,5.9,15.4,21.123h3.6a.953.953,0,1,1,0,1.9H9a.953.953,0,1,1,0-1.9h4.4L16.6,5.9H13A.953.953,0,1,1,13,4h9.99a.953.953,0,1,1,0,1.9Z" transform="translate(-8 -4)" fill="#787e91" fill-rule="evenodd"/>
        </g>
      </g>
    </g>
    <rect id="Rectangle_4102" data-name="Rectangle 4102"  transform="translate(0.146 0.134)" fill="none"/>
  </g>
</svg>
`
}

const capitalizeString = string => {
  if (typeof string === "string" && string !== "") {
    const capitalizedWordsArray = string
      .split(" ")
      .filter(w => w)
      .map(word => {
        const loweredWord = word.toLowerCase()
        return `${loweredWord[0].toUpperCase()}${loweredWord.substring(1)}`
      })
    return capitalizedWordsArray.join(" ")
  }

  return string
}

const filterUniqueObject = array => {
  const flags = []
  const output = []

  for (let i = 0; i < array.length; i++) {
    if (flags[array[i].id])
      // eslint-disable-next-line
      continue
    flags[array[i].id] = true
    output.push(array[i])
  }

  return output
}

export default {
  showTagReplacement,
  arrayUnique,
  formatVariableTags,
  getDecimalCount,
  filterProxy,
  rotatePoint,
  getPointByIndex,
  getTransformCordinates,
  getCloudinaryCordinates,
  mapConversations,
  getMessages,
  addNewMessages,
  createHTMLEmailTemplate,
  getEditCampaignHTML,
  transformInlineStyleToClassNames,
  transformTagWithoutInlineStyle,
  getHtmlInnerText,
  getParsedMessagePreview,
  insertUnsubscribe,
  quillInitValues,
  quillInitStepEmailValues,
  capitalizeString,
  filterUniqueObject,
}
