// legacy, params has been changed by LinkedIn
const basicSearchFilters = [
  "authorIndustry=",
  "authorCompany=",
  "contactInterest=",
  "facetCity=",
  "facetCompany=",
  "facetConnectionOf=",
  "facetCurrentCompany=",
  "facetCurrentFunction=",
  "facetGeoRegion=",
  "facetGroup=",
  "facetGuides=",
  "facetIndustry=",
  "facetNetwork=",
  "facetNonprofitInterest=",
  "facetPastCompany=",
  "facetProfessionalEvent=",
  "facetProfileLanguage=",
  "facetRegion=",
  "facetSchool=",
  "facetSeniority=",
  "facetServiceCategory=",
  "facetState=",
  "groups=",
  "keywords=",
  "topic=",
]

const relationshipsBasic = [
  'facetNetwork=%5B"O"%5D',
  'facetNetwork=%5B"O"%2C"S"%5D',
  'facetNetwork=%5B"S"%5D',
  'facetNetwork=%5B"F"%5D',
  'facetNetwork=%5B"S"%2C"O"%5D',
  "facetNetwork=%5B%22O%22%5D",
  "facetNetwork=%5B%22O%22%2C%22S%22%5D",
  "facetNetwork=%5B%22S%22%5D",
  "facetNetwork=%5B%22F%22%5D",
  "facetNetwork=%5B%22S%22%2C%22O%22%5D",
  "facetNetwork=%5B%22F%22%2C%22S%22%2C%22O%22%5D",

  'network=%5B"O"%5D',
  'network=%5B"O"%2C"S"%5D',
  'network=%5B"S"%5D',
  'network=%5B"F"%5D',
  'network=%5B"S"%2C"O"%5D',
  "network=%5B%22O%22%5D",
  "network=%5B%22O%22%2C%22S%22%5D",
  "network=%5B%22S%22%5D",
  "network=%5B%22F%22%5D",
  "network=%5B%22S%22%2C%22O%22%5D",
]

const relationshipsNav = [
  "relationship=F",
  "relationship=S",
  "relationship=O%2CS",
  "relationship=S%2CO",
  "relationship=O",
  "relationship=A%2CO",
  "relationship=S%2CA%2CO",
  "relationship=A%2CS%2CO",
  "relationship=O%2CA",
  "relationship=A%2CS",
  "relationship=S%2CA",
  "relationship=A%2CF",
  "relationship=F%2CA",
  "relationship=O%2CA%2CS",
  "relationship=A%2CO%2CS",
  "relationship=O%2CS%2CA",
  "relationship=S%2CO%2CA",
  // sales navigator new links
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AF%2Ctext%3A1st%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AF%2Ctext%3A1st%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AF%2Ctext%3A1st%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)))",
  "(type%3ARELATIONSHIP%2Cvalues%3AList((id%3AO%2Ctext%3A3rd%2520Degree%252B%2520Connections%2CselectionType%3AINCLUDED)%2C(id%3AA%2Ctext%3AGroup%2520members%2CselectionType%3AINCLUDED)%2C(id%3AS%2Ctext%3A2nd%2520Degree%2520Connections%2CselectionType%3AINCLUDED)))",
]

const forbiddenNavigatorParams = []

const simpleSequencesSteps = [
  { label: "View profile", action: "view" },
  { label: "Invite to connect", action: "connect" },
  { label: "Message", action: "message" },
  { label: "Email message", action: "email" },
  { label: "Email discovery & verification", action: "verifyEmail" },
  { label: "InMail message", action: "inMail" },
  { label: "Follow", action: "follow" },
]

const smartSequencesConditions = [
  "ifCustom",
  "ifConnected",
  "ifEmailOpened",
  "ifHasEmail",
  "ifHasVerifiedEmail",
  "ifOpenInMail",
  "ifEmailClicked",
]

export {
  basicSearchFilters,
  relationshipsBasic,
  relationshipsNav,
  forbiddenNavigatorParams,
  simpleSequencesSteps,
  smartSequencesConditions,
}
