import "./SvgIconRound.css"

import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon/SvgIcon"

const SvgIconRound = ({ className, style, icon, bgColor, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <span
      className="round-icon"
      style={{ backgroundColor: bgColor, ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SvgIcon icon={icon} className={`icon-size ${className}`} onClick={onClick} />
    </span>
  )
}
SvgIconRound.propTypes = {
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}
SvgIconRound.defaultProps = {
  className: "",
  style: {},
  bgColor: "#fff",
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
}

export default SvgIconRound
