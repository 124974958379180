import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import settings from "../../constants/settings"
import userService from "../../services/user-service"
import dataUtils from "../../utils/data-utils"
import { updateFormField, updateFormFields } from "../forms/formsActions"
import { history } from "../store"

function loginUserSuccess(userData) {
  return { type: ACTIONS.USER_LOGIN, userData }
}

function getUserData(noRedirect = false) {
  return async dispatch => {
    try {
      const userData = await userService.getUserData()
      const account = userData.data.result.user
      dispatch(loginUserSuccess(account))
    } catch (error) {
      if (!noRedirect) {
        history.replace("/login")
      }
    }
  }
}

function getSeatSuccess(seat) {
  return {
    type: ACTIONS.GET_SEAT,
    seat,
  }
}

function addAccount(accountName, startUTCTime, endUTCTime, timezone) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      // const planId = getState().forms.formData["pricing-tier"]
      const planId = 1
      const data = {
        planId,
        fullName: accountName,
        startUTCTime,
        endUTCTime,
        timeZone: timezone,
      }
      const account = await userService.addAccount(userID, data)
      dispatch(getSeatSuccess(account.data.result))
      return account.data.result
    } catch (error) {
      return false
    }
  }
}

function resendConfirmationEmail() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.resendConfirmationEmail(userID)
      toast.success("Confirmation email successfully sent!")
    } catch (error) {}
  }
}

function clearAllUsers() {
  return async dispatch => {
    try {
      dispatch({ type: ACTIONS.CLEAR_ALL_USERS })
    } catch (error) {}
  }
}

function getAllUsersSuccess(previousUsers, allNewUsers, offset) {
  return {
    type: ACTIONS.GET_ALL_USERS,
    allUsers: previousUsers.concat(allNewUsers),
    userCount: allNewUsers.length > 0 ? offset + settings.whitelabelAccountsLimit : 0,
  }
}

function getAllUsers(search = "", limit = settings.whitelabelAccountsLimit, offset = 0) {
  return async (dispatch, getState) => {
    try {
      const realSearch = search
        ? `?search=${search}&limit=${limit}&offset=${offset}`
        : `?limit=${limit}&offset=${offset}`
      const allUsers = await userService.getAllUsers(realSearch)

      dispatch(
        getAllUsersSuccess(
          getState().user.allUsers,
          allUsers.data.result.items,
          offset || settings.whitelabelAccountsLimit,
        ),
      )
    } catch (error) {}
  }
}

function setActiveUserIDSuccess(userID) {
  return { type: ACTIONS.SET_ACTIVE_USER_ID, userID }
}

function getActiveUserSuccess(activeUser) {
  return { type: ACTIONS.GET_ACTIVE_USER, activeUser }
}

function getActiveUser() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const activeUser = await userService.getActiveUser(userID)
      dispatch(getActiveUserSuccess(activeUser.data))
    } catch (error) {}
  }
}

function deleteUser(userId) {
  return async (dispatch, getState) => {
    try {
      const { activeUserID, allUsers, userCount } = getState().user
      const { id } = getState().user.profile
      await userService.deleteUser(activeUserID)
      if (id === activeUserID) {
        history.replace("/login")
      } else {
        history.replace("/admin")
      }
      if (userId) {
        dispatch({
          type: ACTIONS.GET_ALL_USERS,
          allUsers: allUsers.filter(user => user.id !== userId),
          userCount: userCount - 1,
        })
      }
      toast.success("Account successfully deleted")
    } catch (error) {}
  }
}

function changeUserName(userID) {
  return async (dispatch, getState) => {
    try {
      const newName = getState().forms.formData.userName
      const { allUsers, userCount } = getState().user
      await userService.changeUserName(userID, newName)
      dispatch({
        type: ACTIONS.GET_ALL_USERS,
        allUsers: allUsers.map(user => {
          if (user.id === userID) {
            user.fullName = newName
          }
          return user
        }),
        userCount,
      })
      toast.success("User name changed")
    } catch (error) {}
  }
}

function setActiveUserID(userID) {
  return async dispatch => {
    dispatch(setActiveUserIDSuccess(userID))
  }
}

function getUserProduct() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const productInfo = await userService.getUserProduct(userID)
      return productInfo.data
    } catch (error) {
      return false
    }
  }
}

function getAllSavedRepliesSuccess(savedReplies) {
  return {
    type: ACTIONS.GET_ALL_SAVED_REPLIES,
    savedReplies,
  }
}

function getAllSavedReplies() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const savedReplies = await userService.getAllSavedReplies(userID)

      dispatch(getAllSavedRepliesSuccess(savedReplies.data.result.items))
    } catch (error) {
      return false
    }
  }
}

function crateSavedReply(title, savedReply) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      await userService.crateSavedReply(userID, { title, savedReply })
      await dispatch(getAllSavedReplies())

      dispatch(updateFormField("savedReplyTitle", ""))
      dispatch(updateFormField("savedReplyText", ""))

      toast.success("Saved reply successfully created")
    } catch (error) {
      return false
    }
  }
}

function editSavedReply(title, savedReply, savedReplyId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      await userService.editSavedReply(userID, savedReplyId, { title, savedReply })
      await dispatch(getAllSavedReplies())

      dispatch(
        updateFormFields({
          savedReplyId: "",
          savedReplyTitle: "",
          savedReplyText: "",
        }),
      )

      toast.success("Saved reply successfully edited")
    } catch (error) {
      return false
    }
  }
}

function deleteSavedReply(savedReplyId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      await userService.deleteSavedReply(userID, savedReplyId)
      await dispatch(getAllSavedReplies())

      toast.success("Saved reply successfully deleted")
    } catch (error) {
      return false
    }
  }
}

function getAllFeatureRequestsSuccess(featureRequests, init) {
  return {
    type: ACTIONS.GET_ALL_FEATURE_REQUESTS,
    featureRequests,
    init,
  }
}

function getAllFeatureRequests(sortColumn, sortOrder, status, limit, offset, search, init = false) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      let query = `?sortOrder=${sortOrder}&sortColumn=${sortColumn}&limit=${limit}&offset=${offset}&search=${search}`
      if (status !== 0) {
        query += `&status=${status}`
      }
      const featureRequests = await userService.getAllFeatureRequests(userID, query)

      dispatch(getAllFeatureRequestsSuccess(featureRequests.data.result, init))
    } catch (error) {
      return false
    }
  }
}

function getFeatureRequestByIDSuccess(featureRequest) {
  return {
    type: ACTIONS.GET_FEATURE_REQUESTS_BY_ID,
    featureRequest,
  }
}

function getFeatureRequestByID(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const featureRequests = await userService.getFeatureRequestByID(userID, featureID)

      dispatch(getFeatureRequestByIDSuccess(featureRequests.data))
    } catch (error) {
      return false
    }
  }
}

function createFeatureRequest(title, details) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.createFeatureRequest(userID, { title, details })
      dispatch(updateFormField("feature_title", ""))
      dispatch(updateFormField("feature_details", ""))
      toast.success("Feature request successfully created")
    } catch (error) {
      return false
    }
  }
}

function addFeatureRequestComment(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const comment = getState().forms.formData["feature-request-comment"]
      await userService.addFeatureRequestComment(userID, featureID, { comment })
      dispatch(updateFormField("feature-request-comment", ""))
      dispatch(getFeatureRequestByID(featureID))
    } catch (error) {
      return false
    }
  }
}

function voteForFeatureRequest(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.voteForFeatureRequest(userID, featureID)
    } catch (error) {
      return false
    }
  }
}

function createSignatureSuccess(signature) {
  return { type: ACTIONS.CREATE_GLOBAL_SIGNATURE, signature }
}

function createSignature() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const signature = getState().forms.formData["create-signature"]
      const response = await userService.createSignature(userID, accountID, {
        signature: dataUtils.createHTMLEmailTemplate(signature, true),
      })
      dispatch(createSignatureSuccess(response.data))
      toast.success("Signature created")
    } catch (error) {
      toast.error("Create signature failed")
    }
  }
}

function getSignaturesSuccess(signatures) {
  return { type: ACTIONS.GET_GLOBAL_SIGNATURES, signatures }
}

function getSignatures() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const response = await userService.getSignatures(userID, accountID)

      const parsedData = response.data.map(signature => {
        const doc = new DOMParser().parseFromString(signature.signature, "text/html")
        const parsedSignature = dataUtils.getEditCampaignHTML(
          doc.getElementsByTagName("body").item(0),
        )
        return {
          ...signature,
          signature: parsedSignature,
        }
      })

      dispatch(getSignaturesSuccess(parsedData))
    } catch (error) {}
  }
}

function editSignatureSuccess(signature) {
  return { type: ACTIONS.UPDATE_GLOBAL_SIGNATURE, signature }
}

function editSignature(signatureId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const signature = getState().forms.formData["create-signature"]
      await userService.editSignature(userID, accountID, signatureId, {
        signature: dataUtils.createHTMLEmailTemplate(signature, true),
      })
      dispatch(editSignatureSuccess({ id: signatureId, signature }))
    } catch (error) {}
  }
}

function deleteSignatureSuccess(signatureId) {
  return { type: ACTIONS.DELETE_GLOBAL_SIGNATURE, signatureId }
}

function deleteSignature(signatureId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await userService.deleteSignature(userID, accountID, signatureId)
      dispatch(deleteSignatureSuccess(signatureId))
      toast.success("Signature deleted")
    } catch (error) {}
  }
}

function getLinkedinUserSuccess(linkedinUserData) {
  return {
    type: ACTIONS.GET_LINKEDIN_USER,
    linkedinUserData,
  }
}

function clearLinkedinUserSuccess() {
  return {
    type: ACTIONS.CLEAR_LINKEDIN_USER,
  }
}

function clearLinkedinUser() {
  return async dispatch => {
    try {
      dispatch(clearLinkedinUserSuccess())
    } catch (error) {}
  }
}

function getLinkedinUser(linkedinUserId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const linkedinUserData = await userService.getLinkedinUser(userID, accountID, linkedinUserId)
      dispatch(getLinkedinUserSuccess(linkedinUserData.data.result))
    } catch (error) {}
  }
}

function createGlobalBcc() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const { globalBcc } = getState().forms.formData
      const data = { bccRecipients: globalBcc }

      await userService.createGlobalBcc(userID, accountID, data)
      await dispatch(updateFormField("globalBcc", ""))
    } catch (error) {}
  }
}

function getOpenApiKeySuccess(openApiKey) {
  return {
    type: ACTIONS.GET_OPEN_API_KEY,
    openApiKey,
  }
}

function getOpenApiKey() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const apiKey = await userService.getOpenApiKey(userID)
      dispatch(getOpenApiKeySuccess(apiKey.data))
    } catch (error) {}
  }
}

export {
  getUserData,
  addAccount,
  clearAllUsers,
  getAllUsers,
  setActiveUserID,
  resendConfirmationEmail,
  getUserProduct,
  crateSavedReply,
  editSavedReply,
  deleteSavedReply,
  getAllSavedReplies,
  getAllFeatureRequests,
  getFeatureRequestByID,
  createFeatureRequest,
  addFeatureRequestComment,
  voteForFeatureRequest,
  deleteUser,
  changeUserName,
  getActiveUser,
  createSignature,
  getSignatures,
  editSignature,
  deleteSignature,
  getLinkedinUser,
  clearLinkedinUser,
  createGlobalBcc,
  getOpenApiKey,
}
