import { toast } from "react-toastify"
import urljoin from "url-join"

import ACTIONS from "../../constants/ACTIONS"
import paymentService from "../../services/payment-service"
import { getUserAccounts } from "../account/accountActions"
import { showInfoModal } from "../app/appActions"
import { store } from "../store"
import { getUserProduct } from "../user/userActions"

function getPaymentPlansSuccess(paymentPlans) {
  return { type: ACTIONS.GET_PAYMENT_PLANS, paymentPlans }
}

function getPaymentPlans() {
  return async dispatch => {
    try {
      const paymentPlans = await paymentService.getPaymentPlans()
      dispatch(getPaymentPlansSuccess(paymentPlans.data.result.items))
    } catch (error) {}
  }
}

function getAvailableSeatsForWhiteLabelSuccess(availableSeats) {
  return { type: ACTIONS.GET_AVAILABLE_SEATS_FOR_WHITELABEL, availableSeats }
}

function getAvailableSeatsSuccess(availableSeats) {
  return { type: ACTIONS.GET_AVAILABLE_SEATS, availableSeats }
}

function getAvailableSeats(getForWhiteLabel) {
  return async (dispatch, getState) => {
    try {
      const { activeUserID } = getState().user
      const userID = getState().user.profile.id
      const availableSeats = await paymentService.getAvailableSeats(activeUserID)
      dispatch(getAvailableSeatsSuccess(availableSeats.data.result.items))
      if (activeUserID === userID) {
        dispatch(getAvailableSeatsForWhiteLabelSuccess(availableSeats.data.result.items))
      }
      if (getForWhiteLabel) {
        const whiteLabelSeats = await paymentService.getAvailableSeats(userID)
        dispatch(getAvailableSeatsForWhiteLabelSuccess(whiteLabelSeats.data.result.items))
      }
    } catch (error) {}
  }
}

function getAccountSpecificPricesSuccess(accountSpecificPrices) {
  return { type: ACTIONS.GET_ACCOUNT_SPECIFIC_PRICES, accountSpecificPrices }
}
// Fetching specific prices when we upgrading account to new Pricing Tier
function getAccountSpecificPrices(accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountSpecificPrices = await paymentService.getAccountSpecificPrices(userID, accountID)
      dispatch(getAccountSpecificPricesSuccess(accountSpecificPrices.data.result))
    } catch (error) {}
  }
}

function getPaymentCustomerSuccess(customer) {
  return { type: ACTIONS.GET_PAYMENT_CUSTOMER, customer }
}

function getPaymentCustomerFail() {
  return { type: ACTIONS.GET_PAYMENT_CUSTOMER, customer: false }
}

function getPaymentCustomer() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const customerData = await paymentService.getPaymentCustomer(userID)
      dispatch(getPaymentCustomerSuccess(customerData.data.result))
      return customerData.data.result || {}
    } catch (error) {
      dispatch(getPaymentCustomerFail())
      return false
    }
  }
}

function createSubscription() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const subscription = await paymentService.createSubscription(userID)
      return subscription.data.result.id
    } catch (error) {
      return false
    }
  }
}

function getSubscriptionInfo(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const subscriptionInfo = await paymentService.getSubscriptionInfo(userID, subscriptionID)
      return subscriptionInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function getPricePreviewSuccess(pricePreview) {
  return { type: ACTIONS.GET_PRICE_PREVIEW, pricePreview }
}

function getPricePreview() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { pricingPlans } = getState().payment
      const { formData } = getState().forms
      const plans = []
      pricingPlans.forEach(plan => {
        if (formData[`${plan.name}-seats`] > 0) {
          plans.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`],
          })
        }
      })
      const pricePreview = await paymentService.getPricePreview(userID, { plans })
      dispatch(getPricePreviewSuccess(pricePreview.data.result))
    } catch (error) {}
  }
}

function getLastUsedCardSuccess(savedCard) {
  return { type: ACTIONS.GET_LAST_USED_CARD, savedCard }
}

function getLastUsedCard() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const savedCard = await paymentService.getLastUsedCard(userID)
      dispatch(getLastUsedCardSuccess(savedCard.data.result))
      return true
    } catch (error) {
      dispatch(getLastUsedCardSuccess({}))
      return false
    }
  }
}

function changeCard(stripeToken) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await paymentService.changeCard(userID, {
        stripeToken,
      })
      return true
    } catch (error) {
      return false
    }
  }
}

function createCustomer(stripeToken, userEmail, referral, companyName, taxId, address) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const response = await paymentService.createCustomer(userID, {
        stripeToken,
        userEmail,
        referral,
        companyName,
        taxId,
        address,
      })
      if (response.data.result.clientSecret) {
        return response.data.result.clientSecret
      }
      if (response.data.result.failReason) {
        switch (response.data.result.failReason) {
          case "NEW_PAYMENT_METHOD":
            dispatch(
              showInfoModal(
                "warning",
                "Warning",
                "Transaction declined by the bank. Please attach a valid credit card.",
              ),
            )
            break
        }
        return false
      }
      return true
    } catch (error) {}
  }
}

function buySeats() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { activeUserID } = getState().user
      const { pricingPlans } = getState().payment
      const { availableSeats } = getState().user.profile
      const { formData } = getState().forms
      const plans = []
      const newAvailableSeats = []
      pricingPlans.forEach(plan => {
        const planAvailableSeats = availableSeats.find(seat => seat.planId === plan.id)
        const quantity = planAvailableSeats ? planAvailableSeats.quantity : 0
        if (formData[`${plan.name}-seats`] > 0) {
          plans.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`],
          })
          newAvailableSeats.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`] + +quantity,
          })
        } else {
          newAvailableSeats.push({
            planId: +plan.id,
            quantity: +quantity,
          })
        }
      })
      const response = await paymentService.buySeats(userID, { plans })
      if (response.data.result.clientSecret) {
        return {
          clientSecret: response.data.result.clientSecret,
          getNewSeats: () => {
            dispatch(getAvailableSeatsForWhiteLabelSuccess(newAvailableSeats))
            if (userID === activeUserID) {
              dispatch(getAvailableSeatsSuccess(newAvailableSeats))
            }
          },
        }
      }
      if (response.data.result.failReason) {
        switch (response.data.result.failReason) {
          case "NEW_PAYMENT_METHOD":
            dispatch(
              showInfoModal(
                "warning",
                "Warning",
                "Transaction declined by the bank. Please attach a valid credit card.",
              ),
            )
            break
        }
        return false
      }
      await dispatch(getAvailableSeatsForWhiteLabelSuccess(newAvailableSeats))
      if (userID === activeUserID) {
        dispatch(getAvailableSeatsSuccess(newAvailableSeats))
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function checkPromoCode(promoCode) {
  return async () => {
    try {
      const promoCodeInfo = await paymentService.checkPromoCode(promoCode)
      return promoCodeInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function submitPromoCode(promoCode, subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const promoCodeInfo = await paymentService.submitPromoCode(userID, subscriptionID, promoCode)
      return promoCodeInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function cancelSubscription(reasons) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const { formData } = getState().forms

      const reasonDataArray = reasons.map(reason => {
        if (reason.id === 6) {
          return formData.cancelAccountOther
        }

        return reason.text
      })

      const reason = reasonDataArray.join("\n")
      await paymentService.cancelSubscription(userID, accountID, { reason })
      await paymentService.cancelSubscriptionReason(userID, { reason })

      dispatch(getUserAccounts())
      toast.success("Subscription successfully canceled")
    } catch (error) {}
  }
}

function changeAccountSubscription() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const planId = getState().forms.formData["pricing-tier"]
      await paymentService.changeAccountSubscription(userID, accountID, { planId })
      await dispatch(getAccountSpecificPrices(accountID))
      await dispatch(getUserAccounts())
      toast.success("Subscription successfuly changed")
    } catch (error) {
      toast.error("Something went wrong. Please try again later!")
    }
  }
}

function paySubscription(stripeToken, subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const response = await paymentService.paySubscription(userID, subscriptionID, { stripeToken })
      if (response.data.result.clientSecret) {
        return response.data.result.clientSecret
      }
      if (response.data.result.failReason) {
        switch (response.data.result.failReason) {
          case "NEW_PAYMENT_METHOD":
            dispatch(
              showInfoModal(
                "warning",
                "Warning",
                "Transaction declined by the bank. Please attach a valid credit card.",
              ),
            )
            break
        }
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function getLastInvoiceAmount(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const lastInvoice = await paymentService.getLastInvoiceAmount(userID, subscriptionID)
      return lastInvoice.data.result
    } catch (error) {
      return false
    }
  }
}

function endTrial(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await paymentService.endTrial(userID, subscriptionID)
      return true
    } catch (error) {
      return false
    }
  }
}

function getAllSubscriptionsSuccess(allSubscriptions) {
  return { type: ACTIONS.GET_ALL_SUBSCRIPTIONS, allSubscriptions }
}

function getAllSubscriptions() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const allSubscriptions = await paymentService.getAllSubscriptions(userID)
      dispatch(getAllSubscriptionsSuccess(allSubscriptions.data.result.items))
    } catch (error) {}
  }
}

function updateCompanyInvoiceInfo(customerID, data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await paymentService.updateCompanyInvoiceInfo(userID, customerID, data)
      toast.success("Company info successfully updated")
      return true
    } catch (error) {
      return false
    }
  }
}

function transferSeats(destinationUserId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { pricingPlans } = getState().payment
      const { formData } = getState().forms
      const seats = []
      pricingPlans.forEach(plan => {
        if (formData[`${plan.name}-seats`] > 0) {
          seats.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`],
          })
        }
      })
      await paymentService.transferSeats(userID, seats, destinationUserId)
      dispatch(getAvailableSeats(true))
      toast.success("Seats successfully transfered")
      return true
    } catch (error) {
      toast.error("Transfer failed. Please try again later")
      return false
    }
  }
}

function generateRegistrationLink(creditCount) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const generatedLink = await paymentService.generateRegistrationLink(userID, {
        redeems: creditCount,
      })

      const productInfo = await dispatch(getUserProduct())

      const registerLink = urljoin(
        productInfo.result.domain,
        `/register?invitationID=${generatedLink.data.result.invitationId}`,
      )

      return registerLink
    } catch (error) {
      return false
    }
  }
}

async function getAllInvoices(query = "") {
  try {
    const userID = store.getState().user.profile.id
    return await paymentService.getAllInvoices(userID, query)
  } catch (error) {}
}

export {
  getPaymentPlans,
  getAvailableSeats,
  getAccountSpecificPrices,
  getPaymentCustomer,
  getSubscriptionInfo,
  getLastUsedCard,
  getPricePreview,
  changeCard,
  createCustomer,
  buySeats,
  checkPromoCode,
  submitPromoCode,
  cancelSubscription,
  changeAccountSubscription,
  paySubscription,
  getLastInvoiceAmount,
  endTrial,
  createSubscription,
  getAllSubscriptions,
  transferSeats,
  updateCompanyInvoiceInfo,
  generateRegistrationLink,
  getAllInvoices,
}
