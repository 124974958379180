export const lettersArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

export const ABTESTINGTABSCOUNT = 5

export const ABTESTINGELEMENTS = ["connect", "message", "email", "inMail"]

export default { lettersArray, ABTESTINGTABSCOUNT, ABTESTINGELEMENTS }
