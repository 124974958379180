import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    proxyCountries: [],
    infoModalData: { show: false, type: "info" },
    allSupportedTags: [],
    accountStatuses: [],
  }
}

export default function appReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.GET_PROXY_COUNTRIES: {
      return {
        ...state,
        proxyCountries: action.proxyCountries,
      }
    }

    case ACTIONS.SET_INFO_MODAL_DATA:
    case ACTIONS.SHOW_PREVIOUS_INFO_MODAL:
    case ACTIONS.SHOW_INFO_MODAL:
    case ACTIONS.HIDE_INFO_MODAL: {
      return {
        ...state,
        infoModalData: action.infoModalData,
      }
    }

    case ACTIONS.GET_ALL_SUPPORTED_TAGS: {
      return {
        ...state,
        allSupportedTags: action.allSupportedTags,
      }
    }

    case ACTIONS.GET_ACCOUNT_STATUSES: {
      return { ...state, accountStatuses: action.accountStatuses }
    }

    default:
      return { ...state }
  }
}
