import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    pricingPlans: [],
    availableSeats: [],
    accountSpecificPrices: [],
    allSubscriptions: [],
    pricePreview: {},
    savedCard: {},
    paymentCustomer: undefined,
  }
}

export default function paymentReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.GET_PAYMENT_PLANS: {
      return {
        ...state,
        pricingPlans: action.paymentPlans,
      }
    }
    case ACTIONS.GET_AVAILABLE_SEATS: {
      return {
        ...state,
        availableSeats: action.availableSeats,
      }
    }
    case ACTIONS.GET_ALL_SUBSCRIPTIONS: {
      return {
        ...state,
        allSubscriptions: action.allSubscriptions,
      }
    }
    case ACTIONS.GET_LAST_USED_CARD: {
      return {
        ...state,
        savedCard: action.savedCard,
      }
    }

    case ACTIONS.GET_PAYMENT_CUSTOMER: {
      return {
        ...state,
        paymentCustomer: action.customer,
      }
    }

    case ACTIONS.GET_PRICE_PREVIEW: {
      return {
        ...state,
        pricePreview: action.pricePreview,
      }
    }

    case ACTIONS.GET_ACCOUNT_SPECIFIC_PRICES: {
      return {
        ...state,
        accountSpecificPrices: action.accountSpecificPrices,
      }
    }

    default:
      return { ...state }
  }
}
