import { store } from "../redux/store"
import config from "./config"

const isNotSkylead = () => {
  const { whitelabelId } = store.getState().user.profile

  if (typeof whitelabelId !== "undefined") {
    return whitelabelId !== 1
  }

  return true
}

const isWhiteLabel = () => {
  const { whitelabelId, userTypeId } = store.getState().user.profile

  if ([2, 3].includes(+userTypeId)) {
    return false
  }

  return whitelabelId !== 1
}

const hideBilling = () => {
  try {
    const { id } = store.getState().user.profile

    return JSON.parse(config.REACT_APP_HIDE_BILLING_ACCOUNT_IDS).includes(id)
  } catch (error) {}
}

export { isNotSkylead, isWhiteLabel, hideBilling }
