import "./InfoModal.css"

import classNames from "classnames"
import React from "react"
import { Form, Modal } from "react-bootstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import * as appActions from "../../../redux/actions"
import Button from "../../atoms/Button"
import SvgIcon from "../../atoms/SvgIcon/SvgIcon"

const InfoModal = props => {
  const handleCloseInfoModal = () => {
    const { actions, infoModalDataProps, infoModalDataRedux } = props
    const infoModalData = infoModalDataProps || infoModalDataRedux
    if (!infoModalData.noAutoHide) {
      actions.hideInfoModal()
    }
    if (infoModalData.onClose) {
      infoModalData.onClose()
    }
  }

  const renderIconByType = () => {
    const { infoModalDataProps, infoModalDataRedux } = props
    const { type } = infoModalDataProps || infoModalDataRedux

    const modalTypeClassName = `modal-${type}`
    switch (type) {
      case "success":
        return <SvgIcon icon="checked" className={`info-modal-icon ${modalTypeClassName}`} />
      case "warning":
        return <SvgIcon icon="warning" className={`info-modal-icon ${modalTypeClassName}`} />
      case "info":
        return <SvgIcon icon="info" className={`info-modal-icon ${modalTypeClassName}`} />
      case "error":
        return <SvgIcon icon="error" className={`info-modal-icon ${modalTypeClassName}`} />

      default:
        return null
    }
  }

  const onSubmit = async event => {
    const { infoModalDataProps, infoModalDataRedux, actions } = props
    const infoModalData = infoModalDataProps || infoModalDataRedux
    const { onSuccess, noHideOnSubmit = false, noLoading = false } = infoModalData
    if (!noLoading) {
      actions.updateFormField("loadingButton", true)
      // setState({ loading: true })
    }
    const isSuccess = await onSuccess(event)
    if (!noLoading) {
      actions.updateFormField("loadingButton", false)
      // setState({ loading: false })
    }
    if (!noHideOnSubmit && isSuccess !== false) {
      handleCloseInfoModal()
    }
  }

  const getContentWrapper = ({ children }) => {
    const { infoModalDataProps, infoModalDataRedux } = props
    const infoModalData = infoModalDataProps || infoModalDataRedux
    const { formWrap = false } = infoModalData
    if (formWrap) {
      return (
        <Form
          onSubmit={event => {
            event.preventDefault()
          }}
        >
          {children}
        </Form>
      )
    }
    return <>{children}</>
  }

  const { infoModalDataProps, infoModalDataRedux } = props
  const infoModalData = infoModalDataProps || infoModalDataRedux

  const {
    show: showInfoModal,
    header,
    className,
    body,
    type,
    onSuccess,
    actionButtonText = "Submit",
    grayButtonAction = handleCloseInfoModal,
    grayButtonText = "Cancel",
    actionButtonVariant = "primary",
    noCloseButton,
    maxZIndex, // this is when you have multiple modals appearing one over the other
  } = infoModalData

  const modalClassName = classNames("info-modal-wrapper", { [className]: className })
  const backdropClassName = classNames("info-modal-backdrop", { maxZIndex })

  const modalTypeClassName = `modal-${type}`
  const ModalIcon = renderIconByType
  const ModalWrapper = getContentWrapper

  return (
    <Modal
      className={modalClassName}
      show={showInfoModal}
      onHide={handleCloseInfoModal}
      backdropClassName={backdropClassName}
      style={{ whiteSpace: "pre-line" }}
    >
      <ModalWrapper>
        {header && (
          <Modal.Header closeButton className={modalTypeClassName}>
            <Modal.Title className="d-block m-auto">{header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <ModalIcon />
          <span className="w-100">{body}</span>
        </Modal.Body>
        <Modal.Footer>
          {onSuccess ? (
            <div className="d-flex justify-content-center w-100">
              {!noCloseButton && (
                <Button
                  type="button"
                  center
                  variant="secondary"
                  active={false}
                  onClick={grayButtonAction}
                >
                  {grayButtonText}
                </Button>
              )}
              <Button
                center
                variant={actionButtonVariant}
                onClick={onSubmit}
                hasLoading
                type="submit"
              >
                {actionButtonText}
              </Button>
            </div>
          ) : (
            !noCloseButton && (
              <Button
                type="button"
                center
                variant="secondary"
                active={false}
                onClick={grayButtonAction}
              >
                {grayButtonText}
              </Button>
            )
          )}
        </Modal.Footer>
      </ModalWrapper>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    infoModalDataRedux: state.app.infoModalData,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(InfoModal)))
