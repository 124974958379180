import ACTIONS from "../../constants/ACTIONS"

function initialState() {
  return {
    activeAccountID: null,
    settingsData: {},
    pendingConnections: false,
    allWebhooks: { rows: [], count: 0 },
    accountNotifications: { count: 0, notifications: [], unseen: 0 },
    accountData: {},
    proxyInfo: {},
    authenticatedEmails: {},
    globalStatus: null,
    pinTimestamp: null,
  }
}

export default function accountReducer(state = initialState(), action) {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_ACCOUNT_ID: {
      return {
        ...state,
        activeAccountID: action.accountID,
      }
    }

    case ACTIONS.GET_SETTING_DATA: {
      return {
        ...state,
        settingsData: action.settingsData,
      }
    }

    case ACTIONS.GET_PENDING_CONNECTIONS: {
      return {
        ...state,
        pendingConnections: action.pendingConnections,
      }
    }

    case ACTIONS.GET_ALL_WEBHOOKS: {
      return {
        ...state,
        allWebhooks: action.allWebhooks,
      }
    }

    case ACTIONS.GET_ACCOUNT_NOTIFICATIONS: {
      return {
        ...state,
        accountNotifications: action.accountNotifications,
      }
    }

    case ACTIONS.SEEN_ACCOUNT_NOTIFICATIONS: {
      const allNewNotifications = {
        count: state.accountNotifications.count,
        notifications: state.accountNotifications.notifications.map(notif => {
          if (action.notificationIds.includes(notif.id)) {
            return { ...notif, seen: true }
          }
          return { ...notif }
        }),
        unseen: state.accountNotifications.unseen - action.notificationIds.length,
      }
      return {
        ...state,
        accountNotifications: allNewNotifications,
      }
    }

    case ACTIONS.GET_ACTIVE_ACCOUNT: {
      return {
        ...state,
        accountData: action.accountData,
      }
    }

    case ACTIONS.GET_ACCOUNT_PROXY_INFO: {
      return {
        ...state,
        proxyInfo: action.proxyInfo,
      }
    }

    case ACTIONS.GET_AUTHENTICATED_EMAILS: {
      return {
        ...state,
        authenticatedEmails: action.emails,
      }
    }

    case ACTIONS.SET_ACCOUNT_HEALTH: {
      return {
        ...state,
        settingsData: { ...state.settingsData, maxPendingAmount: action.maxPendingAmount },
      }
    }

    case ACTIONS.GET_GLOBAL_STATUS: {
      return {
        ...state,
        globalStatus: action.globalStatus.globalStatusId,
        pinTimestamp: action.globalStatus.pinTimestamp,
      }
    }

    default:
      return { ...state }
  }
}
