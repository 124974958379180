import queryString from "query-string"
import React, { Component, lazy } from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { bindActionCreators } from "redux"
import io from "socket.io-client"

import ACTIONS from "../constants/ACTIONS"
import * as appActions from "../redux/actions"
import { store } from "../redux/store"
import apiUtils from "../utils/api-utils"
import config from "../utils/config"
import AuthUserRoute from "./atoms/AuthUserRoute/AuthUserRoute"
import GuestUserRoute from "./atoms/GuestUserRoute/GuestUserRoute"
import CardWrapper from "./organisms/CardWrapper"

// const UpgradePricingTierPage = lazy(() =>
//   import("./pages/UpgradePricingTierPage/UpgradePricingTierPage"),
// )

const CampaignEditPage = lazy(() => import("./pages/CampaignEditPage/CampaignEditPage"))
const Campaigns = lazy(() => import("./pages/Campaigns/Campaigns"))
const ChatPage = lazy(() => import("./pages/ChatPage"))
const FeatureRequestsPage = lazy(() => import("./pages/FeatureRequestsPage"))
const FeatureRequestPage = lazy(() => import("./pages/FeatureRequestPage"))
const HelpCustomVariables = lazy(() => import("./pages/HelpCustomVariables/HelpCustomVariables"))
const HomePage = lazy(() => import("./pages/HomePage/HomePage"))
const LoadingPage = lazy(() => import("./pages/LoadingPage"))
const LockPage = lazy(() => import("./pages/LockPage/LockPage"))
const Login = lazy(() => import("./pages/LoginPage"))
const TestPage = lazy(() => import("./pages/TestPage"))
const CreateCampaignInfoPage = lazy(() => import("./pages/CreateCampaignInfoPage"))
const CreateCampaignSettingsPage = lazy(() => import("./pages/CreateCampaignSettingsPage"))
const CreateCampaignComplexSteps = lazy(() => import("./pages/CreateCampaignComplexSteps"))
const MasterLoginPage = lazy(() => import("./pages/MasterLoginPage"))
const Register = lazy(() => import("./pages/RegisterPage"))
const SelectedCampaigns = lazy(() => import("./pages/SelectedCampaigns/SelectedCampaigns"))
const SequencesNew = lazy(() => import("./pages/SequencesNew/SequencesNew"))
const SettingsPage = lazy(() => import("./pages/SettingsPage/SettingsPage"))
const SomethingWentWrong = lazy(() =>
  import("./pages/SomethingWentWrongPage/SomethingWentWrongPage"),
)
const StatisticsPage = lazy(() => import("./pages/StatisticsPage"))
const WebhooksPage = lazy(() => import("./pages/WebhooksPage/WebhooksPage"))
const WhitelabelPage = lazy(() => import("./pages/WhitelabelPage"))

apiUtils.initInterceptors()
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)
    const { fpr: referral } = params
    if (referral) localStorage.setItem("referral", referral)
    if (config.REACT_APP_USER_API_KEY === "undefined") {
      // Include the Crisp code here, without the <script></script> tags
      window.$crisp = []
      window.CRISP_WEBSITE_ID =
        config.REACT_APP_CRISP_WEBSITE_ID !== "undefined"
          ? config.REACT_APP_CRISP_WEBSITE_ID
          : undefined
      ;(() => {
        const d = document
        const s = d.createElement("script")

        s.src = "https://client.crisp.chat/l.js"
        s.async = 1
        d.getElementsByTagName("head")[0].appendChild(s)
      })()
    }

    const maxOnPage = localStorage.getItem("maxOnPage")
    if ([200, 500].includes(+maxOnPage)) {
      localStorage.removeItem("maxOnPage")
    }

    const { actions } = this.props

    const socket = io(config.REACT_APP_SOCKET_URL)
    socket.on(
      "AccountAuthChange",
      ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl },
        data: { message, accountGlobalStatusId },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              this.props.history.push(onClickRedirectUrl)
            }
          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
        actions.getUserAccounts()
        if (+accountGlobalStatusId === 3) {
          this.props.history.replace(`/user/${this.props.activeUserID}/`)
        }
      },
    )
    socket.on(
      "Info",
      ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl, duration },
        data: { message },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              this.props.history.push(onClickRedirectUrl)
            }
          if (duration) options.autoClose = duration

          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
      },
    )

    socket.on("SendMessageSuccess", ({ data: { messageId } }) => {
      const newThreadMessage = {
        ...this.props.threadMessages,
        messages: this.props.threadMessages.messages.map(message => {
          if (message.id === messageId) {
            return {
              ...message,
              messageStatus: "SENT/RECEIVED",
            }
          }
          return message
        }),
      }
      store.dispatch({ type: ACTIONS.GET_THREAD_MESSAGES, threadMessages: newThreadMessage })
    })

    socket.on("SendMessageError", ({ data: { messageId } }) => {
      const newThreadMessage = {
        ...this.props.threadMessages,
        messages: this.props.threadMessages.messages.map(message => {
          if (message.id === messageId) {
            return {
              ...message,
              messageStatus: "ERRORED",
            }
          }
          return message
        }),
      }
      store.dispatch({ type: ACTIONS.GET_THREAD_MESSAGES, threadMessages: newThreadMessage })
    })

    socket.on("NewNotification", ({ data }) => {
      const { accountNotifications } = this.props
      const { activeAccountID } = store.getState().account
      if (+data.linkedinAccountId === +activeAccountID) {
        const allNotifications = {
          count: accountNotifications.count + 1,
          notifications: [data].concat(accountNotifications.notifications),
          unseen: accountNotifications.unseen + 1,
        }

        store.dispatch({
          type: ACTIONS.GET_ACCOUNT_NOTIFICATIONS,
          accountNotifications: allNotifications,
        })
        toast.success("You have received a new notification")
      }
    })

    if (window.hj) {
      window.hj("identify", this.props.activeUserID, {})
    }

    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    if (loading) return <LoadingPage />
    return (
      <CardWrapper>
        <Switch>
          <GuestUserRoute exact path="/test" component={TestPage} />
          <GuestUserRoute exact path="/login" component={Login} />
          <GuestUserRoute exact path="/register" component={Register} />
          <AuthUserRoute exact path="/helpcenter/customvariables" component={HelpCustomVariables} />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/chat/:campaignID/:leadID"
            component={ChatPage}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/chat/:campaignID"
            component={ChatPage}
          />
          <AuthUserRoute exact path="/user/:userID/account/:accountID/chat" component={ChatPage} />
          <AuthUserRoute exact path="/user/:userID/" component={MasterLoginPage} />
          <AuthUserRoute exact path="/admin" component={WhitelabelPage} />
          <AuthUserRoute exact path="/" />
          <AuthUserRoute exact path="/user/:userID/home" component={HomePage} />
          <AuthUserRoute exact path="/user/:userID/account/:accountID" component={Campaigns} />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/selected-campaigns/:campaignID"
            component={SelectedCampaigns}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/selected-campaigns"
            component={SelectedCampaigns}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/campaign/:campaignID/edit"
            component={CampaignEditPage}
          />

          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/create-campaign/info"
            component={CreateCampaignInfoPage}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/create-campaign/settings"
            component={CreateCampaignSettingsPage}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/create-campaign/smart-steps"
            component={CreateCampaignComplexSteps}
          />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/create-campaign/simple-steps"
            component={SequencesNew}
          />

          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/settings"
            component={SettingsPage}
          />
          <AuthUserRoute exact path="/user/:userID/account/:accountID/terms" component={LockPage} />
          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/webhooks"
            component={WebhooksPage}
          />

          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/statistics"
            component={StatisticsPage}
          />

          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/feature-requests"
            component={FeatureRequestsPage}
          />

          <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/feature-requests/:featureID"
            component={FeatureRequestPage}
          />

          {/* We will maybe use this in future */}
          {/* <AuthUserRoute
            exact
            path="/user/:userID/account/:accountID/upgrade-pricing-tier"
            component={UpgradePricingTierPage}
          /> */}

          <Route component={SomethingWentWrong} />
        </Switch>
      </CardWrapper>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.profile,
  threadMessages: state.chat.threadMessages,
  activeUserID: state.user.activeUserID,
  accountNotifications: state.account.accountNotifications,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
