/* eslint-disable import/no-cycle */
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"
import paymentService from "../../services/payment-service"
import { hideInfoModal } from "../app/appActions"
import { clearForm, updateFormField } from "../forms/formsActions"
import { store } from "../store"
import { getAuthenticatedEmails } from "./settingsActions"

function getUserAccountsSuccess(userAccounts) {
  return { type: ACTIONS.GET_USER_ACCOUNTS, userAccounts }
}

function getUserAccounts(searchAccounts) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const realSearch = searchAccounts ? `?search=${searchAccounts}` : ""
      const userAccounts = await accountService.getUserAccounts(userID, realSearch)
      dispatch(getUserAccountsSuccess(userAccounts.data.result.items))
    } catch (error) {}
  }
}

function getAccountDataSuccess(accountData) {
  return { type: ACTIONS.GET_ACTIVE_ACCOUNT, accountData }
}

function getAccountData() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const accountData = await accountService.getAccountData(userID, accountID)
      dispatch(getAccountDataSuccess(accountData.data))

      return accountData.data
    } catch (error) {}
  }
}

function changeAccountName(name) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.changeAccountName(userID, accountID, name)
      toast.success("Account name successfully changed")
      dispatch(getUserAccounts())
    } catch (error) {}
  }
}

function updateLinkedinCredentials(email, password) {
  return async (dispatch, getState) => {
    try {
      const accountID = getState().account.activeAccountID
      const userID = getState().user.activeUserID
      await accountService.updateLinkedinCredentials(userID, accountID, email, password)

      dispatch(getUserAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function loginAccount() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.loginAccount(userID, accountID)
    } catch (error) {}
  }
}

function verifyPin(pin) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.verifyLinkedinPin(userID, accountID, pin)
      dispatch(getUserAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function verifyTwoFactorAuth(twoFactorAuth) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.verifyLinkedinTwoFactorAuth(userID, accountID, twoFactorAuth)
      dispatch(getUserAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function resendLinkedinPin(accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      await accountService.resendLinkedinPin(userID, accountID)
      toast.success("Pin verification email successfully resent")
      return true
    } catch (error) {
      toast.error("Pin verification email resend error. Please contact support")
      return false
    }
  }
}

function deleteAccount(reasons) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const { formData } = getState().forms

      const reasonDataArray = reasons.map(reason => {
        if (reason.id === 6) {
          return formData.deleteAccountOther
        }

        return reason.text
      })

      const reason = reasonDataArray.join("\n")
      await accountService.deleteAccount(userID, accountID, { reason })
      await paymentService.cancelSubscriptionReason(userID, { reason })
      await dispatch(clearForm())
      await dispatch(getUserAccounts())
      toast.success("Account successfully deleted")
    } catch (error) {}
  }
}

function setActiveAccountIDSuccess(accountID) {
  return { type: ACTIONS.SET_ACTIVE_ACCOUNT_ID, accountID }
}

function setActiveAccountID(accountID) {
  return async dispatch => {
    dispatch(setActiveAccountIDSuccess(accountID))
  }
}

function changeLinkedinSubscription(linkedinSubscriptionId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      await accountService.changeLinkedinSubscription(userID, accountID, {
        linkedinSubscriptionId: Number(linkedinSubscriptionId),
      })
      dispatch(getAccountData())
      dispatch(getUserAccounts())
      toast.success("Linkedin subscription successfully changed")
    } catch (error) {}
  }
}

function reactivateAccount(accountID, isDefaultProxy, selectedProxy, customProxyInfo) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const data = {}

      if (typeof isDefaultProxy !== "undefined") {
        data.setupProxyType = isDefaultProxy ? "BUY" : "CUSTOM_PROXY"
      }

      if (isDefaultProxy) {
        data.countryCode = selectedProxy
      } else {
        data.proxyInfo = customProxyInfo
      }

      await accountService.reactivateAccount(userID, accountID, data)
      dispatch(getUserAccounts())
      toast.success("Account successfully reactivated")
    } catch (error) {}
  }
}

function getAccountProxySuccess(proxyInfo) {
  return { type: ACTIONS.GET_ACCOUNT_PROXY_INFO, proxyInfo }
}

function getAccountProxy(accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const proxyInfo = await accountService.getAccountProxy(userID, accountID)

      dispatch(getAccountProxySuccess(proxyInfo.data))
    } catch (error) {}
  }
}

function authenticateSMTP(
  redirectUrl,
  userID = store.getState().user.activeUserID,
  accountID = store.getState().account.activeAccountID,
  shouldRefreshAccounts,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      // formData["imap-username"]
      // formData["imap-password"]

      const data = {
        type: "smtp",
        accountId: accountID,
        senderEmail: formData["smtp-sender-email"].trim(),
        senderName: formData["smtp-sender-name"].trim(),
        user: formData["smtp-username"].trim(),
        pass: formData["smtp-password"],
        host: formData["smtp-ip"].trim(),
        port: formData["smtp-port"].trim(),
        redirectUrl,
        certificate: formData.smtpSsl ? "tls" : "none",
        isAuthentication: !!formData.smtpSsl,
        userImap: formData["imap-username"].trim(),
        passImap: formData["imap-password"],
        hostImap: formData["imap-ip"].trim(),
        portImap: formData["imap-port"].trim(),
        certificateImap: formData.smtpSsl ? "tls" : "none",
        isAuthenticationImap: !!formData.smtpSsl,

        // service: "",
      }
      await accountService.authenticateSMTP(userID, accountID, data)
      dispatch(getAuthenticatedEmails(userID, accountID))
      if (shouldRefreshAccounts) {
        dispatch(getUserAccounts())
      }
      await dispatch(clearForm())
      toast.success("SMTP successfully connected")
      dispatch(hideInfoModal())
    } catch (error) {}
  }
}

function testSMTP(
  userID = store.getState().user.activeUserID,
  accountID = store.getState().account.activeAccountID,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      const data = {
        userName: formData["smtp-username"],
        password: formData["smtp-password"],
        host: formData["smtp-ip"],
        port: formData["smtp-port"],
        certificate: formData.smtpSsl ? "tls" : "none",
        isAuthentication: !!formData.smtpSsl,
      }

      const { data: responseData } = await accountService.testSMTP(userID, accountID, data)

      let status = true

      if (responseData.ok === false) {
        status = false
      }
      await dispatch(updateFormField("smtpTest", status))

      return status
    } catch (error) {
      await dispatch(updateFormField("smtpTest", false))
      return false
    }
  }
}

function testImap(
  userID = store.getState().user.activeUserID,
  accountID = store.getState().account.activeAccountID,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      const data = {
        userName: formData["imap-username"],
        password: formData["imap-password"],
        host: formData["imap-ip"],
        port: formData["imap-port"],
        certificateImap: formData.smtpSsl ? "tls" : "none",
        isAuthenticationImap: !!formData.smtpSsl,
      }

      const { data: responseData } = await accountService.testImap(userID, accountID, data)
      let status = true
      if (responseData.ok === false) {
        status = false
      }

      await dispatch(updateFormField("imapTest", status))

      return status
    } catch (error) {
      await dispatch(updateFormField("imapTest", false))
      return false
    }
  }
}

function sendEmail(recipient, subject, content, emailSignature = undefined, bccRecipients) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const data = {
        subject,
        signatureId: emailSignature !== "noSignature" ? emailSignature : undefined,
        recipient,
        content,
        bccRecipients,
      }
      await accountService.sendEmail(userID, accountID, data)
      toast.success("Email sent")
      return true
    } catch (error) {
      toast.error("Email failed to send")
      return false
    }
  }
}

function connectLinkedinAccount(
  accountID,
  isDefaultProxy,
  selectedProxy,
  customProxyInfo,
  linkedinEmail,
  linkedinPassword,
  countryCode,
  setupProxyType,
  proxyInfo,
) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const linkedinSubscriptionId = getState().forms.formData["linkedin-subscription"]

      const data = {
        linkedinEmail,
        linkedinPassword,
        countryCode,
        setupProxyType,
        proxyInfo,
        linkedinSubscriptionId: +linkedinSubscriptionId,
      }

      if (typeof isDefaultProxy !== "undefined") {
        data.setupProxyType = isDefaultProxy ? "BUY" : "CUSTOM_PROXY"
      }

      if (isDefaultProxy) {
        data.countryCode = selectedProxy
      } else {
        data.proxyInfo = customProxyInfo
      }

      await accountService.connectLinkedinAccount(userID, accountID, data)
      toast.success("Linkedin account successfully integrated")
      return true
    } catch (error) {
      return false
    }
  }
}

function disconnectLinkedinAccount(accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      await accountService.disconnectLinkedinAccount(userID, accountID)
      toast.success("Linkedin account successfully disconected")
      return true
    } catch (error) {
      return false
    }
  }
}

function getGlobalStatusSuccess(accountID, globalStatus) {
  return { type: ACTIONS.GET_GLOBAL_STATUS, accountID, globalStatus }
}

function getGlobalStatus() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.activeUserID
      const accountID = getState().account.activeAccountID
      const response = await accountService.getGlobalStatus(userID, accountID)
      dispatch(getGlobalStatusSuccess(accountID, response.data))
    } catch (error) {
      return false
    }
  }
}

export {
  getUserAccounts,
  getAccountData,
  changeAccountName,
  updateLinkedinCredentials,
  loginAccount,
  verifyPin,
  verifyTwoFactorAuth,
  resendLinkedinPin,
  deleteAccount,
  setActiveAccountID,
  changeLinkedinSubscription,
  reactivateAccount,
  getAccountProxy,
  authenticateSMTP,
  testSMTP,
  testImap,
  sendEmail,
  connectLinkedinAccount,
  disconnectLinkedinAccount,
  getGlobalStatus,
}
