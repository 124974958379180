import axios from "axios"

import config from "../utils/config"

function getAllCampaigns(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns${query}`,
  )
}

function updateDraftCampaign(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/update`,
    data,
  )
}

function createCampaign(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns`,
    data,
  )
}

function createLeadSource(userID, accountID, leadSources) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources`,
    { leadSources },
  )
}

function getLeadsByCampaign(userID, accountID, campaignID, sort) {
  return axios.get(
    `${
      config.REACT_APP_BACKEND_URL
    }/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/leads${sort || ""}`,
  )
}

function changeLeadVariables(userID, accountID, leadID, data) {
  return axios.put(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/variables`,
    data,
  )
}

function deleteCampaign(userID, accountID, campaignID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}`,
  )
}

function blockLead(userID, accountID, leadID) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/block`,
  )
}

function blockLeads(userID, accountID, leadIds) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/block?leadIds=[${leadIds}]`,
  )
}

function unblockLead(userID, accountID, leadID) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/unblock`,
  )
}

function unblockLeads(userID, accountID, leadIds) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/unblock?leadIds=[${leadIds}]`,
  )
}

function deleteLeads(userID, accountID, leadIDS) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/delete?leadIds=[${leadIDS}]`,
  )
}

function activateCampaign(userID, accountID, campaignID) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/activate`,
  )
}

function deactivateCampaign(userID, accountID, campaignID) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/deactivate`,
  )
}

function changeCampaignState(userID, accountID, campaignID, campaignState) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/change_campaign_state`,
    campaignState,
  )
}

function importValidation(file, userID, accountID) {
  const formData = new FormData()
  formData.append("lead_import", file)
  return axios.post(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/validations/lead_import`,
    formData,
  )
}

function getExportFile(userID, accountID, campaignID) {
  return axios.get(
    `
      ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/export`,
  )
}

async function importFile(
  userID,
  accountID,
  campaignID,
  dashboard,
  file,
  removeCsvDuplicates,
  removeDbDuplicates,
) {
  const formData = new FormData()
  formData.append("lead_import", file)
  formData.append("dashboard", dashboard)
  formData.append("removeCsvDuplicates", removeCsvDuplicates)
  formData.append("removeDbDuplicates", removeDbDuplicates)
  await axios.post(
    `
      ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/import`,
    formData,
  )
}

function getCampaignDetails(userID, accountID, campaignID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/details`,
  )
}

function getAllCampaignSteps(userID, accountID, campaignID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/steps`,
  )
}

function editCampaign(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/edit`,
    data,
  )
}

function getAllCampaignNames(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/names`,
  )
}

function addBackLeadToCampaign(userID, accountID, leadID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/change_campaign`,
    data,
  )
}

function reuseLeadList(userID, accountID, leadSourcesId) {
  return axios.patch(
    `
   ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources/${leadSourcesId}/reuse_lead_list`,
  )
}

function getLeadsByAllCampaigns(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads${query}`,
  )
}

function getSavedSequences(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/saved_sequences${query}`,
  )
}

export default {
  getAllCampaigns,
  updateDraftCampaign,
  createCampaign,
  createLeadSource,
  getLeadsByCampaign,
  changeLeadVariables,
  deleteCampaign,
  blockLead,
  blockLeads,
  unblockLead,
  unblockLeads,
  deleteLeads,
  activateCampaign,
  deactivateCampaign,
  importValidation,
  getExportFile,
  importFile,
  getCampaignDetails,
  getAllCampaignSteps,
  editCampaign,
  getAllCampaignNames,
  addBackLeadToCampaign,
  changeCampaignState,
  reuseLeadList,
  getLeadsByAllCampaigns,
  getSavedSequences,
}
