export const LEADS_FILTERS = {
  NOT_VERIFIED_EMAILS: {
    name: "NOT_VERIFIED_EMAILS",
    text: "Leads with not verified emails",
  },
  VERIFIED_EMAILS: {
    name: "VERIFIED_EMAILS",
    text: "Leads with verified emails",
  },

  DISCOVERED: {
    name: "DISCOVERED",
    id: 1,
    backgroundColor: "rgba(105, 137, 174, 0.2)",
    text: "Discovered",
    leadStatus: true,
  },
  CONNECTED_PENDING: {
    name: "CONNECTED_PENDING",
    id: 2,
    backgroundColor: "rgba(236, 178, 93, 0.2)",
    text: "Connection pending",
    leadStatus: true,
  },
  CONNECTED_NOT_REPLIED: {
    name: "CONNECTED_NOT_REPLIED",
    id: 3,
    backgroundColor: "rgba(121, 79, 132, 0.2)",
    text: "Connected, not replied",
    leadStatus: true,
  },
  CONNECTED_REPLIED: {
    name: "CONNECTED_REPLIED",
    id: 4,
    backgroundColor: "rgba(19, 183, 132, 0.2)",
    text: "Connected, replied",
    leadStatus: true,
  },

  COMPLEX_STEPS: {
    name: "COMPLEX_STEPS",
    text: "Selected steps",
  },
}

export const getAllLeadStatuses = () => {
  return Object.values(LEADS_FILTERS).filter(leadFilter => leadFilter.leadStatus)
}
